/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

import { fabric } from 'fabric';

class EditorWorkspace {
  constructor(canvas, option) {
    console.log(
      canvas,
      'canvascanvascanvascanvascanvascanvascanvascanvascanvascanvascanvascanvascanvascanvas'
    );

    this.canvas = canvas;
    this.workspaceEl = document.querySelector('#workspace');
    this.workspace = null;
    this.machine = null;
    this.isDragging = false;
    this.option = option;
    this.isMinZoomReached = true;
    this._initBackground();
    this._initmachine();
    this._initWorkspace();
    this._initResizeObserve();
    this._initDring();
  }

  // 初始化背景
  _initBackground() {
    this.canvas.setBackgroundColor('#F1F1F1', this.canvas.renderAll.bind(this.canvas));
    this.canvas.backgroundImage = '';
    this.canvas.setWidth(this.workspaceEl.offsetWidth);
    this.canvas.setHeight(this.workspaceEl.offsetHeight);
    // 上一次画布大小
    this.width = this.workspaceEl.offsetWidth;
    this.height = this.workspaceEl.offsetHeight;
  }

  // 初始化机器
  _initmachine() {
    const { machineWidth, machineHeight } = this.option;
    const machine = new fabric.Rect({
      fill: '#87CEFA',
      width: machineWidth,
      height: machineHeight,
      id: 'machine',
      lockScalingX: true, // 锁定水平缩放
      lockScalingY: true, // 锁定垂直缩放
      selectable: false,
      hasControls: false,
      hoverCursor: 'selection',
    });
    // workspace.set('selectable', false);
    // workspace.set('hasControls', false);
    // workspace.hoverCursor = 'selection';

    this.canvas.add(machine);
    this.canvas.centerObject(machine);
    this.canvas.renderAll();
    this.machine = machine;
    // this.auto();
  }

  _monitor() {
    const This = this;
    const newScale = This.canvas.getZoom();
    if (newScale < 0.6) {
      this.isMinZoomReached = false;
    }
    if (!this.isMinZoomReached) {
      This.canvas.off('mouse:wheel', this._handleWheel.bind(this));
    }
  }

  _handleWheel() {
    const This = this;
    const newScale = This.canvas.getZoom();
    if (newScale < 0.6 && !this.isMinZoomReached) {
      const center = This.canvas.getCenter();
      This.canvas.zoomToPoint(new fabric.Point(center.left, center.top), 0.6);
      this.isMinZoomReached = false;
    }
  }

  // 初始化木板
  _initWorkspace() {
    this.canvas.on('after:render', this._monitor.bind(this));
    this.canvas.on('mouse:wheel', this._handleWheel.bind(this));
    this.canvas.setBackgroundColor('white', this.canvas.renderAll.bind(this.canvas));
    const { workWidth, workHeight } = this.option;

    const PPI = 96; // 假设屏幕的 PPI 为 96
    const mmPerPixel = 25.4 / PPI; // 每像素对应的毫米值

    // 将像素值转换为毫米
    const workWidthInMm = workWidth * mmPerPixel;
    const workHeightInMm = workHeight * mmPerPixel;
    // 每10毫米显示一个网格
    const gridSizeInMm = 10; // 网格大小 (毫米)
    const gridSizeInPixels = gridSizeInMm / mmPerPixel; // 网格大小 (像素)
    // 创建网格图案
    const gridPattern = (ctx, width, height) => {
      ctx.strokeStyle = '#e0e0e0'; // 网格线的颜色
      for (let x = 0.5; x <= width; x += gridSizeInPixels) {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
        ctx.stroke();
      }
      for (let y = 0.5; y <= height; y += gridSizeInPixels) {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(width, y);
        ctx.stroke();
      }
    };
    // 创建用于绘制网格的小型 canvas
    const patternCanvas = document.createElement('canvas');
    patternCanvas.width = gridSizeInPixels * 2; // 计算网格图案的尺寸
    patternCanvas.height = gridSizeInPixels * 2;
    const patternCtx = patternCanvas.getContext('2d');
    patternCtx.fillStyle = '#ffffff'; // 白色背景
    patternCtx.fillRect(0, 0, patternCanvas.width, patternCanvas.height);
    gridPattern(patternCtx, patternCanvas.width, patternCanvas.height);

    const workspace = new fabric.Rect({
      fill: new fabric.Pattern({
        source: patternCanvas,
        repeat: 'repeat',
      }),
      width: workWidth,
      height: workHeight,
      id: 'workspace',
      lockScalingX: true, // 锁定水平缩放
      lockScalingY: true, // 锁定垂直缩放
      selectable: false,
      hasControls: false,
      hoverCursor: 'selection',
      left: this.machine.left,
      top: this.machine.top + this.machine.height - workHeight,
    });
    // workspace.set('selectable', false);
    // workspace.set('hasControls', false);
    // workspace.hoverCursor = 'selection';

    this.canvas.add(workspace);
    // 添加坐标文本
    this._addCoordinateText(workWidthInMm, workHeightInMm, gridSizeInMm); // 每20个像素显示一次坐标
    // this.canvas.centerObject(workspace);
    this.canvas.renderAll();
    this.workspace = workspace;
    // 将木板移到机器上方
    workspace.bringToFront();
    // this.auto();
    // this._adjustObjectsToOrigin();
  }

  // 添加坐标文本方法
  _addCoordinateText(workWidthInMm, workHeightInMm, gridSizeInMm) {
    const fontOptions = {
      fontSize: 12,
      fill: '#000000',
      originX: 'left', // 设置文本对齐方式为左对齐
      originY: 'bottom', // 设置文本对齐方式为底对齐
    };

    // 添加 X 轴坐标 (最下方)
    for (let x = 0; x <= workWidthInMm; x += gridSizeInMm) {
      const mmX = x.toFixed(0); // 保留两位小数
      const text = `${mmX}`;
      // 计算像素位置，并根据 PPI 转换来设置 left 和 top
      const pixelX = x * (96 / 25.4); // 转换回像素值
      const coordinateText = new fabric.Text(text, {
        ...fontOptions,
        id: 'coordinate',
        selectable: false,
        hasControls: false,
        hoverCursor: 'selection',
        left: pixelX + this.machine.left, // 基于机器的 left 偏移
        top: this.machine.top + this.machine.height + 20, // 设置在工作区的下方
      });

      this.canvas.add(coordinateText);
    }

    // 添加 Y 轴坐标 (最左侧)
    for (let y = 0; y <= workHeightInMm; y += gridSizeInMm) {
      const mmY = y.toFixed(0); // 保留两位小数
      const text = `${mmY}`;

      // 计算像素位置，并根据 PPI 转换来设置 left 和 top
      const pixelY = y * (96 / 25.4); // 转换回像素值
      const coordinateText = new fabric.Text(text, {
        ...fontOptions,
        id: 'coordinate',
        selectable: false,
        hasControls: false,
        hoverCursor: 'selection',
        left: this.machine.left - 20, // 向左偏移，避免与边框重叠
        top: this.machine.top + this.machine.height - pixelY, // 从下往上显示 Y 轴
      });

      this.canvas.add(coordinateText);
    }
  }
  // _adjustObjectsToOrigin() {
  //   if (!this.workspace) return;

  //   const { left: workspaceLeft, top: workspaceTop, height: workHeight } = this.workspace;
  //   console.log(this.workspace, 'this.workspacethis.workspace11111111111111');

  //   this.canvas.getObjects().forEach((obj) => {
  //     if (obj.id !== 'workspace' && obj.id !== 'machine') {
  //       // 计算相对于左下角的新位置
  //       const newX = obj.left - workspaceLeft;
  //       const newY = -(obj.top - (workspaceTop + workHeight)); // 注意 Y 轴方向
  //       obj.set({ left: newX, top: newY });
  //       obj.setCoords(); // 更新对象的控制点坐标
  //     }
  //   });

  //   this.canvas.renderAll();
  // }

  // 初始化监听器
  _initResizeObserve() {
    // const resizeObserver = new ResizeObserver((entries) => {
    //   // this.auto();
    //   const diffWidth = entries[0].contentRect.width / 2 - this.width / 2;
    //   const diffHeight = entries[0].contentRect.height / 2 - this.height / 2;
    //   this.width = entries[0].contentRect.width;
    //   this.height = entries[0].contentRect.height;
    //   this.canvas.getObjects().forEach((obj) => {
    //     if (obj.id !== 'workspace') {
    //       const left = obj.left + diffWidth;
    //       const top = obj.top + diffHeight;

    //       obj.set({
    //         left,
    //         top,
    //       });
    //       obj.setCoords();
    //     }
    //   });
    //   this.canvas.renderAll.bind(this.canvas);
    //   this.canvas.renderAll();
    //   this.canvas.requestRenderAll();
    // });

    // resizeObserver.observe(this.workspaceEl);
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      this.width = entry.contentRect.width;
      this.height = entry.contentRect.height;
      this.canvas.setWidth(this.width);
      this.canvas.setHeight(this.height);
      this.canvas.renderAll();
    });

    resizeObserver.observe(this.workspaceEl);
  }

  // setSize(width, height) {
  //   this._initBackground();
  //   this.option.width = width;
  //   this.option.height = height;
  //   // 重新设置workspace
  //   this.workspace = this.canvas.getObjects().find((item) => item.id === 'workspace');
  //   this.workspace.set('width', width);
  //   this.workspace.set('height', height);
  //   // 获取偏移
  //   const l1 = Number(this.workspace.left);
  //   const t1 = Number(this.workspace.top);
  //   console.log(l1, t1, 't1t1t1');
  //   this.canvas.centerObject(this.workspace);
  //   this.moveEl(this.workspace.left - l1, this.workspace.top - t1);
  //   this.auto();
  // }

  setSize(machineWidth, machineHeight, workWidth, workHeight) {
    const currentMachineTop = this.machine.top;
    const originalMachineHeight = this.machine.height;
    const heightDifference = machineHeight - originalMachineHeight;
    this.option.workWidth = workWidth;
    this.option.workHeight = workHeight;
    this._centerMachineAndWorkspace();

    this._initBackground();
    // this._initmachine();
    this._initWorkspace();
    // const { machineWidth, machineHeight, workWidth, workHeight } = this.option;
    console.log(this.option, 'this.option1111');
    // this.option.width = width;
    // this.option.height = height;
    if (this.workspace) {
      this.workspace.set({ width: workWidth, height: workHeight });
      // this.canvas.centerObject(this.workspace);
    }
    // 如果你也想要更新机器的尺寸，这里可以做同样的事情
    if (this.machine) {
      this.machine.set({ width: machineWidth, height: machineHeight });
      this.machine.set({
        top: currentMachineTop + heightDifference, // 修改 `top` 位置，使左下角不动
      });
      // this.canvas.centerObject(this.machine); // 如果你需要保持机器居中
    }
    this.canvas.renderAll();
  }

  _centerMachineAndWorkspace() {
    if (this.machine && this.workspace) {
      // 获取画布的中心点
      // const canvasCenter = this.canvas.getCenter();

      // 居中机器
      // this.canvas.centerObject(this.machine);
      // this.machine.set({
      //   left: canvasCenter.left - this.machine.width / 2,
      //   top: canvasCenter.top - this.machine.height / 2,
      // });

      // 居中工作区
      // this.canvas.centerObject(this.workspace);
      const workspace = this.canvas.getObjects().find((obj) => obj.id === 'workspace');
      if (workspace) {
        this.canvas.remove(workspace);
      }

      // 移除坐标文本
      const coordinates = this.canvas.getObjects().filter((obj) => obj instanceof fabric.Text);
      coordinates.forEach((textObj) => {
        this.canvas.remove(textObj);
      });

      // 渲染所有对象
      this.canvas.renderAll();
    }
  }

  moveEl(diffWidth, diffHeight) {
    this.canvas.getObjects().forEach((obj) => {
      if (obj.id !== 'workspace') {
        const left = obj.left + diffWidth;
        const top = obj.top + diffHeight;
        obj.set({
          left,
          top,
        });
        obj.setCoords();
      }
    });
    this.canvas.renderAll();
    this.canvas.requestRenderAll();
  }

  setZoomAuto(scale, cb) {
    const { workspaceEl } = this;
    const width = workspaceEl.offsetWidth;
    const height = workspaceEl.offsetHeight;
    this.canvas.setWidth(width);
    this.canvas.setHeight(height);
    const center = this.canvas.getCenter();
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.canvas.zoomToPoint(new fabric.Point(center.left, center.top), scale);
    this.canvas.centerObject(this.workspace);
    this.canvas.renderAll();

    // 超出画布不展示this.workspace.left
    // this.workspace.clone((cloned) => {
    //   this.canvas.clipPath = cloned;
    //   this.canvas.requestRenderAll();
    // });
    if (cb) cb(this.workspace.left, this.workspace.top);
  }

  _getScale() {
    const viewPortWidth = this.workspaceEl.offsetWidth;
    const viewPortHeight = this.workspaceEl.offsetHeight;

    // 按照宽度
    if (viewPortWidth / viewPortHeight < this.option.width / this.option.height) {
      return viewPortWidth / this.option.width;
    } // 按照宽度缩放
    return viewPortHeight / this.option.height;
  }

  // 放大
  big() {
    let zoomRatio = this.canvas.getZoom();
    zoomRatio += 0.05;
    const center = this.canvas.getCenter();
    this.canvas.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
  }

  // 缩小
  small() {
    let zoomRatio = this.canvas.getZoom();
    zoomRatio -= 0.05;
    const center = this.canvas.getCenter();
    this.canvas.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
  }

  // 自动缩放
  // auto() {
  //   // const scale = this._getScale();
  //   // this.setZoomAuto(scale - 0.08);
  // }

  // 1:1 放大
  one() {
    this.setZoomAuto(0.8 - 0.08);
    this.canvas.requestRenderAll();
  }

  // 拖拽模式
  _initDring() {
    const This = this;
    this.canvas.on('mouse:down', function (opt) {
      const evt = opt.e;
      if (evt.altKey === true) {
        this.defaultCursor = 'grab';
        This._setDring();
        this.selection = false;
        this.isDragging = true;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
        this.requestRenderAll();
      }
    });

    this.canvas.on('mouse:move', function (opt) {
      if (this.isDragging) {
        this.defaultCursor = 'grabbing';
        const { e } = opt;
        const vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
        this.requestRenderAll();
      }
    });

    this.canvas.on('mouse:up', function () {
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
      this.defaultCursor = 'default';
      This.workspace.hoverCursor = 'default';
      this.getObjects().forEach((obj) => {
        if (obj.id !== 'workspace' && obj.hasControls) {
          obj.selectable = true;
        }
      });
      this.requestRenderAll();
    });

    this.canvas.on('mouse:wheel', function (opt) {
      const delta = opt.e.deltaY;
      let zoom = this.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      const center = this.getCenter();
      this.zoomToPoint(new fabric.Point(center.left, center.top), zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
  }

  _setDring() {
    this.canvas.selection = false;
    this.canvas.defaultCursor = 'grab';
    this.workspace.hoverCursor = 'grab';
    this.canvas.getObjects().forEach((obj) => {
      obj.selectable = false;
    });
    this.canvas.renderAll();
    this.canvas.requestRenderAll();
  }
}

export default EditorWorkspace;
