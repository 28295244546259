/* eslint-disable import/no-cycle */
import { login, logOut } from '@/api/user';
/* eslint-disable import/no-cycle */
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
// import Cookies from 'js-cookie'

const getDefaultState = () => {
  return {
    token: getToken(),
    userName: '',
    email: '',
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (res) => {
    Object.assign(res, getDefaultState());
  },
  SET_TOKEN: (res, token) => {
    res.token = token;
  },
  SET_NAME: (res, userName) => {
    res.userName = userName;
  },
  SET_EMAIL: (res, email) => {
    res.email = email;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    // const { email, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ email: userInfo.email, password: userInfo.password })
        .then((res) => {
          const { data } = res;
          commit('SET_TOKEN', data.token);
          commit('SET_NAME', data.userName);
          commit('SET_EMAIL', data.email);
          localStorage.setItem('userName', data.userName);
          localStorage.setItem('firstLogin', data.firstLogin);
          localStorage.setItem('id', data.id);
          // localStorage.setItem('email', data.email);
          setToken(data.token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getInfo(state.token).then(response => {
  //       const { data } = response

  //       if (!data) {
  //         return reject('Verification failed, please Login again.')
  //       }

  //       const { name, avatar } = data

  //       commit('SET_NAME', name)
  //       commit('SET_AVATAR', avatar)
  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // user logOut
  logOut({ commit }) {
    return new Promise((resolve, reject) => {
      const data = {
        Authorization: getToken(),
      };
      logOut(data)
        .then(() => {
          removeToken(); // must remove  token  first
          resetRouter();
          commit('RESET_STATE');
          localStorage.removeItem('password');
          localStorage.removeItem('email');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  // resetToken({ commit }) {
  //   return new Promise(resolve => {
  //     removeToken() // must remove  token  first
  //     commit('RESET_STATE')
  //     resolve()
  //   })
  // }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
