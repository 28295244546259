/* eslint import/prefer-default-export: "off" */
import request from '@/utils/projectRequest';

// 项目导入
export function importPro(data) {
  return request({
    url: '/api/project/file/import',
    method: 'post',
    data,
  });
}
// 图片导入

export function importImg(data) {
  return request({
    url: '/api/project/image/import',
    method: 'post',
    data,
  });
}
export function getProject(data) {
  return request({
    url: '/api/project/svg/trace',
    method: 'get',
    params: data,
  });
}
// 导入gcode验证
export function importGcode(data) {
  return request({
    url: '/api/project/gcode/import',
    method: 'post',
    data,
  });
}
