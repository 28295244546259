<template>
  <div class="container" style="text-align: center">
    <h1>Please follow the checklist before craft:</h1>
    <div class="grid-container">
      <div style="text-align: center" class="board">
        <span class="title">1.Material size</span>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="Width(X)">
            <el-input-number
              v-model="form.x"
              :controls="false"
              :min="1"
              :max="maschirWidth"
              :step="10"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Length(Y)">
            <el-input-number
              v-model="form.y"
              :controls="false"
              :min="1"
              :max="maschirHeight"
              :step="10"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Thickness(Z)">
            <el-input-number v-model="form.z" :controls="false"></el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">Next</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: center">
        <span class="title">2.Confirm your bit</span>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="">
            <el-select v-model="form.region" placeholder="确认刀头">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="verifyBit" :disabled="buttonDisabled !== 1">
              Next
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: center">
        <span class="title">3.Start position</span>
        <Debugdialog></Debugdialog>
        <div>
          <el-button type="primary" :disabled="buttonDisabled !== 2" @click="reset">
            ResetZero
          </el-button>
          <el-button
            type="primary"
            style="margin-left: 5px"
            @click="setBit"
            :disabled="buttonDisabled !== 2"
          >
            Probing
          </el-button>
        </div>
      </div>
      <div style="text-align: center" class="board">
        <span class="title">4.Pay attention to the press plate</span>
        <div>Make sure that your material is fully secure before continuing</div>
      </div>
      <div style="text-align: center">
        <div class="title">5.Start carving</div>
        <el-button type="primary" @click="goFrame">Frame</el-button>
        <el-button type="primary" @click="startCarving">Run</el-button>
      </div>
      <div style="text-align: center" v-show="displaySet">
        <span class="title">6.Tool setting</span>
        <div>
          <div>1.Plug the lead into the carriage.</div>
          <div>2.Attach the clip to the collet.</div>
          <div>3.Touch the plate to the bit to check connectivity.</div>
          <div>4.Place the touch plate on the material, under the bit.</div>
          <el-button type="primary" @click="toolSet">Probing</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import EventBus from '@/utils/eventBus';
import { bitList } from '@/api/materialbit';
import Debugdialog from '../Debugdialog/index.vue';

export default {
  components: {
    Debugdialog,
  },
  name: 'on-Engrave',
  computed: {
    // 监听 Vuex 中的 socket 状态
    getSocket() {
      console.log(this.$store.state.webSocket.showLaser, '333333333333333333333333');
      return this.$store.state.webSocket.showLaser;
    },
    getWidth() {
      return this.$store.state.material.width;
    },
    getHeight() {
      return this.$store.state.material.height;
    },
    getDept() {
      return this.$store.state.material.dept;
    },
  },
  data() {
    return {
      form: {
        x: 0,
        y: 0,
        z: 0,
      },
      maschirWidth: null,
      maschirHeight: null,
      options: [],
      displaySet: false,
      buttonDisabled: 0,
    };
  },
  created() {
    this.getBitList();
  },
  mounted() {
    this.maschirWidth = Number(localStorage.getItem('maschirWidth'));
    this.maschirHeight = Number(localStorage.getItem('maschirHeight'));
    this.form.x = this.getWidth;
    this.form.y = this.getHeight;
    this.form.z = this.getDept;
  },
  methods: {
    // 确认木板
    onSubmit() {
      this.buttonDisabled = 1;
    },
    // 获取刀头下拉
    getBitList() {
      const data = {
        userId: localStorage.getItem('id'),
      };
      bitList(data).then((res) => {
        console.log(res.data, 'data');
        if (res.code === 200) {
          const array = [];
          Object.entries(res.data).forEach(([type, items]) => {
            // 为每个工具添加type属性并推入allBitsData数组
            items.forEach((item) => {
              item.type = type;
              array.push(item);
            });
          });
          this.options = array.map((item) => ({
            label: item.name,
            vale: item.ss,
          }));

          console.log(array, 'array');
        }
      });
    },
    // 确认刀头
    verifyBit() {
      this.buttonDisabled = 2;
    },
    // 巡边
    goFrame() {
      this.action = 'frame';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    // 开始雕刻
    startCarving() {
      this.action = 'carve';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
      this.$emit('start-carving');
    },
    // 设置原点
    reset() {
      this.action = 'reset';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    // 对刀
    setBit() {
      this.displaySet = true;
    },
    toolSet() {
      this.action = 'adjustedTool';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
  },
};
</script>

<style lang="less" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 创建三列，每列占据相等的空间 */
  gap: 10px; /* 可选：设置网格项之间的间距 */
}

.grid-item {
  background-color: lightblue; /* 设置背景颜色以便可视化 */
  padding: 20px; /* 设置内边距 */
  text-align: center; /* 居中文本 */
  border: 1px solid #ccc; /* 添加边框以便更清晰地看到边界 */
}
::v-deep.board .el-input__inner {
  width: 100px;
}
.title {
  font-size: 20px;
  margin-bottom: 10px;
  margin-bottom: 10px;
}
</style>
