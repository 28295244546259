<template>
  <el-dialog
    title="Advanced Library"
    :visible.sync="vis"
    width="1030px"
    :before-close="closeAdvanced"
    :modal="false"
    class="advance"
  >
    <el-tabs tab-position="left">
      <el-tab-pane label="QR code">
        <h1>Please enter content</h1>
        <div style="width: 100%; height: 100%; display: flex">
          <div style="width: 30%; height: 100%; padding: 0 5px">
            <el-form :model="codeForm" :rules="codeRules" ref="codeForm">
              <el-form-item label="content" prop="codeContent">
                <el-input
                  type="text"
                  placeholder="content"
                  v-model="codeForm.codeContent"
                  maxlength="100"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getCode('codeForm')">Create now</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div
            style="
              width: 70%;
              height: 80%;
              padding: 0 5px;
              text-align: center;
              display: flex;
              justify-content: center;
            "
          >
            <div style="text-align: center; width: 80%; height: 80%">
              <el-image :src="codeUrl" v-if="showCode"></el-image>
              <div class="svg-display" v-html="codeSvg" v-else></div>
              <el-button
                style="float: left; margin-top: 10px"
                type="primary"
                @click="handleImageDoubleClick(codeSvg)"
                :disabled="showCode"
              >
                Add to...
              </el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Open box">
        <h1>Please choose the model you would like to make</h1>
        <div style="width: 100%; height: 100%; display: flex">
          <div style="width: 30%; height: 100%; padding: 0 5px">
            <el-form :model="boxForm" :rules="boxRules" ref="boxForm">
              <el-form-item label="count" prop="boxContent">
                <!-- <el-select v-model="boxForm.boxContent" placeholder="请选择面数">
                  <el-option label="2 boxs: 100mm*56mm*53mm" value="2"></el-option>
                  <el-option label="3" value="3"></el-option>
                  <el-option label="4" value="4"></el-option>
                </el-select> -->
                <!-- <el-input
                  type="text"
                  placeholder="Please enter count"
                  v-model="boxForm.boxContent"
                  maxlength="100"
                  show-word-limit
                ></el-input> -->
                <el-radio v-model="boxForm.boxContent" label="2 boxs: 100mm*56mm*53mm"></el-radio>
                <el-radio v-model="boxForm.boxContent" label="3 boxs: 140mm*56mm*53mm"></el-radio>
                <el-radio v-model="boxForm.boxContent" label="4 boxs: 180mm*56mm*73mm"></el-radio>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getBox('boxForm')">Create now</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div
            style="
              width: 70%;
              height: 80%;
              padding: 0 5px;
              text-align: center;
              display: flex;
              justify-content: center;
            "
          >
            <div style="text-align: center; width: 80%; height: 80%">
              <el-image :src="boxUrl" v-if="showBox"></el-image>
              <div class="svg-display" v-html="boxSvg" v-else></div>
              <el-button
                style="float: left; margin-top: 10px"
                type="primary"
                @click="handleImageDoubleClick(boxSvg)"
                :disabled="showBox"
              >
                Add to...
              </el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Custom box">
        <h1>Please fill the size of box you would like to make</h1>
        <div style="width: 100%; height: 100%; display: flex">
          <div style="width: 30%; height: 100%; padding: 0 5px">
            <el-form :model="customForm" :rules="customRules" ref="customForm" class="sss">
              <el-form-item label="length" prop="length">
                <el-input
                  type="text"
                  placeholder="Please enter length"
                  v-model.number="customForm.length"
                  show-word-limit
                >
                  <template #append>mm</template>
                </el-input>
              </el-form-item>
              <el-form-item label="width" prop="width">
                <el-input
                  type="text"
                  placeholder="Please enter width"
                  v-model.number="customForm.width"
                  show-word-limit
                >
                  <template #append>mm</template>
                </el-input>
              </el-form-item>
              <el-form-item label="height" prop="height">
                <el-input
                  type="text"
                  placeholder="Please enter height"
                  v-model.number="customForm.height"
                  show-word-limit
                >
                  <template #append>mm</template>
                </el-input>
              </el-form-item>
              <el-form-item label="toothLength" prop="toothLength">
                <el-input
                  type="text"
                  placeholder="Please enter toothLength"
                  v-model.number="customForm.toothLength"
                  show-word-limit
                >
                  <template #append>mm</template>
                </el-input>
              </el-form-item>
              <el-form-item label="thickness" prop="thickness">
                <el-input
                  type="text"
                  placeholder="Please enter thickness"
                  v-model.number="customForm.thickness"
                  show-word-limit
                >
                  <template #append>mm</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getCustom('customForm')">Create now</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div
            style="
              width: 70%;
              height: 80%;
              padding: 0 5px;
              text-align: center;
              display: flex;
              justify-content: center;
            "
          >
            <div style="text-align: center; width: 80%; height: 80%">
              <el-image :src="customUrl" v-if="showCustom"></el-image>
              <div class="svg-display" v-html="customSvg" v-else></div>
              <el-button
                style="float: left; margin-top: 10px"
                type="primary"
                @click="handleImageDoubleClick(customSvg)"
                :disabled="showCustom"
              >
                Add to...
              </el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Maze">
        <h1>Maze</h1>
        <div style="width: 100%; height: 100%; display: flex">
          <div style="width: 30%; height: 100%; padding: 0 5px">
            <el-form :model="mazeForm" :rules="mazeRules" ref="mazeForm">
              <el-form-item label="Length" prop="length">
                <el-input
                  type="text"
                  placeholder="length"
                  v-model.number="mazeForm.length"
                  show-word-limit
                >
                  <template #append>mm</template>
                </el-input>
              </el-form-item>
              <el-form-item label="Width" prop="width">
                <el-input
                  type="text"
                  placeholder="width"
                  v-model.number="mazeForm.width"
                  show-word-limit
                >
                  <template #append>mm</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getMaze('mazeForm')">Create now</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div
            style="
              width: 70%;
              height: 80%;
              padding: 0 5px;
              text-align: center;
              display: flex;
              justify-content: center;
            "
          >
            <div style="text-align: center; width: 80%; height: 80%">
              <el-image :src="mazeUrl" v-if="showMaze" style="width: 400px"></el-image>
              <div class="svg-display" v-html="mazeSvg" v-else></div>
              <el-button
                style="float: left; margin-top: 10px"
                type="primary"
                @click="handleImageDoubleClick(mazeSvg)"
                :disabled="showMaze"
              >
                Add to...
              </el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="Ruler">
        <h1>Ruler</h1>
        <div style="width: 100%; height: 100%; display: flex">
          <div style="width: 30%; height: 100%; padding: 0 5px">
            <el-form :model="rulerForm" :rules="rulerRules" ref="rulerForm">
              <el-form-item label="Length" prop="length">
                <el-input
                  type="text"
                  placeholder="length"
                  v-model.number="rulerForm.length"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="Height" prop="height">
                <el-input
                  type="text"
                  placeholder="height"
                  v-model.number="rulerForm.height"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="Unit" prop="unit">
                <el-radio-group v-model="rulerForm.unit">
                  <el-radio label="mm"></el-radio>
                  <el-radio label="inch"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getRuler('rulerForm')">Create now</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div
            style="
              width: 70%;
              height: 80%;
              padding: 0 5px;
              text-align: center;
              display: flex;
              justify-content: center;
            "
          >
            <div style="text-align: center; width: 80%; height: 80%">
              <el-image :src="rulerUrl" v-if="showRuler" style="width: 400px"></el-image>
              <div class="svg-display" v-html="rulerSvg" v-else></div>
              <el-button
                style="float: left; margin-top: 10px"
                type="primary"
                @click="handleImageDoubleClick(rulerSvg)"
                :disabled="showRuler"
              >
                Add to...
              </el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- <div
    class="image-container"
    ref="imageContainer"
    v-infinite-scroll="loadMore"
    :infinite-scroll-distance="showMore"
  >
    <div v-for="(item, index) in imageList" :key="index" class="image-item" ref="imageItem">
      <el-image
        :src="item.previewUrl"
        class="image"
        :alt="item.altText || ''"
        @dblclick="handleImageDoubleClick(item.url)"
      />
    </div>
  </div> -->
    </el-tabs>
  </el-dialog>
  <!-- </div> -->
</template>

<script>
// import fetchSvgContent from '@/utils/svgService';
import { getqrcode, getbox, getcustom } from '@/api/advanced';

export default {
  name: 'advanced-material',
  inject: ['canvas', 'fabric'],
  props: {
    advanced: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      vis: this.advanced,
      codeForm: {
        codeContent: '',
      },
      codeRules: {
        codeContent: [{ required: true, message: 'Please enter content', trigger: 'blur' }],
      },
      codeUrl: require('@/assets/advanced/qrcode.png'),
      showCode: true,
      codeSvg: '',

      boxForm: {
        boxContent: '2 boxs: 100mm*56mm*53mm',
      },
      boxRules: {
        boxContent: [{ required: true, message: 'Please select count', trigger: 'blur' }],
      },
      boxUrl: require('@/assets/advanced/open-box.png'),
      showBox: true,
      boxSvg: '',
      customForm: {
        length: 100,
        width: 70,
        height: 60,
        toothLength: 10,
        thickness: 5,
      },
      customRules: {
        length: [
          { required: true, message: 'Please enter length', trigger: 'blur' },
          { type: 'number', message: 'Length must be a number', trigger: 'blur' },
        ],
        width: [
          { required: true, message: 'Please enter width', trigger: 'blur' },
          { type: 'number', message: 'Width must be a number', trigger: 'blur' },
        ],
        height: [
          { required: true, message: 'Please enter height', trigger: 'blur' },
          { type: 'number', message: 'Height must be a number', trigger: 'blur' },
        ],
        toothLength: [
          { required: true, message: 'Please enter toothLength', trigger: 'blur' },
          { type: 'number', message: 'ToothLength must be a number', trigger: 'blur' },
        ],
        thickness: [
          { required: true, message: 'Please enter thickness', trigger: 'blur' },
          { type: 'number', message: 'Thickness must be a number', trigger: 'blur' },
        ],
      },
      customUrl: require('@/assets/advanced/custom-box.png'),
      showCustom: true,
      customSvg: '',

      mazeForm: {
        length: 0,
        width: 0,
      },
      mazeRules: {
        length: [
          { required: true, message: 'Please enter length', trigger: 'blur' },
          { type: 'number', message: 'Length must be a number', trigger: 'blur' },
        ],
        width: [
          { required: true, message: 'Please enter width', trigger: 'blur' },
          { type: 'number', message: 'Width must be a number', trigger: 'blur' },
        ],
      },
      mazeUrl: require('@/assets/advanced/maze.png'),
      showMaze: true,
      mazeSvg: '',
      rulerForm: {
        length: 0,
        height: 0,
        unit: 'mm',
      },
      rulerRules: {
        length: [
          { required: true, message: 'Please enter length', trigger: 'blur' },
          { type: 'number', message: 'Length must be a number', trigger: 'blur' },
        ],
        height: [
          { required: true, message: 'Please enter width', trigger: 'blur' },
          { type: 'number', message: 'Width must be a number', trigger: 'blur' },
        ],
        unit: [{ required: true, message: 'A unit must be selected', trigger: 'change' }],
      },
      rulerUrl: require('@/assets/advanced/ruler.png'),
      showRuler: true,
      rulerSvg: '',
    };
  },
  watch: {
    advanced(newVal) {
      this.vis = newVal;
      if (this.vis === true) {
        // this.getType();
      }
    },
    vis(newVal) {
      this.$emit('update:advanced', newVal);
    },
  },
  mounted() {},

  methods: {
    // 生成二维码
    getCode(codeForm) {
      this.$refs[codeForm].validate((valid) => {
        if (valid) {
          const data = {
            content: this.codeForm.codeContent,
          };
          getqrcode(data).then((res) => {
            if (res.status === 200) {
              this.codeSvg = res.data;
              this.showCode = false;
            }
            console.log(res, 'res');
          });
          console.log(1);
        } else {
          console.log('表单校验失败');
          return false;
        }
      });
    },
    // 生成置物盒
    getBox(boxForm) {
      this.$refs[boxForm].validate((valid) => {
        if (valid) {
          const data = {
            count: this.boxForm.boxContent.charAt(0),
          };
          getbox(data).then((res) => {
            if (res.status === 200) {
              this.boxSvg = res.data;
              this.showBox = false;
            }
            console.log(res, 'res');
          });
          console.log(1);
        } else {
          console.log('表单校验失败');
          return false;
        }
      });
    },
    // 自定义盒子
    handleInput(event, field) {
      // 提取用户输入中的数字部分
      const inputValue = event.replace(/[^0-9.-]/g, '');
      // 如果用户输入的是有效的数字，则更新数据模型
      const parsedValue = parseFloat(inputValue);
      if (!Number.isNaN(parsedValue)) {
        this.$set(this.customForm, field, parsedValue);
      } else if (inputValue === '') {
        // 允许空字符串，以便用户可以清空输入框
        this.$set(this.customForm, field, null); // 或者设置为 undefined 或其他默认值
      }
    },
    getCustom(customForm) {
      this.$refs[customForm].validate((valid) => {
        if (valid) {
          const data = {
            length: this.customForm.length,
            width: this.customForm.width,
            height: this.customForm.height,
            toothLength: this.customForm.toothLength,
            thickness: this.customForm.thickness,
          };
          getcustom(data).then((res) => {
            if (res.status === 200) {
              this.customSvg = res.data;
              this.showCustom = false;
            }
            console.log(res, 'res');
          });
          console.log(1);
        } else {
          console.log('表单校验失败');
          return false;
        }
      });
    },
    closeAdvanced() {
      this.$emit('close-visible');
    },

    // 双击图片
    async handleImageDoubleClick(e) {
      const This = this;
      This.fabric.loadSVGFromString(e, function (objects) {
        console.log(objects, 'objectsobjectsssssssssssss');
        const templateSize = This.canvas.c.getObjects();
        const masObj = templateSize.filter((element) => element.id === 'workspace');
        const obj = This.fabric.util.groupSVGElements(objects);
        const targetSize = masObj[0].height / 2;
        const originalHeight = obj.height;
        const scaleFactor = targetSize / originalHeight;

        obj.set({
          scaleX: scaleFactor,
          scaleY: scaleFactor,
          left: masObj[0].left + (obj.width * scaleFactor) / 2,
          top: masObj[0].top + masObj[0].height - (obj.height * scaleFactor) / 2,
          originX: 'center',
          originY: 'center',
        });
        This.canvas.c.add(obj);
        This.canvas.c.renderAll();
      });
      this.$emit('close-visible');
    },
    getMaze(mazeform) {
      this.$refs[mazeform].validate((valid) => {
        if (valid) {
          console.log(1);
        } else {
          console.log('error');
        }
      });
    },

    getRuler(rulerform) {
      this.$refs[rulerform].validate((valid) => {
        if (valid) {
          console.log(1);
        } else {
          console.log('error');
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .v-modal {
  display: none;
}
::v-deep .el-form-item__content {
  width: 100%;
}
::v-deep .el-tab-pane {
  // display: flex;
  height: 100%;
  width: 100%;
}
.image-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 创建6列 */
  grid-auto-rows: 150px;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

.image-item {
  display: grid;
  place-items: center;
  height: 150px; /* 固定高度 */
  box-sizing: border-box; /* 确保 padding 和 border 不增加元素的总宽度 */
  padding: 5px; /* 可选：添加一些间距 */
  position: relative; /* 使 .image 相对于 .image-item 定位 */
}

.image {
  width: 100%; /* 占据整个 .image-item 容器的宽度 */
  object-fit: contain; /* 保持图像的宽高比，等比例缩放 */
}
.el-image__inner {
}
::v-deep .el-dialog {
  height: 80%;
  overflow: hidden;
}
::v-deep .el-dialog__body {
  height: 100%;
}
::v-deep .el-tabs--left {
  height: 100%;
}
::v-deep .el-tabs__content {
  height: 90%;
  // overflow-y: scroll;
}
.svg-display {
  position: relative;
  flex: 1;
  height: 100%; /* 确保有足够的高度 */
  // height: 300px; /* 确保有足够的高度 */
  border: 1px solid #ccc; /* 可选：添加边框 */
}
::v-deep .svg-display > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* 确保 SVG 按比例缩放 */
}
::v-deep .sss .el-form-item {
  margin-bottom: 15px;
}
</style>
