<template>
  <div class="home">
    <Layout>
      <Userdialog
        :dialogVisible.sync="dialogVisible"
        @close-visible="closeVisable"
        @project-data="updateProject"
        class="child-element"
        style="z-index: 99999"
      ></Userdialog>

      <Header v-if="show" class="el-header">
        <div style="display: flex">
          <Questiondialog
            v-if="firstLogin"
            @update-first-login="handleFirstLoginChange"
            :show-tools="showTools"
          ></Questiondialog>
          <!-- logo -->
          <img
            src="@/assets/logo/logo-blank.png"
            alt="Logo"
            style="width: 60px"
            @click="imgClick"
          />
          <div style="font-size: 40px; margin: 0 20px">LaCraft</div>
          <!-- 上方菜单 -->
          <div>
            <el-dropdown @command="handleCommand" trigger="click">
              <el-button
                type="primary"
                @mouseover="changeBackgroundColor(true)"
                @mouseleave="changeBackgroundColor(false)"
                class="menu-button"
              >
                File
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="New">New</el-dropdown-item>
                <el-dropdown-item command="Open">Open</el-dropdown-item>
                <el-dropdown-item command="Import">Import Project</el-dropdown-item>
                <el-dropdown-item command="Export">Export Project</el-dropdown-item>
                <!-- <el-dropdown-item command="Share">Share</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div>
            <el-dropdown @command="handleCommand" trigger="click">
              <el-button
                type="primary"
                @mouseover="changeBackgroundColor(true)"
                @mouseleave="changeBackgroundColor(false)"
                class="menu-button"
              >
                Setup
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="NewMaschine">New Machine</el-dropdown-item>
                <el-dropdown-item command="General Settings">General Settings</el-dropdown-item>
                <el-dropdown-item command="Download Driver">Download Driver</el-dropdown-item>
                <el-dropdown-item command="Config">Configuration</el-dropdown-item>
                <!-- <el-dropdown-item command="Marterials">Marterials</el-dropdown-item>
              <el-dropdown-item command="Cut Settings">Cut Settings</el-dropdown-item>
              <el-dropdown-item command="Machines">Machines</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div>
            <el-dropdown @command="handleCommand" trigger="click">
              <el-button
                type="primary"
                @mouseover="changeBackgroundColor(true)"
                @mouseleave="changeBackgroundColor(false)"
                class="menu-button"
              >
                Help
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="GuideBook">GuideBook</el-dropdown-item>
                <el-dropdown-item command="VideoGallery">VideoGallery</el-dropdown-item>
                <el-dropdown-item command="NewUserGuide">NewUserGuide</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- 导入 -->
        <!-- <import-JSON></import-JSON>
        <Divider type="vertical" />
        <import-file></import-file>
        <Divider type="vertical" /> -->
        <!-- 颜色开关 -->
        <!-- <Tooltip :content="$t('grid')">
          <iSwitch v-model="ruler" size="small" class="switch"></iSwitch>
        </Tooltip> -->
        <!-- <Divider type="vertical" /> -->
        <!-- <history></history> -->

        <!-- <div style="float: right">
          <save></save>
          <lang></lang>
        </div> -->
        <div style="display: flex">
          <set-size @update:dept="deptssss" @update:diameter="diameterss"></set-size>
          <div style="margin-left: 20px; display: flex">
            <div>
              <span>Machine:</span>
              <span>{{ maschineName ? maschineName : 'none' }}</span>
            </div>
            <div style="margin: 0 20px">
              <span>Connect:</span>
              <span>{{ join ? 'Yes' : 'No' }}</span>
            </div>
          </div>
          <div style="margin-right: 20px">
            <el-select v-model="selectValue" placeholder="Choose" class="select-com">
              <el-option
                v-for="item in comOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-button style="margin-left: 5px" @click="connectLaser" size="small">
              Connect
            </el-button>
          </div>
          <div>
            <el-button type="success" @click="debug" size="small">Move</el-button>
            <el-button type="success" @click="sendGcode" size="small">Preview</el-button>
            <el-button type="success" @click="carva" size="small">Craft</el-button>
          </div>
        </div>

        <!-- <setThreeSize></setThreeSize> -->
      </Header>
      <Content style="display: flex; height: calc(100vh - 64px)">
        <!-- <div v-if="show" style="width: 380px; height: 100%; background: #fff; display: flex"> -->
        <!-- <Menu
            :active-name="menuActive"
            accordion
            @on-select="(activeIndex) => (menuActive = activeIndex)"
            width="65px"
          >
            <MenuItem :name="1" class="menu-item">
              <Icon type="md-book" size="24" />
              <div>{{ $t('templates') }}</div>
            </MenuItem>
            <MenuItem :name="2" class="menu-item">
              <Icon type="md-images" size="24" />
              <div>{{ $t('elements') }}</div>
            </MenuItem>
            <MenuItem :name="3" class="menu-item">
              <Icon type="md-reorder" size="24" />
              <div>{{ $t('layers') }}</div>
            </MenuItem>
          </Menu> -->
        <!-- <div class="content"> -->
        <!-- 生成模板 -->
        <!-- <div v-show="menuActive === 1" class="left-panel"> -->
        <!-- <import-tmpl></import-tmpl> -->
        <!-- </div> -->
        <!-- 常用元素 -->
        <!-- <div v-show="menuActive === 2" class="left-panel">
              <tools></tools>
              <svgEl></svgEl>
            </div> -->
        <!-- 背景设置 -->
        <!-- <div v-show="menuActive === 3" class="left-panel">
              <layer></layer>
            </div>
          </div>
        </div> -->
        <!-- 画布区域 -->
        <div id="workspace" style="width: 50%; position: relative; background: #f1f1f1">
          <tools
            :save-project="projectData"
            v-if="showTools"
            @canvas-url="getCanvasUrl"
            :update-dept="dept"
            :update-diameter="diameter"
            :update-socket="getSocket"
            @sss-dd="carva"
          ></tools>
          <div class="canvas-box">
            <div class="inside-shadow"></div>
            <div v-if="ruler" class="coordinates-bar coordinates-bar-top" style="width: 50%"></div>
            <div
              v-if="ruler"
              class="coordinates-bar coordinates-bar-left"
              style="height: 100%"
            ></div>
            <!-- class design-stage-point 点状  design-stage-grid 棋盘 -->
            <canvas id="canvas" :class="ruler ? 'design-stage-grid' : ''"></canvas>
            <zoom></zoom>
            <mouseMenu></mouseMenu>
          </div>

          <Shape :update-dept="dept" :update-diameter="diameter"></Shape>
          <!-- <el-tabs type="border-card">
              <el-tab-pane label="Shape">
                <attribute v-if="show"></attribute>
                <set-size></set-size>
                <lock></lock>
              </el-tab-pane>
              <el-tab-pane label="Cut">Cut</el-tab-pane>
              <el-tab-pane label="Laser">
                <el-table :data="tableData" border style="width: 100%">
                  <el-table-column
                    prop="layer"
                    label="Layer"
                    width="60"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="mode"
                    label="Mode"
                    width="60"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="speed"
                    label="Speed/Power"
                    align="center"
                  ></el-table-column>
                </el-table>
                <div>
                  <ul>
                    <li>speed:5000m/s</li>
                    <li>Power:80%</li>
                    <li>Pass:1</li>
                    <li>Interval:0.1mm</li>
                  </ul>
                </div>
              </el-tab-pane>
            </el-tabs> -->
          <!-- <bg-bar></bg-bar> -->
          <!-- <group></group> -->
          <!-- <div class="attr-item"> -->
          <!-- <lock></lock> -->
          <!-- <dele></dele> -->
          <!-- <clone></clone> -->
          <!-- </div> -->
          <!-- 组对齐方式 -->
          <!-- <align></align> -->
          <!-- 居中对齐 -->
          <!-- <center-align></center-align> -->
          <!-- 翻转 -->
          <!-- <flip></flip> -->
          <div
            style="
              /* padding: 10px; */
              overflow-y: auto;
              position: absolute;
              z-index: 999;
              left: 5px;
              bottom: 5px;
            "
          >
            <layerssss></layerssss>
          </div>
        </div>
        <div id="three" style="width: 50%; position: relative; background: #f1f1f1">
          <!-- <div id="threejs-container" ref="threejsContainer"></div> -->
          <iframe
            src="https://ncviewer.com/"
            frameborder="0"
            width="100%"
            height="100%"
            title="Example Website Embed"
          ></iframe>
        </div>
        <!-- 属性区域 380-->
      </Content>
    </Layout>
    <el-dialog :visible.sync="debugDialog" :before-close="closeDebug" width="260px" :modal="true">
      <Debugdialog></Debugdialog>
      <div class="button-container">
        <el-button type="primary" @click="goHome">Home</el-button>
        <el-button type="primary" @click="hold">Hold</el-button>
        <el-button type="primary" @click="unlock">Unlock</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="Be carving"
      :visible.sync="engraveDialog"
      :before-close="closeEngrave"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="1000px"
      :modal="true"
    >
      <Engrave @start-carving="handleStartCarving"></Engrave>
    </el-dialog>

    <el-dialog
      :visible.sync="configDialog"
      :before-close="closeConfig"
      width="40%"
      :modal="true"
      class="config"
    >
      <Configdialog @child-event="getMachineList"></Configdialog>
    </el-dialog>
    <el-dialog
      title="Be carving"
      :visible.sync="progressDia"
      :before-close="closeprogress"
      width="1000px"
      :modal="true"
    >
      <!-- 进度条 -->
      <div style="width: 150px; margin-left: 5px">
        <el-progress :percentage="progress"></el-progress>
      </div>
      <div style="width: 50px">
        <i
          class="el-icon-video-play"
          style="font-size: 25px; line-height: 60px"
          @click="resume"
          v-show="!showIcon"
        ></i>
        <i
          class="el-icon-video-pause"
          style="font-size: 25px; line-height: 60px"
          @click="pause"
          v-show="showIcon"
        ></i>
      </div>
      <div>
        <el-button type="primary" @click="cancelRun">Cancel</el-button>
      </div>
      <div>
        <span>Remaining</span>
        <span>{{ formattedTimeLeft }}</span>
      </div>
      <div>
        <span>Engrave</span>
        <span>{{ engTime }}</span>
      </div>
    </el-dialog>
    <!-- Gcode展示 -->
    <el-dialog
      title="GCode"
      :visible.sync="gcodeDia"
      :before-close="closeGcode"
      width="600px"
      :modal="true"
    >
      <textarea id="gcodePreview" rows="20" cols="50" readonly></textarea>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { importPro } from '@/api/import';
import { exportPro } from '@/api/export';
import { machineList } from '@/api/maschine';

/* eslint-disable import/no-cycle */
import { selectFiles } from '@/utils/utils';
// import { v4 as uuid } from 'uuid';

// 导入元素
// import importJSON from '@/components/importJSON.vue';
// import importFile from '@/components/importFile.vue';

// 顶部组件
import Shape from '@/components/Shape.vue';
import layerssss from '@/components/layerssss.vue';
// import align from '@/components/align.vue';
// import centerAlign from '@/components/centerAlign.vue';
// import flip from '@/components/flip.vue';
// import save from '@/components/save.vue';
// import lang from '@/components/lang.vue';
// import clone from '@/components/clone.vue';
// import group from '@/components/group.vue';
import zoom from '@/components/zoom.vue';
// import lock from '@/components/lock.vue';
// import dele from '@/components/del.vue';

// 左侧组件
// import importTmpl from '@/components/importTmpl.vue';
import tools from '@/components/tools.vue';
// import svgEl from '@/components/svgEl.vue';
// import bgBar from '@/components/bgBar.vue';
import setSize from '@/components/setSize.vue';

// 右侧组件
// import history from '@/components/history.vue';
// import layer from '@/components/layer.vue';
// import attribute from '@/components/attribute.vue';

// 右键菜单
import mouseMenu from '@/components/contextMenu/index.vue';

// 功能组件
import EventHandle from '@/utils/eventHandler';
// import addTemplate from '@/core/addTemplate';

import { fabric } from 'fabric';
import * as THREE from 'three';
import Editor from '@/core';
// import setThreeSize from '@/components/setThreeSize.vue';

import Questiondialog from './components/Questiondialog/index.vue';
import Userdialog from './components/Userdialog/index.vue';
// 调试机器
import Debugdialog from './components/Debugdialog/index.vue';
import Engrave from './components/Engrave/index.vue';
// 材料刀头
import Configdialog from './components/Configdialog/index.vue';

const event = new EventHandle();
const canvas = {};
const renderer = {};
const allSocket = {};
export default {
  name: 'HomeView',
  provide: {
    canvas,
    fabric,
    event,
    renderer,
    THREE,
    allSocket,
  },
  data() {
    return {
      unCraft: '',
      showGcode: false,
      gcodeDia: false,
      comOptions: [],
      selectValue: '',
      canvasUrl: '',
      showTools: false,
      projectData: {},
      tableData: [
        {
          layer: 1,
          mode: 2,
          speed: 3,
        },
        {
          layer: 1,
          mode: 2,
          speed: 3,
        },
      ],
      menuActive: 1,
      show: false,
      select: null,
      ruler: false,
      dialogVisible: true,
      debugDialog: false,
      engraveDialog: false,
      configDialog: false,
      firstLogin: false,
      dept: 0,
      diameter: 0,
      maschineName: null,
      maschineId: null,
      defaultMachine: {},
      join: false,
      progress: 0,
      showIcon: true,
      socket: null,
      action: null,
      connected: false,
      intervalId: null,
      remainingTime: null,
      engravingtime: null,
      progressDia: false,
    };
  },
  watch: {
    deptssss(newVal, oldVal) {
      console.log(oldVal, newVal, '111111111111111');
      this.dept = newVal;
    },
    diameterss(newVal, oldVal) {
      console.log(oldVal, newVal, '111111111111111');
      this.diameter = newVal;
    },
  },
  computed: {
    // 监听 Vuex 中的 socket 状态
    getSocket() {
      console.log(this.$store.state.webSocket.showLaser, '333333333333333333333333');
      return this.$store.state.webSocket.showLaser;
    },
    // 是否继续查找端口
    unFind() {
      console.log(this.$store.state.webSocket.find, '333333333333333333333333');
      return this.$store.state.webSocket.find;
    },
    sendType() {
      return this.$store.state.webSocket.sendType;
    },
    formattedTimeLeft() {
      if (this.remainingTime) {
        const hours = Math.floor(this.remainingTime / (1000 * 60 * 60));
        const minutes = Math.floor((this.remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((this.remainingTime % (1000 * 60)) / 1000);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
          seconds
        ).padStart(2, '0')}`;
      }
      return '00:00:00';
    },
    engTime() {
      if (this.engravingtime) {
        const hours = Math.floor(this.engravingtime / (1000 * 60 * 60));
        const minutes = Math.floor((this.engravingtime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((this.engravingtime % (1000 * 60)) / 1000);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
          seconds
        ).padStart(2, '0')}`;
      }
      return '00:00:00';
    },
  },
  components: {
    setSize,
    // setThreeSize,
    tools,
    Shape,
    layerssss,
    // bgBar,
    // lock,
    // layer,
    // align,
    // attribute,
    // dele,
    // importFile,
    // save,
    // lang,
    // importJSON,
    // clone,
    // flip,
    // importTmpl,
    // centerAlign,
    // group,
    zoom,
    // svgEl,
    // history,
    mouseMenu,
    Questiondialog,
    Userdialog,
    Debugdialog,
    Engrave,
    Configdialog,
  },

  created() {
    this.$Spin.show();
    this.getMachineList();
    this.connectToHardware();
  },
  mounted() {
    this.canvas = new fabric.Canvas('canvas', {
      fireRightClick: true, // 启用右键，button的数字为3
      stopContextMenu: true, // 禁止默认右键菜单
      controlsAboveOverlay: true, // 超出clipPath后仍然展示控制条
    });
    canvas.c = this.canvas;
    // canvas.d = canvas.c.d;
    event.init(canvas.c);
    canvas.editor = new Editor(canvas.c);
    canvas.c.renderAll();
    this.show = true;
    this.$Spin.hide();
    // 3D
    // const container = this.$refs.threejsContainer;
    // 设置 Three.js 渲染器尺寸
    // container.style.width = '100%';
    // container.style.height = '100%';
    // this.canvas.on('after:render', () => {
    //   // 注意：此事件可能会频繁触发，请根据需求使用
    //   this.logAllObjects();
    // });
    // console.log(container.clientHeight, 'container.clientHeight');
  },
  beforeDestroy() {
    // 清除定时器
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    // 关闭WebSocket连接
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close(1000, 'Component is being destroyed');
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    deptssss(e) {
      this.dept = e;
      console.log(e, 'eeeeeeeeeee');
    },
    diameterss(e) {
      console.log(e, '22222222');
      this.diameter = e;
    },
    // logAllObjects() {
    //   const objects = this.canvas.getObjects();
    //   const filteredObjects = objects.filter(
    //     (obj) => obj.id !== 'workspace' && (!obj.fill || obj.fill !== '#F1F1F1')
    //   );
    //   console.log('Current objects on canvas:', filteredObjects);
    //   // 如果你需要对每个对象进行更详细的处理，可以遍历 objects 数组
    //   // objects.forEach((obj) => {
    //   //   console.log('ssssssssssssssssssssss', obj);
    //   //   // 在这里可以对每个对象执行特定的操作
    //   // });
    // },
    getCanvasUrl(canvasUrl) {
      this.canvasUrl = canvasUrl;
    },
    // 双击project获取project数据
    updateProject(newData) {
      this.projectData = newData;
    },
    closeVisable() {
      this.dialogVisible = false;
      this.showTools = true;
    },
    imgClick() {
      this.showTools = false;
      this.dialogVisible = true;
    },
    handleFirstLoginChange(value) {
      this.firstLogin = value;
      // 更新localStorage
      localStorage.setItem('firstLogin', this.firstLogin);
    },
    changeBackgroundColor(isHovered) {
      if (isHovered) {
        this.buttonColor = '#3a8ee6'; // 鼠标悬停时的背景色
      } else {
        this.buttonColor = '#409EFF'; // 默认背景色
      }
    },
    handleCommand(command) {
      if (command === 'New') {
        this.$confirm(
          'Would you like to open a new one without save current project?',
          'Create a new canvas',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
        )
          .then(() => {
            this.$message({
              type: 'success',
              message: 'Created successfully',
            });
            const objects = this.canvas.getObjects();
            objects.forEach((obj) => {
              if (obj.id !== 'workspace' && obj.id !== 'machine') {
                this.canvas.remove(obj);
              }
            });
            this.canvas.renderAll();
            localStorage.setItem('canvasID', null);
            this.$emit('close-visible');
            const project = {
              id: 0,
            };
            this.updateProject(project);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Cancelled',
            });
          });
      } else if (command === 'Open') {
        this.imgClick();
      } else if (command === 'Import') {
        selectFiles({ accept: '.lac', multiple: true }).then((fileList) => {
          if (fileList && fileList.length > 0) {
            // const promises = Array.fom(fileList).map((file) => );
            const data = fileList[0];
            const formData = new FormData();
            formData.append('file', data);
            console.log(fileList, 'fileList');
            console.log(fileList[0].name, 'fileList');
            importPro(formData).then((res) => {
              fabric.loadSVGFromString(res.data, (objects) => {
                const filteredObjects = objects.filter(
                  (obj) =>
                    obj.id !== 'workspace' &&
                    obj.id !== 'machine' &&
                    (!obj.fill || obj.fill !== '#F1F1F1')
                );
                filteredObjects.forEach((obj) => {
                  this.canvas.add(obj);
                });
                this.canvas.renderAll();
              });
              // this.insertSvgFile();
              // console.log(res.data, 'resssssssssssssss');
            });
          } else {
            console.log('没有选择文件');
          }
        });
      } else if (command === 'Export') {
        // const workspace = this.canvas.c.getObjects().find((item) => item.id === 'workspace');
        // const objects = this.canvas.c.getObjects();
        const workspace = this.canvas.getObjects().find((item) => item.id === 'workspace');
        const { left, top, width, height } = workspace;
        const dataUrl = this.canvas.toSVG({
          width,
          height,
          viewBox: {
            x: left,
            y: top,
            width,
            height,
          },
        });
        const data = {
          id: localStorage.getItem('canvasID'),
          name: `Project${localStorage.getItem('canvasID')}`,
          userId: localStorage.getItem('id'),
          canvasUrl: this.canvasUrl,
          materialInfo: {
            // 材料尺寸
            simension: '{"X":"900 mm","Y":"1200 mm","Z":"19 mm"}',
            previewUrl: this.canvasUrl,
            color: '#FF0000',
            extInfo: '',
          },
          // 设备id
          machineId: this.maschineId,
          previewUrl: this.canvasUrl,
        };
        exportPro(data).then((res) => {
          console.log(res, 'res');

          if (res.code === 200) {
            const apiData = res.data;
            const fullSvgContent = `${apiData}\n${dataUrl}`;
            const fileStr = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
              fullSvgContent
            )}`;
            this.downFile(fileStr, `Project${localStorage.getItem('canvasID')}.lac`);
          }
        });
      } else if (command === 'NewMaschine') {
        this.$router.push({ path: '/newmaschine' });
        console.log(11);
      } else if (command === 'Config') {
        this.configDialog = true;
        console.log(111);
      } else if (command === 'GuideBook') {
        window.open('http://support.517788.xyz');
      } else if (command === 'VideoGallery') {
        window.open('http://support.517788.xyz');
      } else if (command === 'NewUserGuide') {
        window.open('http://support.517788.xyz');
      }
      console.log(`选择了: ${command}`);
    },
    downFile(fileStr, fileType) {
      const anchorEl = document.createElement('a');
      anchorEl.href = fileStr;
      anchorEl.download = `${fileType}`;
      document.body.appendChild(anchorEl); // required for firefox
      anchorEl.click();
      anchorEl.remove();
    },
    // 获取机器列表
    getMachineList() {
      const data = {
        userId: localStorage.getItem('id'),
      };
      machineList(data).then((res) => {
        console.log('this.defaultMachinethis.defaultMachinethis.defaultMachine');
        if (res.code === 200) {
          if (res.data.length > 0) {
            console.log(1111);
            this.defaultMachine = res.data.find((item) => item.isDefault === 1);
            this.maschineName = this.defaultMachine.machineName;
            this.maschineId = this.defaultMachine.id;
            localStorage.setItem('maschineId', this.maschineId);
          }
        }
        console.log(res, 'res');
      });
    },
    // 点击调试机器
    debug() {
      this.debugDialog = true;
    },
    // 关闭调试
    closeDebug() {
      this.debugDialog = false;
    },
    // 获取GCode
    async sendGcode() {
      this.showGcode = true;
      this.$store.dispatch('webSocket/setSendType', 'SVG');
      const This = this;
      function addDescToFabricObject(fabricObject) {
        if (fabricObject && typeof fabricObject.toSVG === 'function' && fabricObject.des) {
          const originalToSVG = fabricObject.toSVG;
          fabricObject.toSVG = function (dim, options) {
            let svgString = originalToSVG.call(this, dim, options);
            svgString = svgString.replace(/Q\s*NaN\s+NaN\s+NaN\s+NaN\s+L\s+NaN\s+NaN/gi, '');
            // 构建 desc 内容
            // const description = Object.entries(fabricObject.des)
            //   .map(([key, value]) => `${encodeURIComponent(key)}: ${encodeURIComponent(value)}`)
            //   .join('; ');
            const description = JSON.stringify(fabricObject.des, null, 2);
            const descElement = `<desc>${description}</desc>`;
            // 将 <desc> 插入到 <g> 或者其他顶层元素内
            svgString = svgString.replace(
              /(<(rect|circle|ellipse|line|polyline|polygon|path)[^>]*)\/>/,
              `$1>${descElement}</$2>`
            );
            // svgString = svgString.replace(/(<g[^>]*>)/, `$1${descElement}`);
            return svgString;
          };
        }
      }
      const objects = this.canvas.getObjects();
      const filteredObjects = objects.filter(
        (obj) =>
          obj.id !== 'workspace' &&
          obj.id !== 'machine' &&
          obj.id !== 'coordinate' &&
          !(obj instanceof fabric.Rect && obj.fill && obj.fill.source instanceof HTMLCanvasElement)
      );
      const tempCanvas = new fabric.StaticCanvas(null, {
        width: this.canvas.width,
        height: this.canvas.height,
      });
      filteredObjects.forEach((obj) => {
        if (!obj.toObject) {
          obj.toObject = function () {
            return fabric.Object.prototype.toObject
              .call(this)
              .concat(['speed', 'power', 'pass', 'interval']);
          };
        }
      });
      async function cloneObjectRecursively(index) {
        if (index >= filteredObjects.length) {
          return;
        }
        const obj = filteredObjects[index];
        return new Promise((resolve) => {
          obj.clone((clonedObj) => {
            console.log(obj, 'obj');

            console.log(clonedObj, 'clonedObjclonedObj');
            try {
              if (clonedObj) {
                clonedObj.set({
                  width: clonedObj.width * clonedObj.scaleX,
                  height: clonedObj.height * clonedObj.scaleY,
                  scaleX: 1,
                  scaleY: 1,
                  des: {
                    feedRate: obj.speed,
                    spindleSpeed: obj.power,
                    passes: obj.pass,
                    interval: obj.interval,
                    dept: obj.dept,
                    zIndex: obj.zIndex,
                    cutType: obj.cutType,
                  },
                });
                if (obj.zIndex !== '00') {
                  clonedObj.set({
                    des: {
                      feedRate: obj.speed,
                      spindleSpeed: obj.power,
                      passes: obj.pass,
                      interval: obj.interval,
                      zIndex: obj.zIndex,
                      cutType: obj.cutType,
                    },
                  });
                } else {
                  clonedObj.set({
                    des: {
                      feedRate: obj.speed,
                      spindleSpeed: obj.power,
                      passes: obj.pass,
                      interval: obj.interval,
                      targetDepth: obj.dept,
                      startDepth: 0,
                      zIndex: obj.zIndex,
                      cutType: obj.cutType,
                    },
                  });
                }
                addDescToFabricObject(clonedObj);
                tempCanvas.add(clonedObj);
              } else {
                console.warn(`Cloned object ${index} is undefined or null`);
              }
              resolve();
            } catch (error) {
              console.error('Error cloning object:', error);
              resolve(); // 即使出错也要 resolve 以确保递归继续执行
            }
          });
        }).then(() => cloneObjectRecursively(index + 1));
      }

      await cloneObjectRecursively(0);
      const mar = objects.find((item) => item.id === 'machine');
      const dataUrl = tempCanvas.toSVG();
      const newSvg = `${mar.left},${mar.top}\n${dataUrl}`;
      console.log(newSvg, 'dataUrldataUrl');
      const blob = new Blob([newSvg], { type: 'image/svg+xml' });
      // 基于 Blob 创建一个 File 对象
      this.svgFile = new File([blob], `Project${localStorage.getItem('canvasID')}.svg`, {
        type: 'image/svg+xml',
      });
      console.log(this.svgFile, 'this.svgFilethis.svgFile');
      // this.uploadToServer(this.svgFile);
      // const formData = new FormData();
      // formData.append('file', this.svgFile, `项目${localStorage.getItem('canvasID')}.svg`);
      const reader = new FileReader();
      console.log(reader, 'readerreaderreader');
      reader.onload = function (e) {
        const originalArrayBuffer = e.target.result;
        const a = { toolDiameter: 3.175, feedRate: 100 };
        const jsonString = JSON.stringify(a);
        const encoder = new TextEncoder();
        const uint8Array = encoder.encode(jsonString);
        const arraylength = uint8Array.length;
        const originalUint8Array = new Uint8Array(originalArrayBuffer);
        // // 创建一个新的 Uint8Array，长度是原始的 + 1 字节
        const newArray = new Uint8Array(originalUint8Array.length + 3 + arraylength);
        // // 设置第一个字节为 1
        if (arraylength <= 127) {
          newArray[0] = 0;
          newArray[1] = 0;
          newArray[2] = arraylength;
        } else {
          // const firstDigit = parseInt(num.toString()[0], 10)
          // const lastTwoDigits = parseInt(num.toString().slice(-2), 10);
          newArray[0] = 0;
          newArray[1] = arraylength - 127;
          newArray[2] = 127;
        }
        newArray.set(uint8Array, 3);

        // // 将原始的 Uint8Array 数据复制到新的 Uint8Array 中，从第二个字节开始
        newArray.set(originalUint8Array, 3 + arraylength);
        This.getSocket.send(newArray.buffer);
        console.log(newArray.buffer, 'originalArrayBuffer');
      };
      // console.log(formData, 'formDataformData');
      reader.readAsArrayBuffer(this.svgFile);
    },
    // 开始雕刻
    carva() {
      this.engraveDialog = true;
    },
    closeEngrave() {
      this.engraveDialog = false;
    },
    // 导入gcode雕刻
    carveGcode() {
      this.action = 'carve';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
      this.progressDia = true;
    },
    handleStartCarving() {
      this.progressDia = true;
    },
    closeprogress() {
      this.progressDia = false;
    },
    // 关闭材料弹窗
    closeConfig() {
      this.configDialog = false;
    },
    // 连接com口
    connectLaser() {
      this.action = 'connect';
      const params = {
        action: 'disconnect',
      };
      this.getSocket.send(JSON.stringify(params));

      const data = {
        action: this.action,
        port: this.selectValue,
      };
      this.getSocket.send(JSON.stringify(data));
      console.log(this.selectValue);
    },
    // 开始
    resume() {
      this.showIcon = true;
      this.action = 'resume';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    // 暂停
    pause() {
      this.showIcon = false;
      this.action = 'pause';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
      // if (!this.intervalId) {
      //   this.intervalId = setInterval(() => {
      //     this.updateTimeLeft();
      //   }, 1000);
      // }
    },
    async connectToHardware() {
      const startPort = 7500;
      // const endPort = 7501;

      // for (let port = startPort; port <= endPort; port++) {
      //   try {
      // 假设 toggleShowLaser 返回一个 Promise
      this.$store.dispatch('webSocket/toggleShowLaser', startPort);
      //     // 检查是否找到连接
      //     if (this.unFind()) {
      //       console.log(`Connected on port ${port}`);
      //       break; // 成功连接后中断循环
      //     }
      //   } catch (error) {
      //     console.error(`Failed to connect on port ${port}:`, error); // 尝试下一个端口
      //   }
      // }
      console.log(this.getSocket, 'getSocketgetSocketgetSocket');
      console.log(this.getSocket.readyState, 'getSocketgetSocketgetSocket');
      console.log(this.getSocket.binaryType, 'getSocketgetSocketgetSocket');
      this.getSocket.onopen = () => {
        this.action = 'serials';
        this.$store.dispatch('webSocket/setSendType', '');
        const ws = { action: this.action };
        this.getSocket.send(JSON.stringify(ws));
        // this.startTimer();
      };
      this.getSocket.onmessage = (e) => {
        console.log(this.sendType, 'this.sendTypethis.sendType');

        console.log(e.data instanceof Blob, 'e.data instanceof Blobe.data instanceof Blob');

        console.log(e.data, 'eventssssssss');
        if (this.action === 'serials' && e.data !== 'ping' && this.sendType === '') {
          console.log(1111);
          this.comOptions = JSON.parse(e.data).map((item) => ({ label: item, value: item }));
        } else if (this.checked === true) {
          console.log(this.checked, 'checkedcheckedchecked');
          if (this.checked === true) {
            const logOutput = document.getElementById('logOutput');
            logOutput.textContent = `${e.data}\n${logOutput.textContent}`;
            // logOutput.textContent += event.data + '\n';
            // console.log(logOutput.textContent,'logOutput.textContentlogOutput.textContent');
            logOutput.scrollTop = 0; // 自动滚动到底部
          }
        } else if (this.action === 'carve' || this.action === 'resume' || this.progressDia) {
          if (typeof e.data === 'string' && /^\s*\{.*\}\s*$/.test(e.data)) {
            if (JSON.parse(e.data).success === true && JSON.parse(e.data).progress === 100) {
              this.$message.success('Engraving completed');
              this.progress = JSON.parse(e.data).progress;
            } else {
              // 如果是，则解析 JSON 字符串为对象
              this.progress = JSON.parse(e.data).progress;
              console.log(this.progress);
              console.log(this.progress, 'progressprogressprogress');
              // 剩余时间
              const [remainingHours, remainingMinutes, remainingSeconds] = JSON.parse(e.data)
                .estimatedTime.split(':')
                .map(Number);
              this.remainingTime =
                (remainingHours * 60 * 60 + remainingMinutes * 60 + remainingSeconds) * 1000;
              console.log(this.remainingTime, 'remainingTimeremainingTime');

              // 雕刻时间
              const [engravingHours, engravingMinutes, engravingSeconds] = JSON.parse(e.data)
                .duration.split(':')
                .map(Number);
              this.engravingtime =
                (engravingHours * 60 * 60 + engravingMinutes * 60 + engravingSeconds) * 1000;
              console.log(this.engravingtime, 'engravingtimeengravingtime');
              console.log(this.formattedTimeLeft, 'formattedTimeLeftformattedTimeLeft');

              // this.updateTimeLeft();
              // this.intervalId = setInterval(() => {
              //   this.updateTimeLeft();
              // }, 1000); // 每秒更新一次
              // console.log(1);
              this.messageData = e.data;
            }
          }
        } else if (e.data === 'Connecting') {
          this.join = true;
        } else if (e.data instanceof ArrayBuffer && this.sendType === 'SVG') {
          console.log(333);
          const arrayBuffer = e.data;
          const uint8Array = new Uint8Array(arrayBuffer);
          const decoder = new TextDecoder('utf-8');
          const jsonString = decoder.decode(uint8Array);
          console.log('Decoded JSON String:', jsonString);
          const jsonObject = JSON.parse(jsonString);
          console.log('Parsed JSON Object:', jsonObject);
        } else if (e.data instanceof Blob && this.sendType === 'SVG') {
          // 处理 Blob 类型的数据
          console.log(444);
          this.gcodeDia = true;
          console.log('Received blob data:', e.data);
          // 如果需要读取 Blob 内容，可以创建一个 FileReader 对象
          const reader = new FileReader();
          reader.onloadend = function () {
            const cleanBase64 = reader.result.replace(
              /^data:application\/octet-stream;base64,/,
              ''
            ); // 去除前缀
            const decodedString = atob(cleanBase64);
            document.getElementById('gcodePreview').value = decodedString;
            console.log(decodedString, 'decodedStringdecodedString');
            console.log('The blob as a data URL:', reader.result);
          };
          reader.readAsDataURL(e.data); // 或者使用其他 readAs 方法
        } else if (e.data.includes('successfully') && this.sendType === 'Gcode') {
          console.log(2222);
          // this.carveGcode();
        } else if (this.showGcode === true && e.data.includes('successfully')) {
          // document.getElementById('gcodePreview').value = e.data;
          console.log(e.data, 'datadatadatadatadata');
        }
      };
      this.getSocket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      this.getSocket.onclose = (e) => {
        console.log('WebSocket connection closed with code:', e.code, 'reason:', e.reason);
        if (this.timerId) {
          clearInterval(this.timerId);
        }
      };
    },
    // 回到原点
    goHome() {
      this.action = 'home';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    //
    hold() {
      this.action = 'hold';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },

    unlock() {
      this.action = 'unlock';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    // 取消雕刻
    cancelRun() {
      this.$confirm('Definite cancel engraving?', 'Unengrave', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Cancel  successfully!',
          });
          this.action = 'cancel';
          const data = { action: this.action };
          this.getSocket.send(JSON.stringify(data));
          this.closeprogress();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Cancelled',
          });
        });
    },
    updateTimeLeft() {
      if (this.timeLeft > 0) {
        this.timeLeft -= 1000;
      } else {
        clearInterval(this.intervalId);
        this.timeLeft = 0;
      }
    },
    closeGcode() {
      this.gcodeDia = false;
    },
  },
};
</script>
<style lang="less" scoped>
// 属性面板样式
/deep/ .attr-item {
  position: relative;
  margin-bottom: 12px;
  height: 40px;
  padding: 0 10px;
  background: #f6f7f9;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .ivu-tooltip {
    text-align: center;
    flex: 1;
  }
}

.ivu-menu-vertical .menu-item {
  text-align: center;
  padding: 10px 2px;
  box-sizing: border-box;
  font-size: 12px;

  & > i {
    margin: 0;
  }
}

::v-deep .ivu-layout-header {
  height: 60px;
  padding: 0 10px;
  border-bottom: 1px solid #eef2f8;
  background: #fff;
  height: var(--height);
  line-height: var(--height);
  display: flex;
  color: #333;
  font-size: large;
  background-color: #2a82e4;
  color: #333;
}
.el-header {
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}

.home,
.ivu-layout {
  height: 100vh;
}

.icon {
  display: block;
}

.canvas-box {
  position: relative;
}

.inside-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 15px 5px blue;
  box-shadow: inset 0 0 9px 2px #0000001f;
  z-index: 2;
  pointer-events: none;
}

#canvas {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  // background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=");
  // background-size: 30px 30px;
}

#workspace {
  overflow: hidden;
}

.content {
  flex: 1;
  width: 220px;
  padding: 10px;
  padding-top: 0;
  height: 100%;
  overflow-y: auto;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
  background: none;
}
// 标尺与网格背景
.switch {
  margin-right: 10px;
}
.design-stage-point {
  --offsetX: 0px;
  --offsetY: 0px;
  --size: 20px;
  background-size: var(--size) var(--size);
  background-image: radial-gradient(circle, #2f3542 1px, rgba(0, 0, 0, 0) 1px);
  background-position: var(--offsetX) var(--offsetY);
}

.design-stage-grid {
  // dom.style.setProperty('--offsetX', `${point.x + e.clientX}px`) 通过修改 偏移量 可实现跟随鼠标效果 --size 则为间距
  // dom.style.setProperty('--offsetY', `${point.y + e.clientY}px`)
  --offsetX: 0px;
  --offsetY: 0px;
  --size: 16px;
  --color: #dedcdc;
  background-image: linear-gradient(
      45deg,
      var(--color) 25%,
      transparent 0,
      transparent 75%,
      var(--color) 0
    ),
    linear-gradient(45deg, var(--color) 25%, transparent 0, transparent 75%, var(--color) 0);
  background-position: var(--offsetX) var(--offsetY),
    calc(var(--size) + var(--offsetX)) calc(var(--size) + var(--offsetY));
  background-size: calc(var(--size) * 2) calc(var(--size) * 2);
}

.coordinates-bar {
  --ruler-size: 16px;
  --ruler-c: #808080;
  --rule4-bg-c: #252525;
  --ruler-bdw: 1px;
  --ruler-h: 8px;
  --ruler-space: 5px;
  --ruler-tall-h: 16px;
  --ruler-tall-space: 15px;
  position: absolute;
  z-index: 2;
  background-color: var(--rule4-bg-c);
}
.coordinates-bar-top {
  cursor: row-resize;
  top: 0;
  left: 0;
  height: var(--ruler-size);
  width: 100%;
  background-image: linear-gradient(90deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0),
    linear-gradient(90deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0);
  background-repeat: repeat-x;
  background-size: var(--ruler-space) var(--ruler-h), var(--ruler-tall-space) var(--ruler-tall-h);
  background-position: bottom;
}
.coordinates-bar-left {
  cursor: col-resize;
  top: var(--ruler-size);
  width: var(--ruler-size);
  height: 100%;
  left: 0;
  background-image: linear-gradient(0deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0),
    linear-gradient(0deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0);
  background-repeat: repeat-y;
  background-size: var(--ruler-h) var(--ruler-space), var(--ruler-tall-h) var(--ruler-tall-space);
  background-position: right;
}
.el-button--primary {
  background-color: #2a82e4; /* 鼠标悬停时的背景色 */
}
.el-button {
  color: white;
  border: none;
  transition: background-color 0.3s ease;
  color: black;
}
.el-button:hover {
  background-color: #3a8ee6; /* 鼠标悬停时的背景色 */
}

.el-dropdown-menu__item {
  color: #303133;
}

.el-dropdown-menu__item:hover {
  background-color: #ecf5ff; /* 下拉菜单项鼠标悬停时的背景色 */
  color: #409eff;
}
.el-tabs--border-card > .el-tabs__content {
  padding: 5px;
}
.button-container {
  display: flex;
  flex-direction: column;
  gap: 5px; /* 控制按钮间的间距 */
}
.button-container > .el-button {
  margin: 0;
}
.select-com {
  width: 100px;
}
.el-progress {
  line-height: 60px;
}
/deep/ .config .el-dialog {
  max-height: 900px;
}
/deep/ .menu-button span {
  font-size: 24px;
}
</style>
