<template>
  <div style="display: flex">
    <div class="CNC-button" @click="setCnc('black', '00')">CNC</div>
    <div class="color-list">
      <span
        v-for="(item, i) in colorList"
        :key="item"
        :style="`background:${item}`"
        @click="setLaser(item, '0' + (i + 1))"
      >
        {{ '0' + (i + 1) }}
      </span>
      <span class="el-icon-plus" @click="addColor" v-show="addShow"></span>
    </div>
  </div>
</template>

<script>
import select from '@/mixins/select';

export default {
  name: 'layer-ssss',
  mixins: [select],
  data() {
    return {
      addShow: true,
      colorList: ['#5F2B63', '#B23554', '#F27E56', '#FCE766'],
      availableColors: [
        '#FF5733',
        '#33FF57',
        '#3357FF',
        '#FF33A1',
        '#33FFF9',
        '#FFA500',
        '#8B0000',
        '#006400',
        '#00008B',
        '#8B008B',
        '#FF1493',
        '#20B2AA',
        '#FFD700',
        '#FF69B4',
        '#ADD8E6',
        '#90EE90',
        '#D3D3D3',
        '#F08080',
        '#FA8072',
        '#FF6347',
        '#FF4500',
        '#FF8C00',
        '#FFA07A',
        '#FFB6C1',
        '#FFC0CB',
        '#FFDAB9',
        '#FFE4B5',
        '#FFEFD5',
        '#FFFACD',
        '#FFF8DC',
        '#FFF5EE',
        '#FFFAF0',
        '#FFFFF0',
        '#FFFFE0',
        '#FFFF00',
        '#FFDAB9',
        '#FFA07A',
        '#FF7F50',
        '#FF6347',
        '#FF4500',
        '#FF3030',
        '#FF1C1C',
        '#FF0000',
      ],
    };
  },
  created() {
    // const activeObject = this.canvas.c.getActiveObjects()[0];
    // console.log(activeObject, 'activeObject');
  },
  mounted() {},
  methods: {
    setCnc(color, zIndex) {
      this.$store.dispatch('showTab/toggleShowLaser', true);
      this.setLayser(color, zIndex);
    },
    setLaser(color, zIndex) {
      console.log(color, zIndex, 'zzzzzzzzzzz');
      this.$store.dispatch('showTab/toggleShowLaser', false);
      this.setLayser(color, zIndex);
    },
    addColor() {
      const randomColor =
        this.availableColors[Math.floor(Math.random() * this.availableColors.length)];
      if (this.colorList.length === 9) {
        this.addShow = false;
      }
      // 将随机颜色添加到 colorList
      this.colorList.push(randomColor);
    },
    setLayser(color, layer) {
      const activeObject = this.canvas.c.getActiveObjects()[0];
      console.log(activeObject, 'activeObjectactiveObjectactiveObject');
      console.log(
        activeObject.stroke === null &&
          activeObject.stroke === 'blue' &&
          activeObject.strokeWidth === 1,
        '11111111'
      );

      if (activeObject.fill === null) {
        console.log(layer, 'layerlayerlayerlayerlayerlayer');
        activeObject.set('zIndex', layer);
        activeObject.set('fill', null); // 移除填充
        activeObject.set('stroke', color); // 设置描边颜色
        activeObject.set('cutType', 'Laser - On path');
        console.log(activeObject, 'activeObjectactiveObjectactiveObjectactiveObject');
        this.canvas.c.renderAll();
      }
      if (
        activeObject.stroke === null ||
        activeObject.stroke === 'blue' ||
        activeObject.strokeWidth === 1
      ) {
        console.log(layer, 'layerlayer');
        activeObject.set('zIndex', layer);
        activeObject.set('fill', color); // 移除填充
        activeObject.set('stroke', null); // 设置描边颜色
        activeObject.set('cutType', 'Laser - Fill');
        this.canvas.c.renderAll();
        console.log(activeObject, 'activeObjectactiveObjectactiveObjectactiveObject');
      }

      activeObject.set('speed', 6000);
      activeObject.set('power', 20); // 移除填充
      activeObject.set('pass', 1);
      activeObject.set('Interval', 0.1);
    },
  },
};
</script>

<style lang="less" scoped>
.CNC-button {
  width: 100px;
  background: black;
  color: white;
  line-height: 30px;
  text-align: center;
  height: 30px;
}
.color-list {
  span {
    display: inline-block;
    color: black;
    line-height: 30px;
    text-align: center;
    height: 30px;
    width: 30px;
    margin-left: 5px;
  }
  .el-icon-plus {
    color: black;
  }
}
</style>
