import { render, staticRenderFns } from "./layerssss.vue?vue&type=template&id=70b8b8ab&scoped=true"
import script from "./layerssss.vue?vue&type=script&lang=js"
export * from "./layerssss.vue?vue&type=script&lang=js"
import style0 from "./layerssss.vue?vue&type=style&index=0&id=70b8b8ab&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b8b8ab",
  null
  
)

export default component.exports