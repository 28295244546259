// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  showLaser: null,
  find: true,
  sendType: '',
};
const mutations = {
  toggleShowLaser(aaa, sss) {
    aaa.showLaser = sss;
  },
  setShowLaser(aaa, value) {
    aaa.find = value;
  },
  setSendType(aaa, value) {
    aaa.sendType = value;
  },
};
const actions = {
  toggleShowLaser({ commit }, port) {
    const socket = new WebSocket(`ws://localhost:${port}/ws`);
    commit('toggleShowLaser', socket);
  },
  setShowLaser({ commit }, value) {
    commit('setShowLaser', value);
  },
  setSendType({ commit }, value) {
    commit('setSendType', value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
