/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validEmail(str) {
  const regex = /^[^@]+@[^@]+\.[^@]+$/;
  return regex.test(str.trim());
}
export function validPassword1(str) {
  const minLength = 8;
  const trimmedStr = str.trim();

  if (trimmedStr.length < minLength) {
    return false;
  }
  return true;
}
export function validPassword2(str) {
  const categories = [
    /[a-z]/, // 小写字母
    /[A-Z]/, // 大写字母
    /\d/, // 数字
    /[@$!%*?&]/, // 特殊符号
  ];

  let count = 0;
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].test(str)) {
      count++;
    }
  }

  if (count < 2) {
    return false;
  }
  return true;
}

// 至少包含数字、大写字母、小写字母和特殊符号中的两个
