<template>
  <div class="container">
    <el-dialog
      :visible.sync="vis"
      :close-on-click-modal="false"
      :before-close="handleClose"
      width="1000px"
      :modal="true"
    >
      <template #title>
        <div class="dialog-title">
          <span style="margin-right: 10px">{{ email }}</span>
          <el-button type="text" @click="logoutUser">Log out</el-button>
        </div>
      </template>
      <el-tabs v-model="activeTab" type="card">
        <template solt="header">
          <div
            v-if="activeTab == 'Projects'"
            class="tabs-header"
            style="height: 35px; justify-content: space-between; display: flex; line-height: 35px"
          >
            <div>
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
              >
                Select All
              </el-checkbox>
            </div>
            <div v-if="!anyChecked">
              <!-- <el-dropdown trigger="click" @command="handleClick">
                <el-button size="small" split-button>
                  {{ currentSort }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="Name">Name</el-dropdown-item>
                  <el-dropdown-item command="Date">Date</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button size="small">排序</el-button> -->
            </div>
            <div v-else>
              <el-button type="danger" size="small" @click="deleteSelectedCards">Delete</el-button>
            </div>
          </div>
        </template>
        <el-tab-pane label="Projects" name="Projects">
          <div class="projects-container">
            <el-card
              shadow="hover"
              @mouseenter.native="handleMouseEnter(index)"
              @mouseleave.native="handleMouseLeave(index)"
              @dblclick.native="handleDoubleClick(index)"
              v-for="(project, index) in projects"
              :key="index"
              class="card-with-delete-icon"
            >
              <div class="card-content">
                <i
                  v-if="showDeleteIcon[index]"
                  class="el-icon-delete delete-icon"
                  @click="handleDelete(project)"
                ></i>
              </div>
              <el-image
                style="width: 100%; height: 125px"
                :src="project.canvasUrl"
                fit="cover"
                :key="index"
              ></el-image>
              <div style="padding: 0 0">
                <div style="margin-bottom: 10px; font-size: 20px; font-weight: 600">
                  {{ project.name }}
                </div>
                <div class="bottom">
                  <div>
                    {{ currentDate }}
                  </div>

                  <el-checkbox
                    v-model="project.isChecked"
                    @change="handleSingleCheckboxChange(project.id)"
                    :key="project.id"
                  ></el-checkbox>
                </div>
              </div>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Account" name="Account">
          <div>
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="rules"
              class="login-form"
              auto-complete="on"
              label-position="left"
            >
              <div class="label">Email</div>
              <el-form-item prop="email">
                <span class="svg-container">
                  <svg-icon icon-class="email" />
                </span>
                <el-input
                  ref="email"
                  :disabled="true"
                  v-model="loginForm.email"
                  placeholder="Email"
                  name="email"
                  type="text"
                  tabindex="0"
                  auto-complete="on"
                />
              </el-form-item>
              <div class="label">Username</div>
              <el-form-item prop="Username">
                <span class="svg-container">
                  <svg-icon icon-class="user" />
                </span>
                <el-input
                  ref="username"
                  v-model="loginForm.username"
                  placeholder="Username"
                  name="username"
                  type="text"
                  tabindex="0"
                  auto-complete="on"
                />
              </el-form-item>

              <el-button
                type="primary"
                style="width: 100%; margin-bottom: 15px; background-color: #3f66ff"
                @click.native.prevent="editUserClick"
              >
                Update Profile
              </el-button>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Change Password" name="Change Password">
          <div>
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="rules"
              class="login-form"
              auto-complete="on"
              label-position="left"
            >
              <div class="label">Email</div>
              <el-form-item prop="email">
                <span class="svg-container">
                  <svg-icon icon-class="email" />
                </span>
                <el-input
                  ref="email"
                  :disabled="true"
                  v-model="loginForm.email"
                  placeholder="Email"
                  name="email"
                  type="text"
                  tabindex="0"
                  auto-complete="on"
                />
              </el-form-item>
              <div class="label">Code</div>
              <div style="display: flex; margin-bottom: 22px">
                <el-form-item
                  prop="nowcode"
                  style="margin-bottom: 0; width: 60%; margin-right: 77px"
                >
                  <span class="svg-container">
                    <svg-icon icon-class="verification" />
                  </span>
                  <el-input
                    ref="nowcode"
                    v-model="loginForm.nowcode"
                    type="text"
                    placeholder="Code"
                    name="nowcode"
                    tabindex="0"
                  />
                </el-form-item>
                <el-button :disabled="isCountingDown" @click="getCode">
                  {{ countdownText }}
                </el-button>
              </div>
              <div class="label">New Password</div>
              <el-form-item prop="password" style="margin-bottom: 20px">
                <span class="svg-container">
                  <svg-icon icon-class="password" />
                </span>
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model="loginForm.password"
                  :type="passwordType"
                  placeholder="Password"
                  name="password"
                  tabindex="0"
                  @keyup.enter.native="editPassword"
                />
                <span class="show-pwd" @click="showPwd">
                  <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
                </span>
              </el-form-item>
              <el-button
                type="primary"
                style="width: 100%; margin-bottom: 15px; background-color: #3f66ff"
                @click.native.prevent="editPassword"
              >
                Change Password
              </el-button>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="ssss">
        <el-button type="warning" size="mini" @click="addProject">New Project</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { editUser, resetPw } from '@/api/user';
import { getProject, deleteProject } from '@/api/projects';
import { validPassword1, validPassword2 } from '@/utils/validate';
// import EventBus from '@/utils/eventBus';

export default {
  name: 'user-dialog',
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  inject: ['canvas', 'fabric'],
  computed: {
    ...mapGetters(['token']),
  },

  data() {
    const validateNowcode = (rule, value, callback) => {
      if (value.length !== 4) {
        callback(new Error('Please enter the correct verification code'));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (!validPassword1(value)) {
        callback(new Error('The password can not be less than 8 digits'));
      } else if (!validPassword2(value)) {
        callback(
          new Error(
            'Must contain at least two special characters and digits in upper and lower case'
          )
        );
      } else {
        callback();
      }
    };
    return {
      eventbus: true,
      vis: this.dialogVisible,
      showDeleteIcon: {},
      id: null,
      isCountingDown: false,
      countdown: 60,
      countdownText: 'Get Code',
      loginForm: {
        email: '',
        username: '',
        password: '',
        nowcode: '',
      },
      rules: {
        username: [{ required: true, trigger: 'blur' }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        nowcode: [{ required: true, trigger: 'blur', validator: validateNowcode }],
      },
      passwordType: 'password',
      currentSort: 'Name',
      anyChecked: false,
      checkedProjectIds: [],
      isIndeterminate: false,
      checkAll: false,
      email: '',
      activeTab: 'Projects',
      projects: [],
      currentDate: '2024.11.05',
    };
  },
  watch: {
    dialogVisible(newVal) {
      this.vis = newVal;
      if (this.vis === true) {
        this.porjectList();
      }
    },
    vis(newVal) {
      this.$emit('update:dialogVisible', newVal);
    },
  },
  created() {
    this.email = localStorage.getItem('email');
    this.id = localStorage.getItem('id');
    this.projects.forEach((_, index) => {
      this.$set(this.showDeleteIcon, index, false);
    });
    this.porjectList();
  },
  mounted() {
    // const card = document.querySelector('.el-card');
    // card.addEventListener('mouseenter', () => {
    //   this.isHovered = true;
    // });
    // card.addEventListener('mouseleave', () => {
    //   this.isHovered = false;
    // });
    this.loginForm.email = this.email;
    this.loginForm.username = localStorage.getItem('userName');
  },
  methods: {
    handleClose() {
      this.$emit('close-visible');
      localStorage.setItem('double', false);
    },
    logoutUser() {
      console.log(this.token, 'token');
      const data = {
        Authorization: this.token,
      };
      this.$store.dispatch('user/logOut', data).then(() => {
        if (this.token === undefined) {
          this.$router.push({ path: '/login' });
        }
      });
    },
    // 获取列表
    porjectList() {
      const data = {
        userId: this.id,
      };
      getProject(data).then((res) => {
        if (res.code === 200) {
          this.projects = res.data;
          console.log(this.projects, 'this.projects');
        }
      });
    },
    addProject() {
      const objects = this.canvas.c.getObjects();
      objects.forEach((obj) => {
        if (obj.id !== 'workspace' && obj.id !== 'machine' && obj.id !== 'coordinate') {
          this.canvas.c.remove(obj);
        }
      });
      this.canvas.c.renderAll();
      localStorage.setItem('canvasID', null);
      this.$emit('close-visible');
      const project = {
        id: 0,
      };
      this.$emit('project-data', project);
    },
    handleCheckAllChange(val) {
      this.projects.forEach((project) => {
        project.isChecked = val;
      });
      this.isIndeterminate = false;
      this.updateAnyChecked();
    },
    handleSingleCheckboxChange(id) {
      const project = this.projects.find((p) => p.id === id);
      console.log(project, 'project');
      const allChecked = this.projects.every((p) => p.isChecked);
      const someChecked = this.projects.some((p) => p.isChecked);
      this.checkAll = allChecked;
      this.isIndeterminate = !allChecked && someChecked;
      this.updateAnyChecked();
      // }
    },
    handleMouseEnter(index) {
      this.$set(this.showDeleteIcon, index, true);
    },
    handleMouseLeave(index) {
      this.$set(this.showDeleteIcon, index, false);
    },
    // 双击项目
    handleDoubleClick(index) {
      localStorage.setItem('double', true);
      const objects = this.canvas.c.getObjects();
      objects.forEach((obj) => {
        if (obj.id !== 'workspace' && obj.id !== 'machine' && obj.id !== 'coordinate') {
          this.canvas.c.remove(obj);
        }
      });
      const project = this.projects[index];
      console.log(project, 'projectprojectproject');
      localStorage.setItem('canvasID', project.id);
      this.$emit('project-data', project);
      this.$emit('close-visible');
      // this.$eventBus.$emit('double-clicked');
    },
    // 单个删除
    handleDelete(e) {
      console.log(e, 'dddddd');
      console.log('Delete project:', e);
      const data = [e.id];
      console.log(data, 'data');

      deleteProject(data).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.porjectList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 批量删除
    deleteSelectedCards() {
      const selectedProjects = this.getSelectedProjects();
      this.checkedProjectIds = selectedProjects.map((item) => item.id);
      this.projects = this.projects.filter((project) => !project.isChecked);

      deleteProject(this.checkedProjectIds).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.porjectList();
        } else {
          this.$message.error(res.message);
        }
      });
      this.handleCheckAllChange(false);
    },
    getSelectedProjects() {
      return this.projects.filter((project) => project.isChecked);
    },
    updateAnyChecked() {
      this.anyChecked = this.projects.some((project) => project.isChecked);
      console.log(this.anyChecked, 'this.anyChecked');
    },
    handleClick(e) {
      this.currentSort = e;
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    editUserClick() {
      this.$refs.loginForm.validateField('email', (valid) => {
        if (valid) {
          console.log('error submit!!');
          return false;
        }
        const data = {
          userName: this.loginForm.username,
          email: this.loginForm.email,
        };
        editUser(data).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message);
            localStorage.setItem('userName', data.userName);
          }
        });
      });
    },
    getCode() {
      this.isCountingDown = true;
      this.countdown = 60;
      this.countdownText = `Wait ${this.countdown} seconds`;
      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
          this.countdownText = `Wait ${this.countdown} seconds`;
        } else {
          clearInterval(timer);
          this.isCountingDown = false;
          this.countdownText = 'Get code again';
        }
      }, 1000);
      const data = {
        email: this.loginForm.email,
      };
      resetPw(data).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    editPassword() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          console.log(111);
          this.loading = true;
          const data = {
            email: this.loginForm.email,
            password: this.loginForm.password,
            code: this.loginForm.nowcode,
          };
          editUser(data)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message);
                this.loginForm.password = '';
                this.loginForm.nowcode = '';
              } else {
                this.$message.error(res.message);
              }
            })
            .catch(() => {});
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ssss {
  position: absolute;
  right: 20px;
  top: 85px;
  color: #e6a23c;
  font-weight: 600;
  font-size: 14px;
}
::v-deep .el-dialog__body {
  padding-top: 10px;
}
::v-deep .is-active {
  background: #488cc5;
  color: white !important;
}
::v-deep .el-dialog {
  height: 72%;
  margin-top: 5vh !important;
}
::v-deep .el-dialog__body {
  height: calc(100% - 60px); /* 假设顶部有60px高的标题栏 */
  display: flex;
  flex-direction: column;
}
::v-deep .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
::v-deep .el-tabs__content {
  flex: 1;
  overflow-y: auto; /* 当内容超出时显示滚动条 */
  height: 100%;
}
::v-deep .projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;
}
::v-deep .el-card {
  flex: 0 0 22.5%;
  box-sizing: border-box;
  margin: 10px;
  height: 220px;
}
::v-deep .bottom {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .el-card {
    flex: 0 0 50%;
  }
}
::v-deep .el-input {
  display: inline-block;
  height: 47px;
  width: 85%;
}
::v-deep input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  /* 改变背景为白色 */
  background-color: transparent !important;
  color: #000000 !important;
  /* 如果需要改变文字颜色 */
}
::v-deep input {
  background-color: white !important;
  /* 输入框背景颜色 */
  color: black;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  padding: 12px 5px 12px 15px;
  height: 47px;
}
::v-deep .el-form-item {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  /* 改变背景为白色 */
  background-color: transparent !important;
  border: 1px solid #808a87 !important;
  border-radius: 5px;
}
::v-deep .el-input__inner {
  border: 0;
}

::v-deep .login-form {
  position: relative;
  width: 520px;
  max-width: 100%;
  padding: 160px 35px 0;
  margin: 0 auto;
  overflow: hidden;
}

::v-deep .svg-container {
  padding: 6px 5px 6px 15px;
  color: #889aa4;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
}

::v-deep .show-pwd {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 16px;
  color: #889aa4;
  cursor: pointer;
  user-select: none;
}
::v-deep .card-with-delete-icon {
  position: relative;
}

/* .card-content {
  position: relative;
  padding: 5px; 
} */

::v-deep .delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #f56c6c;
  font-size: 1.25rem;
  transition: opacity 0.3s;
}

::v-deep .delete-icon:hover {
  opacity: 0.5;
}
.child-element {
  line-height: initial; /* 或者使用 unset */
}
// ::v-deep .el-header {
//   line-height: 60px;
// }
</style>
