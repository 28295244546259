// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  width: 0,
  height: 0,
  dept: 0,
};
const mutations = {
  setWidth(aaa, sss) {
    aaa.width = sss;
  },
  setHeight(aaa, value) {
    aaa.height = value;
  },
  setDept(aaa, value) {
    aaa.dept = value;
  },
};
const actions = {
  setWidth({ commit }, port) {
    commit('setWidth', port);
  },
  setHeight({ commit }, value) {
    commit('setHeight', value);
  },
  setDept({ commit }, value) {
    commit('setDept', value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
