import Vue from 'vue';
import axios from 'axios';
import ViewUI from 'view-design';
import VueClipboard from 'vue-clipboard2';
import VueLazyload from 'vue-lazyload';
import svgIcon from '@/components/svgIcon/index';
import 'view-design/dist/styles/iview.css';
import '@/assets/fonts/font.css';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'; // lang i18n
// import { Message } from 'element-ui';

import enUS from 'view-design/dist/locale/en-US';
import zhCN from 'view-design/dist/locale/zh-CN';
import ptBR from 'view-design/dist/locale/pt-BR';
import EventBus from '@/utils/eventBus';

import i18n from './locales/i18n';
import router from './router';

import 'element-ui/lib/theme-chalk/index.css';

import '@/styles/index.scss'; // global css

import store from './store';
import App from './App.vue';
import '@/icons'; // icon
import '@/permission'; // permission control

const LANGMAP = {
  zh: zhCN,
  en: enUS,
  pt: ptBR,
};

Vue.use(ViewUI, { locale: LANGMAP[i18n.locale] || zhCN });
Vue.use(VueClipboard);
Vue.use(VueLazyload);
Vue.use(svgIcon);
Vue.use(ElementUI, { locale });

// Vue.prototype.$message = Message;
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.prototype.$eventBus = EventBus;
console.log('VUE_APP_BASE_API:', process.env.VUE_APP_BASE_API);
new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
