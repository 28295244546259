/* eslint import/prefer-default-export: "off" */
import request from '@/utils/request';

export function exportPro(data) {
  return request({
    url: '/api/project/export',
    method: 'post',
    data,
  });
}
// 上传文件
export function uploadPro(data) {
  return request({
    url: '/file/upload',
    method: 'post',
    data,
  });
}
