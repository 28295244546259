// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  showLaser: false,
};
const mutations = {
  toggleShowLaser(aaa, sss) {
    aaa.showLaser = sss;
  },
  setShowLaser(aaa, value) {
    aaa.showLaser = value;
  },
};
const actions = {
  toggleShowLaser({ commit }, sss) {
    commit('toggleShowLaser', sss);
  },
  setShowLaser({ commit }, value) {
    commit('setShowLaser', value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
