import getPageTitle from '@/utils/get-page-title';
import { getToken } from '@/utils/auth'; // get token from cookie
import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import router from './router';
import store from './store';
import 'nprogress/nprogress.css'; // progress bar style

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ['/login']; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();
  // console.log(s);

  // const hasToken =
  //   'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJobDE3NzM3MTdAMTYzLmNvbSIsInVzZXJJZCI6MywiZW1haWwiOiJobDE3NzM3MTdAMTYzLmNvbSIsImlhdCI6MTczMTgyMTM3NywiZXhwIjoxNzMxOTA3Nzc3fQ.79ObXKSyejZzBkEuNW7wxaqt_E_pqYrJBo22XOCz5QU';
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' });
      NProgress.done();
    } else {
      const hasGetUserInfo = store.getters.userName;
      console.log(hasGetUserInfo, '|hasGetUserInfo');

      if (hasGetUserInfo) {
        next();
      } else {
        try {
          // get user info
          // await store.dispatch('user/getInfo')

          next();
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken');
          Message.error(error || 'Has Error');
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else if (
    whiteList.indexOf(to.path) !== -1 ||
    to.path === '/signup' ||
    to.path === '/reset' ||
    to.path === '/google'
  ) {
    // in the free login whitelist, go directly
    next();
  } else {
    // other pages that do not have permission to access are redirected to the login page.
    next(`/login`);
    NProgress.done();
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
