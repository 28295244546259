import request from '@/utils/request';

// 新增修改机器
export function updateMachine(data) {
  return request({
    url: '/api/machine/updateOrInsert',
    method: 'post',
    data,
  });
}
// 获取机器
export function machineList(id) {
  return request({
    url: '/api/machine/list',
    method: 'get',
    params: id,
  });
}
// 删除设备
export function deleteMachine(data) {
  return request({
    url: '/api/machine/delete',
    method: 'post',
    data,
  });
}
// 设置默认
export function setDefaultMachine(id) {
  return request({
    url: '/api/machine/setDefault',
    method: 'get',
    params: id,
  });
}
