const cnList = [
  {
    name: '汉体',
    fontFamily: '汉体',
  },
  {
    name: '华康金刚黑',
    fontFamily: '华康金刚黑',
  },
  {
    name: 'arial',
    fontFamily: 'arial',
  },
  {
    name: 'cour',
    fontFamily: 'cour',
  },
];

const enList = [];

export default [...cnList, ...enList];
