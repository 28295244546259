/* eslint-disable import/no-cycle */
import request from '@/utils/request';
/* eslint-disable import/no-cycle */

// 登录
export function login(data) {
  return request({
    url: '/api/auth/login',
    method: 'post',
    data,
  });
}
// 注册
export function register(data) {
  return request({
    url: '/api/auth/register',
    method: 'post',
    data,
  });
}
// 重置发送验证码
export function resetPw(email) {
  return request({
    url: '/api/user/sendCode',
    method: 'get',
    params: email,
  });
}
// 修改用户信息
export function editUser(data) {
  return request({
    url: '/api/user/edit',
    method: 'post',
    data,
  });
}
// 登出
export function logOut(Authorization) {
  return request({
    url: '/api/auth/logout',
    method: 'get',
    headers: Authorization,
  });
}
// 谷歌登录跳转
export function googleLogin(data) {
  return request({
    url: '/api/auth/google/login',
    method: 'post',
    data,
  });
}
// export function getInfo(token) {
//   return request({
//     url: '/vue-admin-template/user/info',
//     method: 'get',
//     params: { token }
//   })
// }
