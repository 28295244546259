import request from '@/utils/request';

// 获取项目列表
export function getProject(id) {
  return request({
    url: '/api/project/list',
    method: 'get',
    params: id,
  });
}
// 删除项目
export function deleteProject(data) {
  return request({
    url: '/api/project/delete',
    method: 'post',
    data,
  });
}
// 保存更新项目
export function updateProject(data) {
  return request({
    url: '/api/project/insertOrUpdate',
    method: 'post',
    data,
  });
}
