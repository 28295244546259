<template>
  <div>
    <!-- <el-form ref="form" :model="size" label-width="80px" style="display: flex" class="flex-form">
      <el-form-item label="木板长度">
        <el-input type="number" v-model="size.workWidth"></el-input>
      </el-form-item>
      <el-form-item label="木板高度">
        <el-input type="number" v-model="size.workHeight"></el-input>
      </el-form-item>
      <el-form-item label="木板厚度">
        <el-input type="number" v-model="size.dept"></el-input>
      </el-form-item>
      <el-form-item label="钻头直径">
        <el-input type="number" v-model="size.diameter"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="setSize">立即创建</el-button>
      </el-form-item>
    </el-form> -->
  </div>
</template>

<script>
import select from '@/mixins/select';
import EditorWorkspace from '@/core/EditorWorkspace';
import EditorMaterals from '@/core/EditorMaterals';
import { millimeterToPx } from '@/utils/pixelConversion';
// import EventBus from '@/utils/eventBus';

export default {
  name: 'canvasSize',
  mixins: [select],
  inject: ['canvas', 'fabric'],
  props: ['message'],
  data() {
    return {
      size: {
        machineWidth: 300,
        machineHeight: 180,
        workWidth: 150,
        workHeight: 90,
        dept: 20,
        diameter: 20,
      },
      // presetSize: [
      //   {
      //     label: this.$t('red_book_vertical'),
      //     width: 500,
      //     height: 500,
      //     dept: 20,
      //   },
      //   {
      //     label: this.$t('red_book_horizontal'),
      //     width: 1200,
      //     height: 900,
      //     dept: 20,
      //   },
      //   {
      //     label: this.$t('phone_wallpaper'),
      //     width: 1080,
      //     height: 1920,
      //     dept: 20,
      //   },
      // ],
    };
  },
  mounted() {
    this.updateDept();
    this.size.machineWidth = localStorage.getItem('maschirWidth');
    this.size.machineHeight = localStorage.getItem('maschirHeight');
    // localStorage.setItem('dept', this.size.dept);
    this.canvas.editor.editorWorkspace = new EditorWorkspace(this.canvas.c, {
      machineWidth: millimeterToPx(this.size.machineWidth),
      machineHeight: millimeterToPx(this.size.machineHeight),
      workWidth: millimeterToPx(this.size.workWidth),
      workHeight: millimeterToPx(this.size.workHeight),
    });
    const a = new EditorMaterals(this.canvas.c, {
      machineWidth: this.machineWidth * 0.03,
      machineHeight: this.machineHeight * 0.03,
      workWidth: this.workWidth * 0.03,
      workHeight: this.workHeight * 0.03,
      dept: this.dept * 0.03,
    });
    console.log(a, 'aa');
  },
  methods: {
    updateDept() {
      this.$store.dispatch('material/setWidth', this.size.workWidth);
      this.$store.dispatch('material/setHeight', this.size.workHeight);
      this.$store.dispatch('material/setDept', this.size.dept);
      console.log(this.size.dept, this.size.diameter, 'this.size.deptthis.size.dept');
      this.$emit('update:dept', Number(this.size.dept));
      this.$emit('update:diameter', Number(this.size.diameter));
    },
    setSizeBy(machineWidth, machineHeight, workWidth, workHeight) {
      this.size.machineWidth = machineWidth;
      this.size.machineWidth = machineHeight;
      this.size.workWidth = workWidth;
      this.size.workHeight = workHeight;
      this.setSize();
    },
    setSize() {
      this.updateDept();
      console.log(1111111111);
      // localStorage.setItem('dept', this.size.dept);
      this.canvas.editor.editorWorkspace.setSize(
        millimeterToPx(Number(this.size.machineWidth)),
        millimeterToPx(Number(this.size.machineHeight)),
        millimeterToPx(Number(this.size.workWidth)),
        millimeterToPx(Number(this.size.workHeight))
      );
      // EditorMaterals(this.width, this.height, this.dept);
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ivu-form-item {
  margin-bottom: 0;
}
/deep/ .ivu-divider-plain.ivu-divider-with-text-left {
  margin: 10px 0;
  font-weight: bold;
}
::v-deep .el-input {
  width: 100px;
}
.flex-form {
  display: flex;
  height: 100%;
  flex-direction: row; /* 水平排列 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中（如果需要的话） */
  // flex-direction: column; /* 设置为列布局 */
  // align-items: stretch; /* 让子元素拉伸以填满父元素 */
}

.flex-form .el-form-item {
  // display: flex;
  justify-content: center;
  align-items: center; /* 垂直居中 */
  margin-bottom: 10px; /* 可选：设置间距 */
}
// .flex-form .el-form-item__content {
//   flex-grow: 1; /* 让内容区域填充剩余空间 */
// }
</style>
