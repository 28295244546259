<template>
  <div class="container">
    <el-dialog
      title="Please make your choice"
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      width="25%"
    >
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules">
        <el-form-item label="Which type of device have you used before:" prop="equipment">
          <el-checkbox-group v-model="ruleForm.equipment">
            <el-checkbox label="Laser" name="type"></el-checkbox>
            <el-checkbox label="CNC" name="type"></el-checkbox>
            <el-checkbox label="3D Printer" name="type"></el-checkbox>
            <el-checkbox label="None" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          label="What is your experience with the device you've used?"
          prop="experience"
        >
          <el-radio-group v-model="ruleForm.experience">
            <el-radio label="None"></el-radio>
            <el-radio label="Beginner"></el-radio>
            <el-radio label="Average"></el-radio>
            <el-radio label="Master"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="What is your most frequently used material?" prop="material">
          <el-checkbox-group v-model="ruleForm.material">
            <el-checkbox label="Wood/Cardboard" name="type"></el-checkbox>
            <el-checkbox label="Plastic/PVC" name="type"></el-checkbox>
            <el-checkbox label="Metal/Stone" name="type"></el-checkbox>
            <el-checkbox label="Leather/Fabric" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clickDialog('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'question-dialog',
  data() {
    return {
      dialogVisible: true,
      ruleForm: {
        equipment: [],
        material: [],
        experience: '',
      },
      rules: {
        equipment: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one',
            trigger: 'change',
          },
        ],
        experience: [{ required: true, message: 'Please select one', trigger: 'change' }],
        material: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one',
            trigger: 'change',
          },
        ],
      },
    };
  },
  methods: {
    //   changeFirstLogin() {
    //   // 触发事件，通知父组件更新状态
    //   this.$emit('update-first-login', false);
    // },
    clickDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm, 'ruleForm');
          this.$emit('update-first-login', false);
          this.dialogVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style scoped></style>
