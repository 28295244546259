<template>
  <div
    v-if="baseType.includes(mSelectOneType)"
    style="
      width: 250px;
      height: 40%;
      /* padding: 10px; */
      overflow-y: auto;
      position: absolute;
      z-index: 777;
      top: 0;
      right: 0;
    "
  >
    <el-tabs type="border-card" @tab-click="tabClick">
      <el-tab-pane label="Shape" class="shapeTab">
        <div>
          <Divider plain orientation="left">{{ $t('attributes.exterior') }}</Divider>
          <div>
            <div class="flex-item">
              <span class="label">{{ $t('attributes.left') }}</span>
              <div class="content">
                <el-input
                  :controls="false"
                  size="medium"
                  v-model="baseAttr.left"
                  :disabled="isLock"
                  @change="(value) => changeCommon('left', value)"
                  show-input
                />
              </div>
            </div>
            <div class="flex-item">
              <span class="label">{{ $t('attributes.top') }}</span>
              <div class="content">
                <el-input
                  :controls="false"
                  size="small"
                  v-model="baseAttr.top"
                  :disabled="isLock"
                  @change="(value) => changeCommon('top', value)"
                  show-input
                />
              </div>
            </div>
          </div>
          <Divider plain orientation="left">{{ $t('attributes.angle') }}</Divider>
          <div class="flex-view">
            <div class="flex-item">
              <div class="content slider-box">
                <Slider
                  v-model="baseAttr.angle"
                  :disabled="isLock"
                  :max="360"
                  @on-input="(value) => changeCommon('angle', value)"
                ></Slider>
              </div>
            </div>
          </div>
          <Divider plain orientation="left">{{ $t('size') }}</Divider>
          <!-- <div class="flex-view"> -->
          <div class="flex-item">
            <span class="size-label">{{ $t('attributes.width') }}</span>
            <div class="size-content">
              <el-input
                :controls="false"
                size="small"
                v-model="baseAttr.width"
                :disabled="isLock"
                @change="(value) => changeCommon('width', value)"
                show-input
              />
            </div>
          </div>
          <div class="flex-item">
            <span class="size-label">{{ $t('attributes.height') }}</span>
            <div class="size-content">
              <el-input
                :controls="false"
                size="small"
                v-model="baseAttr.height"
                :disabled="isLock"
                @change="(value) => changeCommon('height', value)"
                show-input
              />
            </div>
          </div>
          <!-- </div> -->
          <div v-if="mSelectOneType === 'rect'">
            <Divider plain orientation="left">{{ $t('attributes.radius') }}</Divider>
            <!-- <div class="flex-view"> -->
            <div class="flex-item">
              <span class="size-label">{{ $t('attributes.rx') }}</span>
              <div class="size-content">
                <el-input
                  :controls="false"
                  size="small"
                  v-model="baseAttr.rx"
                  :disabled="isLock"
                  @change="(value) => changeCommon('rx', value)"
                  show-input
                />
              </div>
            </div>
            <div class="flex-item">
              <span class="size-label">{{ $t('attributes.ry') }}</span>
              <div class="size-content">
                <el-input
                  :controls="false"
                  size="small"
                  v-model="baseAttr.ry"
                  :disabled="isLock"
                  @change="(value) => changeCommon('ry', value)"
                  show-input
                />
              </div>
            </div>
            <!-- </div> -->
          </div>

          <div v-if="mSelectOneType === 'text'">
            <Divider plain orientation="left">{{ $t('attributes.font') }}</Divider>
            <div style="position: relative; text-align: center; background: #f6f7f9">
              <el-dropdown trigger="click" @command="handleFontSelect">
                <el-button>{{ fontAttr.fontFamily }}</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in fontFamilyList"
                    :key="index"
                    :command="item"
                  >
                    {{ item }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- <Divider plain orientation="left">{{ $t('attributes.effect') }}</Divider>
            <div style="position: relative; text-align: center; background: #f6f7f9">
              <el-dropdown trigger="click" @command="handleFontSelect">
                <el-button>{{ fontAttr.fontFamily }}</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in fontFamilyList"
                    :key="index"
                    :command="item"
                  >
                    {{ item }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div> -->
            <!-- </div> -->
            <!-- </div> -->
          </div>

          <Button v-if="isLock" @click="doLock(false)" icon="md-lock" type="text"></Button>
          <Button v-else @click="doLock(true)" icon="md-unlock" type="text"></Button>
        </div>
        <!-- <set-size></set-size> -->
        <!-- <lock></lock> -->
      </el-tab-pane>
      <el-tab-pane label="Cut" v-if="showLaser">
        <div style="display: flex">
          <div class="left-cut">
            <Divider plain orientation="left">{{ $t('attributes.dept') }}</Divider>
            <el-input v-model.number="sliderValue" @change="inputChange"></el-input>
            <div v-if="sliderValue === maxDept ? true : false">
              <Divider plain orientation="left">{{ $t('attributes.adddepth') }}</Divider>
              <el-select v-model="addDepthValue" @change="addDeptChange">
                <el-option
                  v-for="item in addDepthOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- <div style="margin-top: 10px">
              <img src="@/assets/fillside/fill.png" alt="" style="width: 40px" />
              <div>{{ $t('attributes.shift') }}</div>
              <el-input type="text" v-model="shiftValue"></el-input>
            </div> -->
          </div>
          <div class="right-cut">
            <el-slider
              v-model="sliderValue"
              :min="0"
              :max="maxDept"
              height="300px"
              vertical
              :marks="marks"
              @change="sliderChange"
              @input="sliderInput"
            ></el-slider>
          </div>
        </div>
        <div>
          <Divider plain orientation="left">{{ $t('attributes.cutpath') }}</Divider>
          <div>
            <div class="flex-view">
              <div class="flex-item" style="text-align: center; justify-content: center">
                <div class="size-content">
                  <div style="position: relative; text-align: center; background: #f6f7f9">
                    <el-dropdown trigger="click" @command="handleFontSelect">
                      <img src="@/assets/fillside/onpath.png" alt="" style="width: 60px" />
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="changeCommon('onpath', '#57606B')">
                          <img src="@/assets/fillside/onpath.png" alt="" style="width: 40px" />
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="changeCommon('inside', '#57606B')">
                          <img src="@/assets/fillside/inside.png" alt="" style="width: 40px" />
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="changeCommon('outside', '#57606B')">
                          <img src="@/assets/fillside/outside.png" alt="" style="width: 40px" />
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </div>
              <div class="flex-item" style="text-align: center; justify-content: center">
                <div class="size-content">
                  <img
                    src="@/assets/fillside/fill.png"
                    alt="Fill Button"
                    style="width: 60px; cursor: pointer"
                    @click="changeCommon('fill', '#57606B')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-html="assasa"></div>
      </el-tab-pane>
      <el-tab-pane label="Laser" v-if="!showLaser">
        <el-table :data="tableData" border style="width: 100%" @row-click="rowClick">
          <el-table-column prop="layer" label="Layer" width="60" align="center"></el-table-column>
          <el-table-column prop="mode" label="Mode" width="100" align="center">
            <template slot-scope="scope">
              <el-select v-model="scope.row.mode" placeholder="请选择" @change="selectChange">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="speed"
            label="Speed/Power"
            align="center"
            width="130"
          ></el-table-column>
        </el-table>
        <div>
          <el-form ref="form" :model="attribute" label-width="80px">
            <el-form-item label="Speed">
              <InputNumber
                v-model="attribute.speed"
                @on-change="(value) => speedChange(value)"
                :min="60"
                :max="6000"
                :step="60"
                show-input
              ></InputNumber>
            </el-form-item>
            <el-form-item label="Power">
              <InputNumber
                v-model="attribute.power"
                @on-change="(value) => powerChange(value)"
                :min="0"
                :max="100"
                :step="10"
                show-input
              ></InputNumber>
            </el-form-item>
            <el-form-item label="Pass">
              <InputNumber
                v-model="attribute.pass"
                @on-change="(value) => passChange(value)"
                :min="1"
                :max="10"
                :step="1"
                show-input
              ></InputNumber>
            </el-form-item>
            <el-form-item label="Interval">
              <InputNumber
                v-model="attribute.interval"
                @on-change="(value) => intervalChange(value)"
                :min="0.01"
                :max="10"
                :step="0.1"
                show-input
              ></InputNumber>
            </el-form-item>
          </el-form>
          <!-- <ul>
          <li>
            speed:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
          <li>
            Power:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
          <li>
            Pass:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
          <li>
            Interval:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
        </ul> -->
          <Divider plain orientation="left">{{ $t('attributes.cutpath') }}</Divider>
          <div class="flex-view">
            <div class="flex-item" style="text-align: center; justify-content: center">
              <div class="size-content">
                <img
                  src="@/assets/fillside/onpath.png"
                  alt="Fill Button"
                  style="width: 60px; cursor: pointer"
                  @click="changeCommon('onpath', '#57606B')"
                />
              </div>
            </div>
            <div class="flex-item" style="text-align: center; justify-content: center">
              <div class="size-content">
                <img
                  src="@/assets/fillside/fill.png"
                  alt="Fill Button"
                  style="width: 60px; cursor: pointer"
                  @click="changeCommon('fill', '#57606B')"
                />
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import fontList from '@/assets/fonts/font';
import select from '@/mixins/select';
import FontFaceObserver from 'fontfaceobserver';
import { perMillimeter, millimeterToPx } from '@/utils/pixelConversion';
import initAligningGuidelines from '@/core/initAligningGuidelines';
import * as opentype from 'opentype.js';
// import EditorWorkspace from '@/core/EditorWorkspace';

// import attribute from '@/components/attribute.vue';

export default {
  name: 'on-shape',
  mixins: [select],
  //   components: {
  //     attribute,
  //   },
  props: ['updateDept', 'updateDiameter'],
  watch: {
    updateDept(newVal) {
      console.log(newVal, 'newValnewVal111');
      this.maxDept = newVal;
      this.marks = {};
      const step = this.maxDept / 2;
      for (let i = 0; i <= 2; i++) {
        const key = Math.round(i * step);
        this.marks[Number(key)] = String(key);
      }
    },

    updateDiameter(newVal) {
      console.log(newVal, 'newValnewVal');
      this.bitDiametert = newVal;
    },
    // updateDept: {
    //   handler(newVal) {
    //     console.log(newVal, 'newValnewVal111');
    //     this.maxDept = newVal;
    //     this.marks = {};
    //     const step = this.maxDept / 2;
    //     for (let i = 0; i <= 2; i++) {
    //       const key = Math.round(i * step);
    //       this.marks[Number(key)] = String(key);
    //     }
    //   },
    //   immediate: true, // 监听器会在组件创建时立即执行一次
    // },
    // updateDiametert: {
    //   handler(newVal) {
    //     console.log(newVal, 'newValnewVal');
    //     this.bitDiametert = newVal;
    //   },
    //   immediate: true, // 监听器会在组件创建时立即执行一次
    // },
  },

  computed: {
    showLaser() {
      console.log(
        this.$store.state.showTab.showLaser,
        'this.$store.state.showLaserthis.$store.state.showLaserthis.$store.state.showLaser'
      );

      return this.$store.state.showTab.showLaser;
    },
  },
  data() {
    return {
      bitDiametert: 0,
      maxDept: 0,
      sliderValue: 0,
      shiftValue: 0,
      marks: {},
      addDepthValue: 0,
      addDepthOptions: [
        {
          value: 0,
          label: 'none',
        },
        {
          value: 0.2,
          label: '0.2mm',
        },
        {
          value: 0.5,
          label: '0.5mm',
        },
        {
          value: 1,
          label: '1mm',
        },
        {
          value: 3,
          label: '3mm',
        },
      ],
      isLock: false,
      baseType: [
        'polygon',
        'text',
        'i-text',
        'textbox',
        'rect',
        'circle',
        'triangle',
        'image',
        'group',
        'line',
        'arrow',
        'path',
      ],
      // 通用属性
      baseAttr: {
        opacity: 0,
        angle: 0,
        fill: '#fff',
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        rx: 0,
        ry: 0,
        strokeWidth: 0,
        strokeDashArray: [],
        stroke: '#fff',
        shadow: {
          color: '#fff',
          blur: 0,
          offsetX: 0,
          offsetY: 0,
        },
      },
      assasa: '',
      // 字体属性
      fontAttr: {
        fontSize: 0,
        fontFamily: '',
        lineHeight: 0,
        charSpacing: 0,
        fontWeight: '',
        textBackgroundColor: '#fff',
        textAlign: '',
        fontStyle: '',
        underline: false,
        linethrough: false,
        overline: false,
      },
      // 字体下拉列表
      fontFamilyList: fontList.map((item) => item.fontFamily),
      options: [
        {
          value: 'Line',
          label: 'Stroke',
        },
        {
          value: 'fill',
          label: 'Fill',
        },
      ],
      tableData: [],
      attribute: {
        speed: 6000,
        power: 20,
        pass: 1,
        interval: 0.1,
      },
      clickRow: {},
      form: {},
      clickActive: null,
      isDragging: false,
      color: null,
    };
  },
  created() {
    this.event.on('selectCancel', () => {
      this.baseAttr.fill = '';
      this.$forceUpdate();
    });
    this.event.on('selectOne', () => {
      console.log(this.mSelectOneType, 'this.mSelectOneTypethis.mSelectOneType');
      const objects = this.canvas.c.getObjects();
      console.log(objects, 'objectsobjects');
      const workSpace = objects.find((item) => item.id === 'workspace');
      // const machine = objects.find((item) => (item.id = 'machine'));
      const [clickActive] = this.canvas.c.getActiveObjects();
      this.clickActive = clickActive;
      // this.color = this.clickActive.fill;
      console.log(this.clickActive, 'this.clickActive111111111111111111111111111111111111');
      this.sliderValue = this.clickActive.dept ? this.clickActive.dept : 0;
      const activeObject = this.canvas.c.getActiveObjects()[0];
      if (activeObject.zIndex === '00') {
        this.$store.dispatch('showTab/toggleShowLaser', true);
      } else {
        this.$store.dispatch('showTab/toggleShowLaser', false);
      }
      if (activeObject) {
        // base
        this.baseAttr.opacity = activeObject.get('opacity') * 100;
        this.baseAttr.fill = activeObject.get('fill');
        this.baseAttr.left = `${perMillimeter(
          Number((activeObject.get('left') - workSpace.left).toFixed(2))
        ).toFixed(1)}mm`;
        this.baseAttr.top = `${perMillimeter(
          Number(
            (
              workSpace.height +
              workSpace.top -
              activeObject.get('top') -
              activeObject.get('height')
            ).toFixed(2)
          )
        ).toFixed(1)}mm`;
        this.baseAttr.width = `${perMillimeter(
          Number(activeObject.get('width') * activeObject.get('scaleX')).toFixed(2)
        ).toFixed(1)}mm`;
        this.baseAttr.height = `${perMillimeter(
          Number(activeObject.get('height') * activeObject.get('scaleY')).toFixed(2)
        ).toFixed(1)}mm`;
        this.baseAttr.stroke = activeObject.get('stroke');
        this.baseAttr.strokeWidth = activeObject.get('strokeWidth');
        this.baseAttr.shadow = activeObject.get('shadow') || {};
        this.baseAttr.angle = activeObject.get('angle') || 0;
        if (this.mSelectOneType === 'rect') {
          this.baseAttr.rx = `${perMillimeter(Number(activeObject.get('rx').toFixed(2))).toFixed(
            1
          )}mm`;
          this.baseAttr.ry = `${perMillimeter(Number(activeObject.get('ry').toFixed(2))).toFixed(
            1
          )}mm`;
          this.isLock = activeObject.get('isLock');
        }
        const textTypes = ['i-text', 'text', 'textbox'];
        if (textTypes.includes(activeObject.type)) {
          this.fontAttr.fontSize = activeObject.get('fontSize');
          this.fontAttr.fontFamily = activeObject.get('fontFamily');
          this.fontAttr.lineHeight = activeObject.get('lineHeight');
          this.fontAttr.textAlign = activeObject.get('textAlign');
          this.fontAttr.underline = activeObject.get('underline');
          this.fontAttr.linethrough = activeObject.get('linethrough');
          this.fontAttr.charSpacing = activeObject.get('charSpacing');
          this.fontAttr.overline = activeObject.get('overline');
          this.fontAttr.fontStyle = activeObject.get('fontStyle');
          this.fontAttr.textBackgroundColor = activeObject.get('textBackgroundColor');
          this.fontAttr.fontWeight = activeObject.get('fontWeight');
        }
        this.getColor();
        // 图片滤镜
        // if (activeObject.type === 'image') {
        //   this.imgAttr.blur = activeObject.filters[0] ? activeObject.filters[0].blur : 0;
        // }
      }
    });
    this.event.on('selectMultiple', () => {
      const ssss = this.canvas.c.getActiveObjects();
      console.log(ssss, 'ssssssssss');
    });
  },
  mounted() {
    console.log(this.canvas.c, 'this.canvas.cthis.canvas.cthis.canvas.c');
    this.bitDiametert = this.updateDiameter;
    this.maxDept = this.updateDept;
    const step = this.maxDept / 2;
    for (let i = 0; i <= 2; i++) {
      const key = Math.round(i * step);
      this.marks[Number(key)] = String(key);
    }
    initAligningGuidelines(this.canvas.c);
    this.canvas.c.on('object:moving', (e) => {
      console.log(e, 'eeeeeeeeeeeeeeesmoving');
      const activeObject = e.target;
      const objects = this.canvas.c.getObjects();
      const workSpace = objects.find((item) => item.id === 'workspace');
      this.baseAttr.left = `${perMillimeter(
        Number((activeObject.get('left') - workSpace.left).toFixed(2))
      ).toFixed(1)}mm`;
      this.baseAttr.top = `${perMillimeter(
        Number(
          (
            workSpace.height +
            workSpace.top -
            activeObject.get('top') -
            activeObject.get('height')
          ).toFixed(2)
        )
      ).toFixed(1)}mm`;
    });
    this.canvas.c.on('object:scaling', (e) => {
      console.log(e, 'eeeeeeeeeeeeeeescaling');
      const activeObject = e.target;
      this.baseAttr.width = `${perMillimeter(
        Number(activeObject.get('width') * activeObject.get('scaleX')).toFixed(2)
      ).toFixed(1)}mm`;
      this.baseAttr.height = `${perMillimeter(
        Number(activeObject.get('height') * activeObject.get('scaleY')).toFixed(2)
      ).toFixed(1)}mm`;
      console.log(e, 'eeeeeeeeeeeeeeeeee');
    });
  },
  methods: {
    getColor() {
      // 计算颜色深度
      console.log(this.clickActive, 'clickActiveclickActive111');
      const color =
        this.clickActive.fill === null ? this.clickActive.stroke : this.clickActive.fill;
      // const grayValue = Math.round((this.maxDept - this.sliderValue) * 2.55);
      if (this.clickActive.zIndex === '00') {
        console.log(1);
        const a = 255 / this.maxDept;
        const grayValue = (this.maxDept - this.sliderValue) * a;
        // 计算颜色深度
        // const grayValue = 100 - mappedValue;
        return `rgb(${grayValue}, ${grayValue}, ${grayValue})`;
      }
      if (
        this.clickActive.stroke === null ||
        this.clickActive.stroke === 'blue' ||
        this.clickActive.strokeWidth === 1
      ) {
        console.log(2);
        console.log(this.clickActive.fill, 'this.clickActive.fill');

        return color;
      }
    },
    sliderInput(e) {
      console.log(e, 'eee');
      this.sliderValue = e;
      // if(this.activeObject)
      if (this.clickActive.fill === null) {
        this.clickActive.set('stroke', this.getColor());
      } else {
        this.clickActive.set('fill', this.getColor());
      }
      this.canvas.c.renderAll();
    },
    // 滑块拖动
    sliderChange(e) {
      this.clickActive.set('dept', e);
    },
    // 深度设置
    inputChange(e) {
      console.log(e, 'eeeeeeeee');
      this.clickActive.set('dept', e);
    },
    addDeptChange(e) {
      console.log(e, 'eeee');
      const a = this.clickActive.get('dept');
      this.clickActive.set('dept', e + a);
    },
    // laser table点击
    rowClick(row) {
      console.log(row, 'rowrow');
      this.clickRow = row;
      this.attribute = { ...this.clickRow };
      // this.attribute.speed = this.attribute.speed.split('/')[0];
    },
    // 填充和描边选择
    selectChange(e) {
      console.log(e, 'eeee');
    },
    speedChange(e) {
      this.clickActive.set('speed', e);
      this.clickRow.speed = e;
    },
    powerChange(e) {
      this.clickActive.set('power', e);
      this.clickRow.power = e;
    },
    passChange(e) {
      this.clickActive.set('pass', e);
      this.clickRow.pass = e;
    },
    intervalChange(e) {
      this.clickActive.set('interval', e);
      this.clickRow.interval = e;
    },
    // tab切换
    tabClick(e) {
      if (e.label === 'Laser') {
        console.log(
          this.canvas.c.getObjects(),
          'this.canvas.c.getObjects()this.canvas.c.getObjects()'
        );
        const newArray = this.canvas.c.getObjects();
        const filteredObjects = newArray.filter(
          (obj) =>
            obj.id !== 'workspace' &&
            obj.id !== 'machine' &&
            obj.id !== 'coordinate' &&
            !(
              obj instanceof this.fabric.Rect &&
              obj.fill &&
              obj.fill.source instanceof HTMLCanvasElement
            )
        );
        console.log(filteredObjects, 'newArraynewArraynewArray');
        this.tableData = filteredObjects
          .map((item) => {
            if (item.zIndex !== '00') {
              return {
                layer: item.zIndex,
                mode: item.fill === null ? 'stroke' : 'fill',
                // speed: `${this.attribute.speed} / ${this.attribute.power}`,
                speed: item.speed ? item.speed : 0,
                power: item.power ? item.power : 0,
                pass: item.pass ? item.pass : 0,
                interval: item.interval ? item.interval : 0,
              };
            }
            return null;
          })
          .filter((item) => item !== null);
        console.log(this.clickActive.speed, 'this.clickActive.speedthis.clickActive.speed');
        this.attribute.speed = this.clickActive.speed ? this.clickActive.speed : 6000;
        this.attribute.power = this.clickActive.power ? this.clickActive.power : 20;
        this.attribute.pass = this.clickActive.pass ? this.clickActive.pass : 1;
        this.attribute.interval = this.clickActive.interval ? this.clickActive.interval : 0.1;

        console.log(this.tableData, 'newArraysss');
      }
    },
    // 字体切换
    async handleFontSelect(e) {
      this.fontAttr.fontFamily = e;
      // `@/assets/fonts/cn/${e}.tff`
      const font = new FontFaceObserver(e);
      console.log(font);
      font.load(null, 150000).then(() => {
        const activeObject = this.canvas.c.getActiveObjects()[0];
        activeObject.set('fontFamily', e);
        this.canvas.c.renderAll();
      });
      const activeObject = this.canvas.c.getActiveObjects()[0];
      console.log(activeObject, 'activeObjectactiveObject');

      activeObject && activeObject.set('fontFamily', e);
      this.canvas.c.renderAll();
      this.$Spin.hide();
      const fontMapping = {
        华康金刚黑: require('@/assets/fonts/cn/华康金刚黑.ttf'),
        汉体: require('@/assets/fonts/cn/汉体.ttf'),
        cour: require('@/assets/fonts/cn/cour.ttf'),
        arial: require('@/assets/fonts/cn/arial.ttf'),
        // 添加其他字体...
      };
      const fontPath = fontMapping[e];
      console.log(fontPath, 'fontPathfontPath');
      try {
        // 使用预定义的映射对象来获取字体路径
        const sss = fontMapping[e];
        if (!sss) {
          throw new Error('Font not found');
        }

        console.log('Loading font from:', sss);

        // 使用 fetch 加载字体文件
        const response = await fetch(fontPath);
        if (!response.ok) {
          throw new Error(`Failed to load font: ${response.statusText}`);
        }

        console.log(
          'Response status:',
          response.status,
          'Content-Type:',
          response.headers.get('content-type')
        );

        // 确保获取的是 ArrayBuffer，并等待 Promise 完成
        const buffer = await response.arrayBuffer();

        console.log('Received buffer size:', buffer.byteLength);

        // 将 ArrayBuffer 转换为 Uint8Array
        // const uint8Array = new Uint8Array(buffer);

        // console.log(uint8Array, 'new Uint8Array(buffer)new Uint8Array(buffer)');

        // 使用 opentype.js 解析字体

        // 加载字体
        const ddd = await opentype.load(fontPath);
        console.log(ddd, 'dddddddddd');

        // 将文本转换为路径
        const path = ddd.getPath(
          activeObject.text,
          activeObject.left,
          activeObject.top,
          activeObject.fontSize
        );
        this.assasa = path.toSVG();
        console.log(this.assasa, 'assasaassasa');

        console.log(path, 'pathpath');
        // console.log(
        //   this.pathArrayToSVG(path.commands),
        //   'this.pathArrayToSVG(path.commands)this.pathArrayToSVG(path.commands)'
        // );

        // this.pathArrayToSVG(path.commands);
        // const aa = opentype.parse(buffer);
      } catch (error) {
        console.error('Error loading or parsing font:', error);
      }

      console.log(activeObject, 'activeObjectactiveObject');
      console.log(e, 'e'); // 华康金刚黑 e
    },
    pathArrayToSVG(pathArray) {
      let d = '';

      pathArray.forEach((command) => {
        switch (command.type.toLowerCase()) {
          case 'm': // Move to
            d += `M ${command.x} ${command.y}`;
            break;
          case 'l': // Line to
            d += ` L ${command.x} ${command.y}`;
            break;
          case 'q': // Quadratic Bézier curve
            d += ` Q ${command.x1} ${command.y1}, ${command.x} ${command.y}`;
            break;
          case 'z': // Close path
            d += ' Z';
            break;
          default:
            console.warn(`Unknown command type: ${command.type}`);
        }
      });

      return d;
    },
    // 锁定
    doLock(isLock) {
      isLock ? this.lock() : this.unLock();
    },
    lock() {
      this.isLock = true;
      this.updateObjectProperties(true);
      this.canvas.c.renderAll();
    },
    unLock() {
      this.isLock = false;
      this.updateObjectProperties(false);
      this.canvas.c.renderAll();
    },
    updateObjectProperties(lock) {
      this.clickActive.selectable = true; // 允许选择
      this.clickActive.isLock = lock; // 允许选择
      this.clickActive.evented = !lock; // 锁定时不允许事件（拖动、缩放）
      this.clickActive.lockMovementX = lock; // 锁定水平移动
      this.clickActive.lockMovementY = lock; // 锁定垂直移动
      this.clickActive.lockScalingX = lock; // 锁定水平缩放
      this.clickActive.lockScalingY = lock; // 锁定垂直缩放
      this.clickActive.hasControls = !lock; // 隐藏控制点
    },
    // 通用属性改变
    changeCommon(key, value) {
      const objects = this.canvas.c.getObjects();
      const workSpace = objects.find((item) => item.id === 'workspace');
      const activeObject = this.canvas.c.getActiveObjects()[0];
      const colors1 = this.canvas.c.getActiveObjects()[0].stroke;
      const colors2 = this.canvas.c.getActiveObjects()[0].fill;
      // const widthss = activeObject.width;
      // const heightss = activeObject.height;
      // const cx = activeObject.left + widthss / 2; // 计算中心点X
      // const cy = activeObject.top + heightss / 2; // 计算中心点Y
      console.log(colors1, colors2, 'colors1colors1');
      if (key === 'left') {
        if (value && !value.endsWith('mm')) {
          const cleanedValue = value.replace(/[^\d.-]/g, '');
          if (!Number.isNaN(cleanedValue) && cleanedValue !== '') {
            this.baseAttr.left = `${parseFloat(cleanedValue).toFixed(1)}mm`;
          } else {
            this.baseAttr.left = '';
          }
        }
        console.log(11111);
        const x = value.slice(0, -2);
        this.clickActive.set(
          'left',
          Number((millimeterToPx(Number(x)) + workSpace.left).toFixed(2))
        );
        this.canvas.c.renderAll();
        return;
      }
      if (key === 'top') {
        const x = value.slice(0, -2);
        this.clickActive.set(
          'top',
          Number(
            (
              workSpace.height +
              workSpace.top -
              millimeterToPx(Number(x)) -
              this.clickActive.height
            ).toFixed(2)
          )
        );
        this.canvas.c.renderAll();
        return;
      }
      if (key === 'width') {
        const x = value.slice(0, -2);
        console.log(millimeterToPx(Number(x)), 'millimeterToPx(Number(x))');
        this.clickActive.set(
          'scaleX',
          Number(millimeterToPx(Number(x)).toFixed(2)) / this.clickActive.width
        );
        this.canvas.c.renderAll();
        return;
      }
      if (key === 'height') {
        const x = value.slice(0, -2);
        console.log(millimeterToPx(Number(x)), 'millimeterToPx(Number(x))');
        this.clickActive.set(
          'scaleY',
          Number(millimeterToPx(Number(x)).toFixed(2)) / this.clickActive.height
        );
        this.canvas.c.renderAll();
        return;
      }
      // 透明度特殊转换
      if (key === 'opacity') {
        activeObject && activeObject.set(key, value / 100);
        this.canvas.c.renderAll();
        return;
      }
      // 旋转角度适配
      if (key === 'angle') {
        activeObject.rotate(value);
        this.canvas.c.renderAll();
        return;
      }
      // 圆角
      if (key === 'rx') {
        activeObject.set('rx', Number(millimeterToPx(Number(value.slice(0, -2)))));
        if (activeObject.ry === 0) {
          activeObject.set('ry', Number(millimeterToPx(Number(value.slice(0, -2)))));
        }
        this.canvas.c.renderAll();
        return;
      }
      if (key === 'ry') {
        activeObject.set('ry', Number(millimeterToPx(Number(value.slice(0, -2)))));
        this.canvas.c.renderAll();
        return;
      }
      // 描边
      if (key === 'onpath') {
        console.log(activeObject, 'activeObjectactiveObject');
        console.log(activeObject.fill, 'activeObject.fillactiveObject.fill');
        const color = activeObject.fill;
        console.log('onpath');
        if (activeObject.zIndex === '00') {
          activeObject.set('fill', null);
          activeObject.set('strokeWidth', this.bitDiametert);
          activeObject.set('stroke', this.getColor());
          activeObject.set('strokeUniform', true);
          activeObject.set('cutType', 'Mill - On path');
          this.canvas.c.renderAll();
          return;
        }
        if (this.clickActive.fill !== null) {
          activeObject.set('fill', null);
          activeObject.set('strokeWidth', 1);
          activeObject.set('stroke', color);
          activeObject.set('strokeUniform', true);
          activeObject.set('cutType', 'Laser - On path');
          console.log(activeObject, 'activeObjectactiveObject');
          this.canvas.c.renderAll();
          return;
        }
      }
      if (key === 'inside') {
        activeObject.set('fill', null);
        activeObject.set('strokeWidth', this.bitDiametert);
        activeObject.set('stroke', 'black');
        activeObject.set('width', activeObject.width - this.bitDiametert);
        activeObject.set('height', activeObject.height - this.bitDiametert);
        activeObject.set('strokeUniform', true);
        this.canvas.c.renderAll();

        // const innerStrokeWidth = this.bitDiametert; // 内部描边宽度
        // activeObject.clone(async (clonedObj) => {
        //   const innerStrokeObject = clonedObj;
        //   console.log(innerStrokeObject, 'innerStrokeObjectinnerStrokeObject');
        //   // 调整大小和位置以适应内部描边
        //   innerStrokeObject.set({
        //     left: activeObject.left,
        //     top: activeObject.top,
        //     scaleX: Math.max(
        //       activeObject.scaleX - innerStrokeWidth / (activeObject.width * activeObject.scaleX),
        //       0.001
        //     ),
        //     scaleY: Math.max(
        //       activeObject.scaleY - innerStrokeWidth / (activeObject.height * activeObject.scaleY),
        //       0.001
        //     ),
        //     stroke: 'black', // 内部描边颜色
        //     strokeWidth: innerStrokeWidth,
        //     fill: null, // 确保内部没有填充
        //     selectable: false, // 确保用户不能选择它
        //     evented: false, // 确保事件不触发在这个对象上
        //   });

        //   // 将新的内部描边对象添加到画布并置于顶层
        //   this.canvas.c.add(innerStrokeObject).bringToFront(activeObject);
        //   this.canvas.c.renderAll();

        //   console.log('Cloned object:', innerStrokeObject);
        // });
      }
      if (key === 'outside') {
        activeObject.setShadow({
          color: 'black', // 阴影颜色
          blur: 10, // 阴影模糊度
          offsetX: 5, // 阴影偏移量X
          offsetY: 5, // 阴影偏移量Y
        });
        // const newWidth = widthss + this.bitDiametert * 2; // 外轮廓宽度增加
        // const newHeight = heightss + this.bitDiametert * 2; // 外轮廓高度增加

        // // 更新图形属性，确保中心点不变
        // activeObject.set({
        //   left: cx - newWidth / 2,
        //   top: cy - newHeight / 2,
        //   width: newWidth,
        //   height: newHeight,
        //   stroke: 'black', // 设置轮廓颜色
        //   strokeWidth: this.bitDiametert,
        //   strokeUniform: true,
        //   fill: null, // 清除填充
        // });
        // activeObject.set('fill', null);
        // activeObject.set('strokeWidth', this.bitDiametert);
        // activeObject.set('stroke', 'black');
        // activeObject.set('width', activeObject.width + this.bitDiametert);
        // activeObject.set('height', activeObject.height + this.bitDiametert);
        // activeObject.set('strokeUniform', true);
        this.canvas.c.renderAll();

        // const outerStrokeWidth = this.bitDiametert; // 外部描边宽度
        // const outerStrokeObject = activeObject.clone();
        // // 调整大小和位置以适应外部描边
        // outerStrokeObject.set({
        //   left: activeObject.left - outerStrokeWidth / 2,
        //   top: activeObject.top - outerStrokeWidth / 2,
        //   scaleX:
        //     activeObject.scaleX + outerStrokeWidth / (activeObject.width * activeObject.scaleX),
        //   scaleY:
        //     activeObject.scaleY + outerStrokeWidth / (activeObject.height * activeObject.scaleY),
        //   stroke: 'black', // 外部描边颜色
        //   strokeWidth: outerStrokeWidth,
        //   fill: null, // 确保内部没有填充
        //   selectable: false, // 确保用户不能选择它
        //   evented: false, // 确保事件不触发在这个对象上
        // });

        // // 将新的外部描边对象添加到画布并置于底层
        // this.canvas.c.add(outerStrokeObject).sendToBack(outerStrokeObject);
        // this.canvas.c.renderAll();
        // activeObject.set('strokeUniform', true);
      }
      // 填充
      if (key === 'fill') {
        const color = activeObject.stroke;
        if (activeObject.zIndex === '00') {
          console.log(1);
          if (activeObject.get('fill') === null) {
            activeObject.set('fill', this.getColor()); // 移除填充
            activeObject.set('stroke', null); // 设置描边颜色
            activeObject.set('cutType', 'Mill Pocket');
            this.canvas.c.renderAll();
          }
        } else if (this.clickActive.fill === null) {
          console.log(2);

          activeObject.set('fill', color); // 移除填充
          activeObject.set('stroke', null); // 设置描边颜色
          activeObject.set('cutType', 'Laser - Fill');
          this.canvas.c.renderAll();
        }
      }
      // activeObject && activeObject.set(key, value);
    },
  },
};
</script>

<style lang="less" scoped>
.el-dropdown-menu {
  max-height: 50%;
  overflow-y: scroll;
}
.flex-view {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
  display: inline-flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #f6f7f9;
}
.flex-item {
  display: flex;
  flex: 1;
  .label {
    width: 32px;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    font-size: 14px;
    color: #333333;
  }
  .content {
    width: 60px;
  }
  .size-label {
    width: 40px;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    font-size: 14px;
    color: #333333;
  }
  .size-content {
    width: 40px;
    .ivu-input-number {
      width: 60px;
    }
  }
  .slider-box {
    width: calc(100% - 50px);
    margin-left: 10px;
  }
  .right {
    margin-left: 10px;
    /deep/ .ivu-input-number {
      display: block;
      width: 100%;
    }
  }
  /deep/ .ivu-slider-wrap {
    margin: 13px 0;
  }
  /deep/ .ivu-radio-group-button .ivu-radio-wrapper {
    width: 48px;
    line-height: 40px;
    text-align: center;
    svg {
      vertical-align: baseline;
    }
  }

  /deep/ .ivu-btn-group-large > .ivu-btn {
    font-size: 24px;
  }

  /deep/ .ivu-radio-group-button.ivu-radio-group-large .ivu-radio-wrapper {
    font-size: 24px;
  }
}
.left-cut {
  width: 50%;
}
.right-cut {
  width: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
}
/deep/ .el-slider .is-vertical {
  transform: rotateX(180deg);
  display: inline-block;
}

/deep/ .el-slider__bar {
  transform: rotateX(180deg);
}

/deep/ .el-slider__button {
  transform: rotateX(180deg);
}

::v-deep .ivu-input-number {
  width: 65px;
}
.shapeTab .el-input {
  width: 90px;
}
</style>
