/* eslint import/prefer-default-export: "off" */
import request from '@/utils/projectRequest';

// ai获取图片
export function getAi(data, Authorization) {
  return request({
    url: '/api/design-library/ai-image',
    method: 'get',
    params: data,
    headers: {
      Authorization, // 确保这里的token是你想要传递的JWT
    },
    responseType: 'blob',
  });
}
