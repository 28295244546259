import request from '@/utils/request';

// 刀头查询
export function bitList(id) {
  return request({
    url: '/api/bit/list',
    method: 'get',
    params: id,
  });
}
// 新增修改刀头
export function updatebit(data) {
  return request({
    url: '/api/bit/insertOrUpdate',
    method: 'post',
    data,
  });
}
// 删除刀头
export function deletebit(data) {
  return request({
    url: '/api/bit/delete',
    method: 'post',
    data,
  });
}
// 材料查询
export function materList(id) {
  return request({
    url: '/api/material/list',
    method: 'get',
    params: id,
  });
}
// 材料新增编辑
export function updateMaterial(data) {
  return request({
    url: '/api/material/insertOrUpdate',
    method: 'post',
    data,
  });
}
// 删除材料
export function deleteMaterial(data) {
  return request({
    url: '/api/material/delete',
    method: 'post',
    data,
  });
}
