// 素材库
/* eslint import/prefer-default-export: "off" */
import request from '@/utils/request';

// 分页查询
export function getLibeaetList(data) {
  return request({
    url: '/api/design-library/search',
    method: 'get',
    params: data,
  });
}
// 类型下拉
export function getLibeaetType(data) {
  return request({
    url: '/api/design-library/type',
    method: 'get',
    params: data,
  });
}
// 标签下拉
export function getLibeaetTag(data) {
  return request({
    url: '/api/design-library/tag',
    method: 'get',
    params: data,
  });
}
