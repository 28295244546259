<template>
  <div class="container">
    <!-- XYZ轴控制面板 -->
    <div class="control-panel">
      <span>Step</span>
      <el-select v-model="moveValue" placeholder="Choose" @change="selectChange">
        <el-option
          v-for="item in moveOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <br />
      <el-button @click="xAddMove" :disabled="moveDisable">X+</el-button>
      <el-button @click="xRedMove" :disabled="moveDisable">X-</el-button>
      <br />
      <el-button @click="yAddMove" :disabled="moveDisable">Y+</el-button>
      <el-button @click="yRedMove" :disabled="moveDisable">Y-</el-button>
      <br />
      <el-button @click="zAddMove" :disabled="moveDisable">Z+</el-button>
      <el-button @click="zRedMove" :disabled="moveDisable">Z-</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'debug-dialog',
  data() {
    return {
      moveValue: '',
      moveOptions: [
        { label: '0.1mm', value: '0.1' },
        { label: '0.5mm', value: '0.5' },
        { label: '1.0mm', value: '1.0' },
        { label: '5.0mm', value: '5.0' },
      ],
      moveDisable: true,
    };
  },
  computed: {
    // 监听 Vuex 中的 socket 状态
    getSocket() {
      return this.$store.state.webSocket.showLaser;
    },
  },
  watch: {},
  methods: {
    selectChange() {
      this.moveDisable = false;
    },
    // 机器移动
    xAddMove() {
      const data = {
        action: 'jog',
        direction: 'X',
        size: this.moveValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
    xRedMove() {
      const data = {
        action: 'jog',
        direction: 'X',
        size: -this.moveValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
    yAddMove() {
      const data = {
        action: 'jog',
        direction: 'Y',
        size: this.moveValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
    yRedMove() {
      const data = {
        action: 'jog',
        direction: 'Y',
        size: -this.moveValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
    zAddMove() {
      const data = {
        action: 'jog',
        direction: 'Z',
        size: this.moveValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
    zRedMove() {
      const data = {
        action: 'jog',
        direction: 'Z',
        size: -this.moveValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.toolbar,
.control-panel,
.gcode-preview,
.log-output {
  margin-bottom: 20px;
}
.toolbar button,
.control-panel button {
  margin: 5px;
}
.button-container {
  display: flex;
  flex-direction: column;
  gap: 5px; /* 控制按钮间的间距 */
}
.el-button {
  margin: 0;
}
</style>
