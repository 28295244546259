import axios from 'axios';
// import { MessageBox, Message } from 'element-ui';
/* eslint-disable import/no-cycle */
import store from '@/store';
/* eslint-disable import/no-cycle */
import { getToken } from '@/utils/auth';

// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API || 'http://10.10.10.77:8081', // url = base url + request url
  timeout: 500000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers.Authorization = getToken();
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response;
    console.log(res, 'res');

    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 200) {
    //   Message({
    //     message: res.message || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000,
    //   });

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm(
    //       'You have been logged out, you can cancel to stay on this page, or log in again',
    //       'Confirm logout',
    //       {
    //         confirmButtonText: 'Re-Login',
    //         cancelButtonText: 'Cancel',
    //         type: 'warning',
    //       }
    //     ).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         window.location.reload();
    //       });
    //     });
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'));
    // }
    return res;
  }
  //   (error) => {
  //     Message({
  //       message: error.message,
  //       type: 'error',
  //       duration: 5 * 1000,
  //     });
  //     return Promise.reject(error);
  //   }
);
// // GET 请求
// export function get(url, params = {}) {
//   return instance.get(url, { params });
// }

// // POST 请求
// export function post(url, data = {}, config = {}) {
//   return instance.post(url, data, config);
// }

export default service;
