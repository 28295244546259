<template>
  <!-- <div
    class="easel-container"
    :style="{ width: panel2dWidth + 'px', height: panel2dHeight + 'px' }"
  >
    <div class="workspace" :style="{ width: panel2dWidth + 'px', height: panel2dHeight + 'px' }"> -->
  <div style="display: flex; position: absolute; top: 0; left: 0; z-index: 999">
    <ul class="list-container">
      <li v-for="(item, index) in items" :key="index" @click="toggleDetails(index)">
        <img :src="item.image" alt="" />
        <div :class="[item.isActive ? 'triangle' : 'none']"></div>
      </li>
    </ul>
    <span>
      <ul class="childList" :style="{ visibility: ishow ? 'visible' : 'hidden' }">
        <li v-for="(item, index) in childItems" :key="index" @click="childClick(index)">
          <img :src="item.image" alt="" />
        </li>
      </ul>
      <ul class="secChildList" :style="{ visibility: secShow ? 'visible' : 'hidden' }">
        <li v-for="(item, index) in secChildItems" :key="index" @click="secChildClick(index)">
          <img :src="item.image" alt="" />
          <!-- <div :class="['triangle', { active: item.isActive }]"></div> -->
        </li>
      </ul>
      <div style="height: 200px; visibility: visible"></div>
      <ul class="secChildList" :style="{ visibility: importShow ? 'visible' : 'hidden' }">
        <li v-for="(item, index) in importItems" :key="index" @click="importClick(index)">
          <!-- <div :class="['triangle', { active: item.isActive }]"></div> -->
          <img :src="item.image" alt="" />
        </li>
      </ul>
      <div></div>
      <ul class="secChildList" :style="{ visibility: exportShow ? 'visible' : 'hidden' }">
        <li v-for="(item, index) in exportItems" :key="index" @click="exportClick(index)">
          <img :src="item.image" alt="" />
          <!-- :style="{ backgroundImage: `url(${item.image})` }" -->

          <!-- <div :class="['triangle', { active: item.isActive }]"></div> -->
        </li>
      </ul>
    </span>
    <!-- 素材库 -->
    <designLibrary :library.sync="library" @close-visible="closeVisable"></designLibrary>
    <!-- 高级素材库 -->
    <advancedMasterial
      :advanced.sync="advancedVis"
      @close-visible="closeAdvanced"
    ></advancedMasterial>

    <!-- <el-dialog
      title="提示"
      :visible.sync="library"
      width="30%"
      :before-close="closeVisable"
      :modal="false"
    >
      <span>这是一段信息</span>
    </el-dialog> -->

    <!-- 图片处理弹窗 -->
    <el-dialog
      title="Image processing"
      :visible.sync="showImage"
      width="60%"
      :before-close="closeImage"
      :modal="false"
    >
      <div class="dialog-content">
        <!-- 左边：SVG 展示区域 -->
        <div class="svg-display" id="imagePreview" v-loading="imgLoading">
          <el-image
            v-if="imageSrc"
            :src="imageSrc"
            alt="Selected Image"
            fit="contain"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="svg-display" v-html="svgUrl" v-loading="imgLoading"></div>
        <!-- 右边：控制面板 -->
        <div class="control-panel">
          <el-form label-width="145px">
            <el-form-item label="Noise cancellation">
              <el-input-number
                v-model="controls.spotRemoval"
                controls-position="right"
                :disabled="!controls.spotRemovalEnabled"
                :min="1"
                :max="100"
                :step="10"
                @blur="debounceMethod()"
                @change="debounceMethod()"
              ></el-input-number>
              <el-checkbox v-model="controls.spotRemovalEnabled">enable</el-checkbox>
            </el-form-item>
            <el-form-item label="Smoothing">
              <el-input-number
                v-model="controls.smoothing"
                controls-position="right"
                :disabled="!controls.smoothingEnabled"
                :min="0.1"
                :max="1.33"
                :step="0.1"
                @blur="debounceMethod()"
                @change="debounceMethod()"
              ></el-input-number>
              <el-checkbox v-model="controls.smoothingEnabled">enable</el-checkbox>
            </el-form-item>
            <el-form-item label="Reduced sampling">
              <el-input-number
                v-model="controls.downsampling"
                controls-position="right"
                :disabled="!controls.downsamplingEnabled"
                :min="0"
                :max="10"
                :step="1"
                @blur="debounceMethod()"
                @change="debounceMethod()"
              ></el-input-number>
              <el-checkbox v-model="controls.downsamplingEnabled">enable</el-checkbox>
            </el-form-item>
            <el-button @click="uppppp(svgUrl)">Add canvas</el-button>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- AI生成 -->
    <el-dialog
      title="AI image"
      :visible.sync="showAi"
      width="60%"
      :before-close="closeAi"
      :modal="false"
    >
      <div style="display: flex; text-align: center; align-items: center">
        <el-form :model="aiForm" :rules="aiRules" ref="aiForm" style="width: 30%">
          <el-form-item label="keyword" prop="textContent">
            <el-input
              type="textarea"
              placeholder="Please enter keyword"
              v-model="aiForm.textContent"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          class="block"
          v-for="item in fits"
          :key="item.style"
          :style="{
            margin: '0 10px',
            width: '120px',
            backgroundColor: item.selected ? '#606266' : '',
            color: item.selected ? '#fff' : '',
          }"
          @click="selectStyle(item)"
        >
          <div>{{ item.style }}</div>
          <el-image style="width: 100px; height: 100px" :src="item.url" :fit="item.fit"></el-image>
        </div>
        <div>
          <el-button type="primary" v-loading="aiLoading" @click="getImage('aiForm')">
            Generate
          </el-button>
        </div>
      </div>

      <div class="dialog-content">
        <!-- 左边：SVG 展示区域 -->
        <div class="svg-display" id="imagePreview">
          <el-image
            v-loading="aiLoading"
            :src="aiImageUrl"
            alt="Selected Image"
            fit="contain"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="svg-display" v-html="aiSvg" v-loading="aiLoading"></div>

        <!-- 右边：控制面板 -->
        <div class="control-panel">
          <el-form label-width="145px">
            <el-form-item label="Noise cancellation">
              <el-input-number
                v-model="aiControl.spotRemoval"
                aiControl-position="right"
                :disabled="!aiControl.spotRemovalEnabled"
                :min="1"
                :max="100"
                :step="10"
                @blur="aiChange()"
                @change="aiChange()"
              ></el-input-number>
              <el-checkbox v-model="aiControl.spotRemovalEnabled">enable</el-checkbox>
            </el-form-item>
            <el-form-item label="Smoothing">
              <el-input-number
                v-model="aiControl.smoothing"
                aiControl-position="right"
                :disabled="!aiControl.smoothingEnabled"
                :min="0.1"
                :max="1.33"
                :step="0.1"
                @blur="aiChange()"
                @change="aiChange()"
              ></el-input-number>
              <el-checkbox v-model="aiControl.smoothingEnabled">enable</el-checkbox>
            </el-form-item>
            <el-form-item label="Reduced sampling">
              <el-input-number
                v-model="aiControl.downsampling"
                aiControl-position="right"
                :disabled="!aiControl.downsamplingEnabled"
                :min="0"
                :max="10"
                :step="1"
                @blur="aiChange()"
                @change="aiChange()"
              ></el-input-number>
              <el-checkbox v-model="aiControl.downsamplingEnabled">enable</el-checkbox>
            </el-form-item>
            <el-button @click="uppppp(aiSvg)">Add to...</el-button>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="add Text"
      :visible.sync="showText"
      width="40%"
      :before-close="closeText"
      :modal="false"
    >
      <div>
        <el-form :model="textForm" :rules="textRules" ref="textForm" style="width: 50%">
          <el-form-item label="keyword" prop="textContent">
            <el-input
              type="textarea"
              placeholder="Please enter keyword"
              v-model="textForm.textContent"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="keyword" prop="textType">
            <el-select v-model="textForm.textType" placeholder="Choose">
              <el-option
                v-for="item in fontFamilyList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" v-loading="aiLoading" @click="handleFontSelect">
            Generate
          </el-button>
        </div>
        <!-- <Divider plain orientation="left">{{ $t('attributes.font') }}</Divider> -->
      </div>
    </el-dialog>
  </div>

  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import fontList from '@/assets/fonts/font';
import { v4 as uuid } from 'uuid';
import initializeLineDrawing from '@/core/initializeLineDrawing';
import { getImgStr, selectFiles } from '@/utils/utils';
import { exportPro, uploadPro } from '@/api/export';
import { importPro, importImg, getProject, importGcode } from '@/api/import';
import { getAi } from '@/api/aiImage';
import { updateProject } from '@/api/projects';
import designLibrary from '@/components/designLibrary.vue';
import advancedMasterial from '@/components/advancedMasterial.vue';
import { getPolygonVertices } from '@/utils/math';
import fetchSvgContent from '@/utils/svgService';
import { getToken } from '@/utils/auth';
// import FontFaceObserver from 'fontfaceobserver';
import * as opentype from 'opentype.js';
// import EventBus from '@/utils/eventBus';

// import axios from 'axios';
// import { getToken } from '@/utils/auth';

// 默认属性
const defaultPosition = { shadow: '', fontFamily: 'arial', zIndex: '00' };
// 拖拽属性

export default {
  name: 'ToolBar',
  inject: ['canvas', 'fabric'],
  props: ['saveProject', 'updateDept', 'updateSocket'],
  computed: {
    // 监听 Vuex 中的 socket 状态
    getSocket() {
      return this.$store.state.webSocket.showLaser;
    },
    getWidth() {
      return this.$store.state.material.width;
    },
    getHeight() {
      return this.$store.state.material.height;
    },
    getDept() {
      return this.$store.state.material.dept;
    },
  },
  watch: {
    saveProject(newVal) {
      this.project = newVal;
    },
    // updateDept(newVal) {
    //   this.dept = newVal;
    //   this.engravingDepth = this.dept > 5 ? this.dept / 2 : 3;
    // },
    getSocket() {
      this.socket = this.$store.state.webSocket.showLaser;
    },
    // getWidth() {
    //   this.width = this.$store.state.material.width;
    // },
  },

  data() {
    return {
      fontFamilyList: fontList.map((item) => ({ label: item.fontFamily, value: item.fontFamily })),
      showText: false,
      fits: [
        {
          fit: 'fill',
          style: 'simple',
          url: require('@/assets/AI/simple.png'),
          selected: true,
        },
        {
          fit: 'fill',
          style: 'clean',
          url: require('@/assets/AI/clean.png'),
          selected: false,
        },
        {
          fit: 'fill',
          style: 'reality',
          url: require('@/assets/AI/reality.png'),
          selected: false,
        },
        {
          fit: 'fill',
          style: 'bluepencil',
          url: require('@/assets/AI/bluepencil.png'),
          selected: false,
        },
        {
          fit: 'fill',
          style: 'free',
          url: require('@/assets/AI/free.png'),
          selected: false,
        },
      ],
      aiStyle: 'simple',
      aiForm: {
        textContent: 'a cat',
      },
      aiRules: {
        textContent: [{ required: true, message: 'Please enter keyword', trigger: 'blur' }],
      },
      textForm: {
        textContent: 'CNC',
        textType: '',
      },
      textRules: {
        textContent: [{ required: true, message: 'Please enter Text', trigger: 'blur' }],
        textType: [{ required: true, message: 'Please select a type', trigger: 'blur' }],
      },
      aiImageUrl: null,
      aiSvg: '',
      aipath: '',
      aiLoading: false,
      imgLoading: false,
      width: 0,
      dept: 0,
      engravingDepth: 0,
      timer: null, // 定义定时器变量
      canvasId: null,
      canvasUrl: '',
      project: {},
      uploadInterval: null,
      isDrawingLineMode: false,
      isArrow: false,
      ishow: false,
      secShow: false,
      importShow: false,
      exportShow: false,
      items: [
        {
          image: require('@/assets/images/L1shapes.png'),
          isActive: true,
        },
        {
          image: require('@/assets/images/L2pen.png'),
          isActive: true,
        },
        {
          image: require('@/assets/images/L4text.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L5libray.png'),
          isActive: true,
        },
        {
          image: require('@/assets/images/L6formwork.png'),
          isActive: true,
        },
        {
          image: require('@/assets/images/L6AI.png'),
          isActive: true,
        },
        {
          image: require('@/assets/images/L7import.png'),
          isActive: true,
        },
        {
          image: require('@/assets/images/L8export.png'),
          isActive: true,
        },
        {
          image: require('@/assets/images/L9share.png'),
          isActive: false,
        },
      ],
      childItems: [
        {
          image: require('@/assets/images/L11square.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L12circular.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L13line.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L14triangle.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L15pentagon.png'),
          isActive: false,
        },
      ],
      secChildItems: [
        {
          image: require('@/assets/images/L21pen.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L22pencel.png'),
          isActive: false,
        },
      ],
      importItems: [
        {
          image: require('@/assets/images/L71gcode.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L72svg.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L73image.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L74project.png'),
          isActive: false,
        },
      ],
      exportItems: [
        {
          image: require('@/assets/images/L81gcode.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L82project.png'),
          isActive: false,
        },
      ],
      fabricCanvas: null,
      mode: 'select',
      isGridVisible: true, // 确保网格始终显示
      gridSize: 20, // 网格大小
      snapThreshold: 0.5, // 对齐阈值
      selectedObject: null,
      mask: null,
      addWidth: 0,
      addHeight: 0,
      gridRect: null,
      library: false,
      isDrawing: false,
      toggle: false,
      points: [],
      polygon: null,
      svgFile: null,
      dragOption: {
        left: 0,
        top: 0,
      },
      tempObjects: [],
      // 图片路径
      filePath: '',
      showImage: false,
      showAi: false,
      svgUrl: '', // SVG 文件的 URL 或者内联 SVG 字符串
      imageSrc: null,
      controls: {
        spotRemoval: 10,
        spotRemovalEnabled: true,
        smoothing: 1,
        smoothingEnabled: true,
        downsampling: 2,
        downsamplingEnabled: true,
      },
      aiControl: {
        spotRemoval: 10,
        spotRemovalEnabled: true,
        smoothing: 1,
        smoothingEnabled: true,
        downsampling: 2,
        downsamplingEnabled: true,
      },
      advancedVis: false,
    };
  },
  components: {
    designLibrary,
    advancedMasterial,
  },
  created() {
    // 线条绘制
    // this.canvas.c.on('drop', (opt) => {
    //   // 画布元素距离浏览器左侧和顶部的距离
    //   const offset = {
    //     left: this.canvas.c.getSelectionElement().getBoundingClientRect().left,
    //     top: this.canvas.c.getSelectionElement().getBoundingClientRect().top,
    //   };

    //   // 鼠标坐标转换成画布的坐标（未经过缩放和平移的坐标）
    //   const point = {
    //     x: opt.e.x - offset.left,
    //     y: opt.e.y - offset.top,
    //   };

    //   // 转换后的坐标，restorePointerVpt 不受视窗变换的影响
    //   const pointerVpt = this.canvas.c.restorePointerVpt(point);

    //    this.dragOption.left = pointerVpt.x;
    //    this.dragOption.top = pointerVpt.y;
    // });
    this.canvas.c.on('mouse:down', this.handleMouseDown);
    this.canvas.c.on('mouse:up', this.handleMouseUp);
  },
  mounted() {
    console.log(this.fontFamilyList, 'fontFamilyListfontFamilyListfontFamilyList');
    // this.dept = this.updateDept;
    this.engravingDepth = this.getDept > 5 ? this.getDept / 2 : 3;
    const objects = this.canvas.c.getObjects();
    const workSpace = objects.find((item) => item.id === 'workspace');
    const workspaceTop = workSpace.top + workSpace.height - 100;
    const workspaceleft = workSpace.left;
    this.dragOption.top = workspaceTop;
    this.dragOption.left = workspaceleft;
    // this.$eventBus.$on('double-clicked', () => {
    //   console.log(1);

    //   // console.log(project, 'projectproject');

    //   this.loadSvgContent();
    // });
    this.init();
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器
    if (this.uploadInterval) {
      clearInterval(this.uploadInterval);
      this.uploadInterval = null;
    }
    if (this.timer) {
      clearTimeout(this.timer); // 确保在组件销毁前清除定时器
    }
    // this.$eventBus.$off('double-clicked');
  },
  methods: {
    // 文字添加

    async handleFontSelect() {
      this.$refs.textForm.validate(async (valid) => {
        if (valid) {
          const fontMapping = {
            华康金刚黑: require('@/assets/fonts/cn/华康金刚黑.ttf'),
            汉体: require('@/assets/fonts/cn/汉体.ttf'),
            cour: require('@/assets/fonts/cn/cour.ttf'),
            arial: require('@/assets/fonts/cn/arial.ttf'),
            // 添加其他字体...
          };
          const fontPath = fontMapping[this.textForm.textType];
          console.log(fontPath, 'fontPathfontPath');
          try {
            // 使用预定义的映射对象来获取字体路径
            const sss = fontMapping[this.textForm.textType];
            if (!sss) {
              throw new Error('Font not found');
            }
            // 使用 fetch 加载字体文件
            const response = await fetch(fontPath);
            if (!response.ok) {
              throw new Error(`Failed to load font: ${response.statusText}`);
            }
            // 确保获取的是 ArrayBuffer，并等待 Promise 完成
            // 加载字体
            const ddd = await opentype.load(fontPath);
            console.log(ddd, 'dddddddddd');
            // 将文本转换为路径
            const path = ddd.getPath(this.textForm.textContent, 0, 0, 100);
            const assasa = path.toSVG();
            console.log(assasa, 'assasaassasaassasa');

            const textPath = new this.fabric.Path(assasa, {
              ...this.dragOption,
            });
            const objects = this.canvas.c.getObjects();
            const workSpace = objects.find((item) => item.id === 'workspace');
            textPath.set({
              top: workSpace.top + workSpace.height - textPath.height,
              dept: this.engravingDepth,
              id: uuid(),
              cutType: 'Mill Pocket',
              zIndex: '00',
            });
            console.log(textPath, 'textPathtextPathtextPath');

            this.canvas.c.add(textPath);
            this.canvas.c.renderAll();
          } catch (error) {
            console.error('Error loading or parsing font:', error);
          }
        } else {
          console.log('Error submitting form');
          return false;
        }
      });

      // console.log(activeObject, 'activeObjectactiveObject');
      // console.log(e, 'e'); // 华康金刚黑 e
    },

    // AI选择风格
    selectStyle(item) {
      // 取消所有项目的选中状态
      this.fits.forEach((fit) => {
        this.$set(fit, 'selected', false); // 确保 selected 是响应式的
      });
      this.aiStyle = item.style;
      // 设置当前项为选中状态
      this.$set(item, 'selected', true);
    },
    // 获取ai图片
    getImage(aiForm) {
      this.$refs[aiForm].validate((valid) => {
        if (valid) {
          this.aiLoading = true;
          const data = {
            content: this.aiForm.textContent,
            style: this.aiStyle,
          };
          console.log(data, 'data');
          const token = getToken();
          console.log(token, 'tokentokentoken');

          getAi(data, token).then((res) => {
            if (res.status === 200) {
              this.aiImageUrl = URL.createObjectURL(res.data);
              console.log(this.aiImageUrl, 'this.aiImagethis.aiImage');
              // const file = new File([blob], 'filename.png', {
              //   type: 'image/png',
              //   lastModified: Date.now(),
              // });
              const formData = new FormData();
              formData.append('file', res.data, 'filename.png');
              importImg(formData).then((item) => {
                if (item.data.code === 200) {
                  const svgUrlList = item.data.data.svg;
                  this.aipath = item.data.data.filePath;
                  console.log(item, 'item');
                  console.log(this.aiSvg, 'this.showImagethis.showImagethis.showImage');
                  console.log(this.aipath, 'this.aipath.showImagethis.showImage');
                  // this.filePath = res.data.data.filePath;
                  // this.showImage = true;
                  // const svgUrlList = res.data.data.svg;
                  this.aiSvg = svgUrlList.replace(/\\n/g, '\n').replace(/\\"/g, '"');
                  this.aichangeMethod();
                  this.aiLoading = false;
                }
              });
            }
            console.log(res, 'Res');
          });
        } else {
          console.log('表单校验失败');
          return false;
        }
      });
    },
    async init() {
      const double = localStorage.getItem('double');

      if (double === 'true') {
        await this.loadSvgContent();
      }
      this.getSvg();
      // 设置定时器，每隔30000毫秒（30秒）调用一次getSvg方法
      this.uploadInterval = setInterval(() => {
        this.getSvg();
      }, 30000);
    },
    // 将用户列表双击展示到画布中
    async loadSvgContent() {
      try {
        console.log(this.saveProject.canvasUrl, 'saveProjectsaveProjectsaveProjectsaveProject');
        const svgString = await fetchSvgContent(this.saveProject.canvasUrl);
        console.log(svgString, 'svgStringsvgStringsvgString');
        // 手动解析SVG并提取<desc>内容
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgString, 'application/xml');
        const descElements = doc.querySelectorAll('desc');
        const descArray = Array.from(descElements);
        console.log(descArray, 'descElementsdescElements');
        const desc = descArray.slice(1);
        // const jsonObject = JSON.parse(desc[0].innerHTML);
        // console.log(desc, 'descElementsdescElements');
        // console.log(desc[0].innerHTML, 'desc[0].innerHTMLdesc[0].innerHTML');
        // console.log(jsonObject.feedRate, 'jsonObjectjsonObjectjsonObject');

        // const descData = [];
        // descElements.forEach((desc) => {
        //   try {
        //     const descContent = desc.textContent.trim();

        //     // 检查<desc>内容是否是有效的JSON
        //     if (isValidJson(descContent)) {
        //       const jsonData = JSON.parse(descContent);
        //       descData.push(jsonData);
        //     } else {
        //       console.warn('<desc>标签内容不是有效的JSON, 跳过解析:', descContent);
        //       descData.push(null); // 可以选择推送null或默认值
        //     }
        //   } catch (e) {
        //     console.error('无法解析<desc>标签的内容:', e);
        //     descData.push(null); // 解析失败时推送null或默认值
        //   }
        // });
        this.fabric.loadSVGFromString(svgString, (objects) => {
          console.log(objects, 'objectsobjects');
          const filteredObjects = objects.filter(
            (obj) =>
              obj.id !== 'workspace' &&
              obj.id !== 'machine' &&
              (!obj.fill || obj.fill !== '#F1F1F1')
          );
          filteredObjects.forEach((obj, index) => {
            console.log(index);
            console.log(desc[index].innerHTML, 'desc[index]desc[index]desc[index]');
            const jsonString = desc[index].innerHTML;
            const jsonObject = JSON.parse(jsonString);
            console.log(jsonObject, 'jsonObjectjsonObject');
            if (obj.transformMatrix) {
              obj.set({
                left: obj.left + obj.transformMatrix[4],
                top: obj.top + obj.transformMatrix[5],
                scaleX: obj.transformMatrix[0],
                scaleY: obj.transformMatrix[3],
                angle: 0,
              });
            }
            obj.set({
              ...jsonObject,
              pass: jsonObject.passes,
              speed: jsonObject.feedRate,
              power: jsonObject.spindleSpeed,
              dept: jsonObject.targetDepth,
            });
            console.log(obj, 'objobjobj111');
            this.canvas.c.add(obj);
          });

          this.canvas.c.renderAll();
        });
      } catch (error) {
        console.error('Error fetching or loading SVG:', error);
      }
    },
    uppppp(e) {
      const This = this;
      console.log(e, 'eeeeeeeee');
      This.fabric.loadSVGFromString(e, function (objects) {
        console.log(objects, 'objectsobjectsssssssssssss');
        const templateSize = This.canvas.c.getObjects();
        const masObj = templateSize.filter((element) => element.id === 'workspace');
        const obj = This.fabric.util.groupSVGElements(objects);
        const targetSize = masObj[0].height / 2;
        const originalHeight = obj.height;
        const scaleFactor = targetSize / originalHeight;
        obj.set({
          scaleX: scaleFactor,
          scaleY: scaleFactor,
          left: masObj[0].left + (obj.width * scaleFactor) / 2,
          top: masObj[0].top + masObj[0].height - (obj.height * scaleFactor) / 2,
          originX: 'center',
          originY: 'center',
        });
        obj.set('zIndex', '00');
        obj.set('cutType', 'Mill Pocket');
        This.canvas.c.add(obj);
        This.canvas.c.renderAll();
      });
      this.showImage = false;
      this.showAi = false;
      // const blob = new Blob([this.svgUrl], { type: 'image/svg+xml;charset=utf-8' });
      // const url = URL.createObjectURL(blob);
    },
    toggleDetails(index) {
      this.closeLine();
      this.canvas.isDrawingMode = false;
      if (index === 0) {
        this.items[index];
        this.secShow = false;
        this.importShow = false;
        this.exportShow = false;
        this.ishow = !this.ishow;
      } else if (index === 1) {
        this.ishow = false;
        this.importShow = false;
        this.exportShow = false;
        this.secShow = !this.secShow;
      } else if (index === 6) {
        this.ishow = false;
        this.secShow = false;
        this.exportShow = false;
        this.importShow = !this.importShow;
      } else if (index === 7) {
        this.ishow = false;
        this.secShow = false;
        this.importShow = false;
        this.exportShow = !this.exportShow;
      } else {
        this.ishow = false;
        this.secShow = false;
        this.importShow = false;
        this.exportShow = false;
      }
      if (index === 4) {
        this.advancedVis = true;
        // 橡皮擦功能
      } else if (index === 2) {
        this.showText = true;
        // this.addText(this.dragOption);
      } else if (index === 3) {
        this.library = true;
      } else if (index === 5) {
        this.showAi = true;
      }

      // console.log(index, "index");
    },
    childClick(index) {
      console.log(this.getDept, 'getDeptgetDept');
      console.log(this.engravingDepth, 'this.engravingDepththis.engravingDepth');
      this.closeLine();
      if (index === 0) {
        this.canvas.c.isDrawingMode = false;
        this.addRect(this.dragOption);
      } else if (index === 1) {
        this.canvas.c.isDrawingMode = false;
        this.addCircle(this.dragOption);
      } else if (index === 2) {
        this.canvas.c.isDrawingMode = false;
        this.toggleDrawingLineMode(false);
      } else if (index === 3) {
        this.canvas.c.isDrawingMode = false;
        this.addTriangle(this.dragOption);
      } else if (index === 4) {
        this.canvas.c.isDrawingMode = false;
        this.addPolygon(this.dragOption);
      }
    },
    secChildClick(index) {
      this.closeLine();
      if (index === 0) {
        this.canvas.c.isDrawingMode = false;
        this.toggle = !this.toggle;
        if (this.toggle) {
          this.points = [];
          this.polygon = null;
        } else {
          this.clearCanvas();
        }
      } else if (index === 1) {
        this.isDrawing = !this.isDrawing;
        if (this.isDrawing === true) {
          this.canvas.c.isDrawingMode = true;
        } else if (this.isDrawing === false) {
          this.canvas.c.isDrawingMode = false;
        }
      }
    },
    handleMouseDown(event) {
      if (!this.toggle) return;
      const pointer = this.canvas.c.getPointer(event.e);
      const point = new this.fabric.Point(pointer.x, pointer.y);

      if (this.points.length === 0) {
        this.points.push(point);
        // this.canvas.c.add(
        const circle = new this.fabric.Circle({
          left: point.x,
          top: point.y,
          radius: 5,
          fill: 'rgb(178, 53, 84)',
          originX: 'center',
          originY: 'center',
          evented: false,
          selectable: false,
          // scaleX: 1 / this.canvas.getZoom(),
          // scaleY: 1 / this.canvas.getZoom(),
        });
        // );
        this.canvas.c.add(circle);
        this.tempObjects.push(circle);
      } else {
        const lastPoint = this.points[this.points.length - 1];
        const distance = lastPoint.distanceFrom(point);
        console.log(distance, 'distancedistance');
        if (distance > 10) {
          this.points.push(point);
          // this.canvas.c.add(
          const circle = new this.fabric.Circle({
            left: point.x,
            top: point.y,
            radius: 5,
            fill: 'rgb(178, 53, 84)',
            originX: 'center',
            originY: 'center',
            evented: false,
            selectable: false,
          });
          // );
          this.canvas.c.add(circle);
          this.tempObjects.push(circle);
          const line = this.drawLine(lastPoint, point);
          this.tempObjects.push(line);
        }
      }
    },
    handleMouseUp() {
      if (!this.toggle) return;
      if (this.points.length > 2) {
        const firstPoint = this.points[0];
        const lastPoint = this.points[this.points.length - 1];
        const distance = firstPoint.distanceFrom(lastPoint);
        if (distance < 10) {
          this.finishPolygon();
        }
      }
    },
    drawLine(start, end) {
      const line = new this.fabric.Line([start.x, start.y, end.x, end.y], {
        stroke: 'black',
        selectable: false,
      });
      this.canvas.c.add(line);
      return line;
    },
    finishPolygon() {
      console.log(this.points, 'this.pointsthis.points');
      // 获取所有 x 和 y 值
      const xValues = this.points.map((point) => point.x);
      const yValues = this.points.map((point) => point.y);

      // 计算最小值
      const minX = Math.min(...xValues);
      const minY = Math.min(...yValues);
      this.polygon = new this.fabric.Polygon(this.points, {
        dept: this.engravingDepth,
        id: uuid(),
        cutType: 'Mill Pocket',
        // stroke: 'red',
        // strokeWidth: 10,
        // selectable: false,
        left: minX, // 设置多边形的初始位置
        top: minY,
        zIndex: '00',
      });
      console.log(this.polygon, 'this.polygonthis.polygonthis.polygon');
      this.canvas.c.add(this.polygon);
      this.canvas.c.renderAll();
      console.log('Polygon added to canvas:', this.canvas.c);
      // 移除所有临时对象（点和线）
      this.tempObjects.forEach((obj) => this.canvas.c.remove(obj));
      this.tempObjects = []; // 清空临时对象数组
      // 清理状态
      this.toggle = false;
      this.points = [];
    },
    clearCanvas() {
      console.log(222222);
      this.canvas.c.clear();
      this.points = [];
      this.polygon = null;
    },
    importClick(index) {
      this.closeLine();
      if (index === 0) {
        this.insertGcode();
      } else if (index === 1) {
        this.insertSvg();
      } else if (index === 2) {
        this.showImage = true;
        this.insertImg();
      } else if (index === 3) {
        const objects = this.canvas.c.getObjects();
        objects.forEach((obj) => {
          if (obj.id !== 'workspace' && obj.id !== 'machine') {
            this.canvas.c.remove(obj);
          }
        });
        this.insertProject();
      }
      console.log(index);
    },
    exportClick(index) {
      this.closeLine();
      if (index === 1) {
        this.exportProject();
      }
      console.log(index);
    },
    // 拖拽开始时就记录当前打算创建的元素类型
    // onDragend(type) {
    //   // todo 拖拽优化 this.canvas.editor.dragAddItem(event, item);
    //   switch (type) {
    //     case 'text':
    //       this.addText( this.dragOption);
    //       break;
    //     case 'textBox':
    //       this.addTextBox( this.dragOption);
    //       break;
    //     case 'rect':
    //       this.addRect( this.dragOption);
    //       break;
    //     case 'circle':
    //       this.addCircle( this.dragOption);
    //       break;
    //     case 'triangle':
    //       this.addTriangle( this.dragOption);
    //       break;
    //     default:
    //   }
    // },
    addText(option) {
      const text = new this.fabric.IText(this.$t('everything_is_fine'), {
        ...defaultPosition,
        ...option,
        fontSize: 80,
        isLock: false,
        type: 'text',
        dept: this.engravingDepth,
        id: uuid(),
        cutType: 'Mill Pocket',
      });
      this.canvas.c.add(text);
      if (!option) {
        text.center();
      }
      this.canvas.c.setActiveObject(text);
    },
    // 文本
    // addTextBox(option) {
    //   const text = new this.fabric.Textbox(this.$t('everything_goes_well'), {
    //     ...defaultPosition,
    //     ...option,
    //     splitByGrapheme: true,
    //     width: 50,
    //     fontSize: 10,
    //     id: uuid(),
    //   });
    //   this.canvas.c.add(text);
    //   if (!option) {
    //     text.center();
    //   }
    //   this.canvas.c.setActiveObject(text);
    // },
    // 三角形
    addTriangle(option) {
      const triangle = new this.fabric.Triangle({
        ...defaultPosition,
        ...option,
        width: 100,
        height: 100,
        isLock: false,
        dept: this.engravingDepth,
        id: uuid(),
        cutType: 'Mill Pocket',
      });
      this.canvas.c.add(triangle);
      if (!option) {
        triangle.center();
      }
      this.canvas.c.setActiveObject(triangle);
    },
    // 圆形
    addCircle(option) {
      const circle = new this.fabric.Circle({
        ...defaultPosition,
        ...option,
        isLock: false,
        radius: 50,
        dept: this.engravingDepth,
        id: uuid(),
        name: '圆形',
        cutType: 'Mill Pocket',
      });
      this.canvas.c.add(circle);
      if (!option) {
        circle.center();
      }
      this.canvas.c.setActiveObject(circle);
    },
    // 矩形
    addRect(option) {
      const rect = new this.fabric.Rect({
        ...defaultPosition,
        ...option,
        // fill: '#fff',
        dept: this.engravingDepth,
        isLock: false,
        width: 100,
        height: 100,
        rx: 0,
        ry: 0,
        id: uuid(),
        name: '矩形',
        cutType: 'Mill Pocket',
      });
      this.canvas.c.add(rect);
      if (!option) {
        rect.center();
      }
      this.canvas.c.setActiveObject(rect);
    },
    // 多边形
    addPolygon(option) {
      const Polygon = new this.fabric.Polygon(getPolygonVertices(5, 50), {
        ...defaultPosition,
        ...option,
        isLock: false,
        dept: this.engravingDepth,
        width: 50,
        height: 50,
        id: uuid(),
        name: '正多边形',
        cutType: 'Mill Pocket',
      });
      this.canvas.c.add(Polygon);
      if (!option) {
        Polygon.center();
      }
      this.canvas.c.setActiveObject(Polygon);
    },
    closeLine() {
      if (this.isDrawingLineMode) {
        // this.canvas.selection = true;
        this.canvas.c.isDrawingMode = false;
        this.drawHandler.setMode(false);
        this.drawHandler.setArrow(false);
        this.canvas.c.forEachObject((obj) => {
          if (obj.id !== 'workspace' && obj.id !== 'machine') {
            obj.selectable = true;
            obj.evented = true;
          }
        });
        this.isDrawingLineMode = false;
      }
    },
    toggleDrawingLineMode() {
      // 切换划线模式
      this.drawingLineModeSwitch(this.isArrow);
    },
    drawingLineModeSwitch(isArrow) {
      console.log(this.isDrawingLineMode, 'this.isDrawingLineModethis.isDrawingLineMode');
      console.log(isArrow, 'this.isDrawingLineModethis.isDrawingLineMode');

      if (this.isDrawingLineMode) {
        // 关闭划线模式
        // this.canvas.c.selectable = true;
        // obj.evented = true;
        this.canvas.c.isDrawingMode = false;
        this.drawHandler.setMode(false);
        this.drawHandler.setArrow(false);
        this.canvas.c.forEachObject((obj) => {
          // if (obj.id !== 'workspace' && obj.id !== 'machine') {
          obj.selectable = true;
          obj.evented = true;
          // }
        });
        this.canvas.c.off('mouse:down', this.preventSelection);
        this.isDrawingLineMode = false;
      } else {
        // 开启划线模式
        // this.canvas.c.selectable = false;
        // obj.evented = false;
        this.canvas.c.isDrawingMode = false;
        this.drawHandler = initializeLineDrawing(this.canvas.c, defaultPosition);
        this.drawHandler.setMode(true);
        this.drawHandler.setArrow(isArrow);
        this.canvas.c.forEachObject((obj) => {
          console.log(obj, 'obj');

          // if (obj.id !== 'workspace' && obj.id !== 'machine') {
          obj.selectable = false;
          obj.evented = false;
          // }
        });

        this.isDrawingLineMode = true;
      }
    },
    // drawingLineModeSwitch(isArrow) {
    //   this.drawHandler = initializeLineDrawing(this.canvas.c, defaultPosition);
    //   console.log(this.drawHandler, 'this.drawHandler');
    //   this.isArrow = isArrow;
    //   this.isDrawingLineMode = !this.isDrawingLineMode;
    //   this.drawHandler.setMode(this.isDrawingLineMode);
    //   this.drawHandler.setArrow(isArrow);
    //   this.canvas.c.forEachObject((obj) => {
    //     if (obj.id !== 'workspace') {
    //       obj.selectable = !this.isDrawingLineMode;
    //       obj.evented = !this.isDrawingLineMode;
    //     }
    //   });
    // },
    // 插入图片
    insertImg() {
      selectFiles({ accept: 'image/jpeg, image/png, image/bmp', multiple: false }).then(
        (fileList) => {
          this.imgLoading = true;
          console.log(fileList, 'fileListfileList');
          const data = fileList[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageSrc = e.target.result;
          };
          reader.readAsDataURL(data);
          const formData = new FormData();
          formData.append('file', data);
          console.log(formData, 'formDataformData');
          // Array.from(fileList).forEach((item) => {
          //   getImgStr(item).then((file) => {
          //     console.log(file, 'filefile');
          importImg(formData).then((res) => {
            if (res.data.code === 200) {
              console.log(res, 'res');
              console.log(this.showImage, 'this.showImagethis.showImagethis.showImage');
              this.filePath = res.data.data.filePath;
              const svgUrlList = res.data.data.svg;
              this.svgUrl = svgUrlList.replace(/\\n/g, '\n').replace(/\\"/g, '"');
              this.imgLoading = false;
              this.callTargetMethod();
            }
          });
          //     // this.insertmgFile(file);
          //   });
          // });
        }
      );
    },
    // 关闭图片处理
    closeImage() {
      this.showImage = false;
    },
    // 关闭Ai弹窗
    closeAi() {
      this.showAi = false;
    },
    // 关闭添加文字
    closeText() {
      this.showText = false;
    },
    // 导出gcode
    insertGcode() {
      selectFiles({ accept: '.gc', multiple: true }).then((fileList) => {
        console.log(fileList, 'fileListfileList');
        // Array.from(fileList).forEach((item) => {
        //   getImgStr(item).then((file) => {
        // const blob = new Blob([file], { type: 'text/plain' }); // 设置适当的 MIME 类型
        this.sendBinaryFile(fileList[0]);
      });
    },
    // 插入Svg
    insertSvg() {
      selectFiles({ accept: '.svg', multiple: true }).then((fileList) => {
        Array.from(fileList).forEach((item) => {
          getImgStr(item).then((file) => {
            this.insertSvgFile(file);
          });
        });
      });
    },
    // 导入项目

    insertProject() {
      selectFiles({ accept: '.lac', multiple: true }).then((fileList) => {
        if (fileList && fileList.length > 0) {
          // const promises = Array.fom(fileList).map((file) => );
          const data = fileList[0];
          const formData = new FormData();
          formData.append('file', data);
          console.log(fileList, 'fileList');
          console.log(fileList[0].name, 'fileList');
          console.log(formData, 'formDataformData');

          importPro(formData).then((res) => {
            this.fabric.loadSVGFromString(res.data, (objects) => {
              const filteredObjects = objects.filter(
                (obj) =>
                  obj.id !== 'workspace' &&
                  obj.id !== 'machine' &&
                  (!obj.fill || obj.fill !== '#F1F1F1')
              );
              filteredObjects.forEach((obj) => {
                this.canvas.c.add(obj);
              });
              this.canvas.c.renderAll();
            });
            // console.log(res.data, 'resssssssssssssss');
          });
          console.log(111);
          // const promises = Array.from(fileList).map((file) => processFile(file));
          // const results = Promise.all(promises);
          // console.log(results, 'results');
        } else {
          console.log('没有选择文件');
        }
      });
    },
    // 导入gcode
    sendBinaryFile(file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('width', this.getWidth);
      formData.append('height', this.getHeight);
      formData.append('depth', this.getDept);

      importGcode(formData).then((res) => {
        if (res.status === 200) {
          this.$message.success('success');
          this.$emit('sss-dd');
          this.$store.dispatch('webSocket/setSendType', 'Gcode');
          const reader = new FileReader();
          console.log(reader, 'readerreaderreader');
          const This = this;
          reader.onload = function (e) {
            console.log(e, 'eeeeee');
            const originalArrayBuffer = e.target.result;
            const originalUint8Array = new Uint8Array(originalArrayBuffer);
            // // 创建一个新的 Uint8Array，长度是原始的 + 1 字节
            const newArray = new Uint8Array(originalUint8Array.length + 1);
            // // 设置第一个字节为 1
            newArray[0] = 1;
            // // 将原始的 Uint8Array 数据复制到新的 Uint8Array 中，从第二个字节开始
            newArray.set(originalUint8Array, 1);
            console.log(newArray.buffer, 'newArray.buffernewArray.buffer');
            // const arrayBuffer = e.target.result;
            // console.log(arrayBuffer, 'arrayBufferarrayBuffer');
            // 将文件内容显示在 GCode Preview 区域（仅文本部分）
            // const gcodePreview = document.getElementById('gcodePreview');
            // const fileReaderForText = new FileReader();
            // fileReaderForText.onload = function (f) {
            //   gcodePreview.value = f.target.result;
            // };
            // fileReaderForText.readAsText(file);

            // 发送二进制数据给服务器
            This.getSocket.send(newArray.buffer);
          };
          reader.readAsArrayBuffer(file);
        } else {
          this.$message.error(res.message);
        }
      });

      // reader.onerror = function (error) {
      //   // log('Error reading the file.');
      //   console.error(error);
      // };
    },

    // 插入svg元素
    insertSvgFile(svgFile) {
      console.log(svgFile, 'svgFilesvgFilesvgFile');
      const This = this;
      const templateSize = This.canvas.c.getObjects();
      const masObj = templateSize.filter((element) => element.id === 'workspace');
      this.fabric.loadSVGFromURL(svgFile || this.svgFile, (objects, options) => {
        // 获取原始的宽度和高度
        let originalWidth = 0;
        let originalHeight = 0;
        // 遍历所有对象，找到最大边界框
        console.log(objects, 'objectsobjectsobjects');
        objects.forEach((obj) => {
          const bbox = obj.getBoundingRect();
          console.log(bbox, 'bboxbboxbbox');
          originalWidth = Math.max(originalWidth, bbox.width);
          originalHeight = Math.max(originalHeight, bbox.height);
        });

        // 如果没有找到任何对象，则返回
        if (originalWidth === 0 || originalHeight === 0) {
          console.error('SVG has no valid objects to determine dimensions.');
          return;
        }

        // // 指定宽度
        // const newWidth = masObj[0].height / 2;
        // // // 计算新高度
        // const newHeight = originalHeight * (newWidth / originalWidth);

        const item = This.fabric.util.groupSVGElements(objects, {
          ...options,
        });
        const workspaceleft = masObj[0].left;
        const workspaceTop = masObj[0].top + masObj[0].height - item.height * item.scaleY;
        item.set({
          zIndex: '00',
          cutType: 'Mill Pocket',
          id: uuid(),
          // scaleX: newWidth / originalWidth,
          // scaleY: newHeight / originalHeight,
          left: workspaceleft,
          top: workspaceTop,
          name: 'defaultSVG',
          dept: this.engravingDepth,
        });
        console.log(item, 'itemitemitem');
        This.canvas.c.add(item);
        This.canvas.c.renderAll();
      });
    },

    // exportPro() {
    //   const data = {};
    //   exportPro(data);
    // },
    // 保存svg
    async exportProject() {
      function addDescToFabricObject(fabricObject) {
        if (fabricObject && typeof fabricObject.toSVG === 'function' && fabricObject.des) {
          const originalToSVG = fabricObject.toSVG;
          fabricObject.toSVG = function (dim, options) {
            let svgString = originalToSVG.call(this, dim, options);
            svgString = svgString.replace(/Q\s*NaN\s+NaN\s+NaN\s+NaN\s+L\s+NaN\s+NaN/gi, '');
            // 构建 desc 内容
            // const description = Object.entries(fabricObject.des)
            //   .map(([key, value]) => `${encodeURIComponent(key)}: ${encodeURIComponent(value)}`)
            //   .join('; ');
            const description = JSON.stringify(fabricObject.des, null, 2);
            const descElement = `<desc>${description}</desc>`;
            // 将 <desc> 插入到 <g> 或者其他顶层元素内
            svgString = svgString.replace(
              /(<(rect|circle|ellipse|line|polyline|polygon|path)[^>]*)\/>/,
              `$1>${descElement}</$2>`
            );
            // svgString = svgString.replace(/(<g[^>]*>)/, `$1${descElement}`);
            return svgString;
          };
        }
      }
      const objects = this.canvas.c.getObjects();
      const filteredObjects = objects.filter(
        (obj) =>
          obj.id !== 'workspace' &&
          obj.id !== 'machine' &&
          obj.id !== 'coordinate' &&
          !(
            obj instanceof this.fabric.Rect &&
            obj.fill &&
            obj.fill.source instanceof HTMLCanvasElement
          )
      );
      const tempCanvas = new this.fabric.StaticCanvas(null, {
        width: this.canvas.c.width,
        height: this.canvas.c.height,
      });
      filteredObjects.forEach((obj) => {
        if (!obj.toObject) {
          obj.toObject = function () {
            return this.fabric.Object.prototype.toObject
              .call(this)
              .concat(['speed', 'power', 'pass', 'interval']);
          };
        }
      });
      async function cloneObjectRecursively(index) {
        if (index >= filteredObjects.length) {
          return;
        }
        const obj = filteredObjects[index];

        return new Promise((resolve) => {
          obj.clone((clonedObj) => {
            try {
              if (clonedObj) {
                clonedObj.set({
                  width: clonedObj.width * clonedObj.scaleX,
                  height: clonedObj.height * clonedObj.scaleY,
                  scaleX: 1,
                  scaleY: 1,
                  des: {
                    feedRate: obj.speed,
                    spindleSpeed: obj.power,
                    passes: obj.pass,
                    interval: obj.interval,
                    dept: obj.dept,
                    zIndex: obj.zIndex,
                    cutType: obj.cutType,
                  },
                });
                if (obj.zIndex !== '00') {
                  clonedObj.set({
                    des: {
                      feedRate: obj.speed,
                      spindleSpeed: obj.power,
                      passes: obj.pass,
                      interval: obj.interval,
                      zIndex: obj.zIndex,
                      cutType: obj.cutType,
                    },
                  });
                } else {
                  clonedObj.set({
                    des: {
                      feedRate: obj.speed,
                      spindleSpeed: obj.power,
                      passes: obj.pass,
                      interval: obj.interval,
                      targetDepth: obj.dept,
                      startDepth: 0,
                      zIndex: obj.zIndex,
                      cutType: obj.cutType,
                    },
                  });
                }
                addDescToFabricObject(clonedObj);
                tempCanvas.add(clonedObj);
              } else {
                console.warn(`Cloned object ${index} is undefined or null`);
              }
              resolve();
            } catch (error) {
              console.error('Error cloning object:', error);
              resolve(); // 即使出错也要 resolve 以确保递归继续执行
            }
          });
        }).then(() => cloneObjectRecursively(index + 1));
      }

      await cloneObjectRecursively(0);
      const dataUrl = tempCanvas.toSVG();

      // const blob = new Blob([dataUrl], { type: 'image/svg+xml' });
      // // 基于 Blob 创建一个 File 对象
      // this.svgFile = new File([blob], 'example.svg', { type: 'image/svg+xml' });
      // this.handleSVGFile(this.svgFile);
      // const data = {
      //   file: this.svgFile,
      // };
      // console.log(data, 'datadatadata');

      // this.uploadFile(data);

      // this.uploadFile(this.svgFile);
      // const templateSize = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(dataUrl)}`;
      // return templateSize;

      // console.log(fileStr, 'fileStr');
      const data = {
        id: localStorage.getItem('canvasID'),
        name: `Project${localStorage.getItem('canvasID')}`,
        userId: localStorage.getItem('id'),
        canvasUrl: this.canvasUrl,
        materialInfo: {
          // 材料尺寸
          simension: '{"X":"900 mm","Y":"1200 mm","Z":"19 mm"}',
          previewUrl: this.canvasUrl,
          color: '#FF0000',
          extInfo: '',
        },
        // 设备id
        machineId: localStorage.getItem('machineId'),
        previewUrl: this.canvasUrl,
      };
      const mar = objects.find((item) => item.id === 'machine');
      exportPro(data).then((res) => {
        if (res.code === 200) {
          const apiData = res.data;
          const fullSvgContent = `${apiData}\n${mar.left},${mar.top}\n${dataUrl}`;
          const fileStr = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(fullSvgContent)}`;
          this.downFile(fileStr, `Project${localStorage.getItem('canvasID')}.lac`);
        }
      });
    },
    // 上传文件
    uploadFile(data) {
      uploadPro(data).then((res) => {
        if (res.code === 200) {
          this.canvasUrl = res.data;
          this.$emit('canvas-url', this.canvasUrl);
          this.uploadProject();
        }
      });
    },
    // 更新项目
    uploadProject() {
      const data = {
        id: localStorage.getItem('canvasID'),
        name: `Project${localStorage.getItem('canvasID')}`,
        userId: localStorage.getItem('id'),
        canvasUrl: this.canvasUrl,
        materialInfo: {
          simension: '{"X":"19 mm","Y":"19 mm","Z":"19 mm"}',
          previewUrl: this.canvasUrl,
          color: '#FF0000',
          extInfo: '',
        },
        machineId: localStorage.getItem('machineId'),
        previewUrl: this.canvasUrl,
        gcodeUrl: this.canvasUrl,
      };
      updateProject(data).then((res) => {
        if (res.code === 200) {
          localStorage.setItem('canvasID', res.data);
          this.canvasId = res.data;
        }
      });
    },
    // 获取svg
    async getSvg() {
      function addDescToFabricObject(fabricObject) {
        if (fabricObject && typeof fabricObject.toSVG === 'function' && fabricObject.des) {
          const originalToSVG = fabricObject.toSVG;
          fabricObject.toSVG = function (dim, options) {
            let svgString = originalToSVG.call(this, dim, options);
            svgString = svgString.replace(/Q\s*NaN\s+NaN\s+NaN\s+NaN\s+L\s+NaN\s+NaN/gi, '');
            // 构建 desc 内容
            // const description = Object.entries(fabricObject.des)
            //   .map(([key, value]) => `${encodeURIComponent(key)}: ${encodeURIComponent(value)}`)
            //   .join('; ');
            const description = JSON.stringify(fabricObject.des, null, 2);
            const descElement = `<desc>${description}</desc>`;
            // 将 <desc> 插入到 <g> 或者其他顶层元素内
            svgString = svgString.replace(
              /(<(rect|circle|ellipse|line|polyline|polygon|path)[^>]*)\/>/,
              `$1>${descElement}</$2>`
            );
            // svgString = svgString.replace(/(<g[^>]*>)/, `$1${descElement}`);
            return svgString;
          };
        }
      }
      const objects = this.canvas.c.getObjects();
      const filteredObjects = objects.filter(
        (obj) =>
          obj.id !== 'workspace' &&
          obj.id !== 'machine' &&
          obj.id !== 'coordinate' &&
          !(
            obj instanceof this.fabric.Rect &&
            obj.fill &&
            obj.fill.source instanceof HTMLCanvasElement
          )
      );
      const tempCanvas = new this.fabric.StaticCanvas(null, {
        width: this.canvas.c.width,
        height: this.canvas.c.height,
      });
      filteredObjects.forEach((obj) => {
        if (!obj.toObject) {
          obj.toObject = function () {
            return this.fabric.Object.prototype.toObject
              .call(this)
              .concat(['speed', 'power', 'pass', 'interval']);
          };
        }
      });
      async function cloneObjectRecursively(index) {
        if (index >= filteredObjects.length) {
          return;
        }
        const obj = filteredObjects[index];
        return new Promise((resolve) => {
          console.log(obj, 'obj222222222');
          obj.clone((clonedObj) => {
            try {
              if (clonedObj) {
                clonedObj.set({
                  width: clonedObj.width * clonedObj.scaleX,
                  height: clonedObj.height * clonedObj.scaleY,
                  scaleX: 1,
                  scaleY: 1,
                  des: {
                    feedRate: obj.speed,
                    spindleSpeed: obj.power,
                    passes: obj.pass,
                    interval: obj.interval,
                    dept: obj.dept,
                    zIndex: obj.zIndex,
                    cutType: obj.cutType,
                  },
                });
                if (obj.zIndex !== '00') {
                  clonedObj.set({
                    des: {
                      feedRate: obj.speed,
                      spindleSpeed: obj.power,
                      passes: obj.pass,
                      interval: obj.interval,
                      zIndex: obj.zIndex,
                      cutType: obj.cutType,
                    },
                  });
                } else {
                  clonedObj.set({
                    des: {
                      feedRate: obj.speed,
                      spindleSpeed: obj.power,
                      passes: obj.pass,
                      interval: obj.interval,
                      targetDepth: obj.dept,
                      startDepth: 0,
                      zIndex: obj.zIndex,
                      cutType: obj.cutType,
                    },
                  });
                }
                addDescToFabricObject(clonedObj);
                tempCanvas.add(clonedObj);
              } else {
                console.warn(`Cloned object ${index} is undefined or null`);
              }
              resolve();
            } catch (error) {
              console.error('Error cloning object:', error);
              resolve(); // 即使出错也要 resolve 以确保递归继续执行
            }
          });
        }).then(() => cloneObjectRecursively(index + 1));
      }

      await cloneObjectRecursively(0);
      console.log(tempCanvas, 'tempCanvastempCanvas');

      // 自定义函数来处理每个对象的 toSVG 输出并插入 <desc> 标签
      const dataUrl = tempCanvas.toSVG();
      console.log(dataUrl, 'dataUrldataUrl');
      const blob = new Blob([dataUrl], { type: 'image/svg+xml' });
      // 基于 Blob 创建一个 File 对象
      this.svgFile = new File([blob], `Project${localStorage.getItem('canvasID')}.svg`, {
        type: 'image/svg+xml',
      });
      console.log(this.svgFile, 'this.svgFilethis.svgFile');
      // this.uploadToServer(this.svgFile);
      const formData = new FormData();
      formData.append('file', this.svgFile, `Project${localStorage.getItem('canvasID')}.svg`);
      console.log(formData, 'formDataformData');

      await this.uploadFile(formData);
    },

    // uploadToServer(file) {},
    downFile(fileStr, fileType) {
      const anchorEl = document.createElement('a');
      anchorEl.href = fileStr;
      anchorEl.download = `${fileType}`;
      document.body.appendChild(anchorEl); // required for firefox
      anchorEl.click();
      anchorEl.remove();
    },
    closeVisable() {
      this.library = false;
    },

    // 图片处理
    debounceMethod() {
      if (this.timer) {
        clearTimeout(this.timer); // 清除之前的定时器
      }
      this.timer = setTimeout(() => {
        this.callTargetMethod();
      }, 1000); // 设置新的定时器，延迟两秒
    },
    callTargetMethod() {
      const data = {
        filePath: this.filePath,
        turdsize: this.controls.spotRemoval,
        alphamax: this.controls.smoothing,
        downsampling: this.controls.downsampling,
      };
      getProject(data).then((res) => {
        if (res.code === 200) {
          const svgUrlList = res.data.data.svg;
          this.svgUrl = svgUrlList.replace(/\\n/g, '\n').replace(/\\"/g, '"');
        }
      });
      // 在这里调用你想要的方法，例如更新服务器数据、重新渲染图表等
    },
    // Aisvg处理

    aiChange() {
      if (this.timer) {
        clearTimeout(this.timer); // 清除之前的定时器
      }
      this.timer = setTimeout(() => {
        this.aichangeMethod();
      }, 1000); // 设置新的定时器，延迟两秒
    },
    aichangeMethod() {
      const data = {
        filePath: this.aipath,
        turdsize: this.aiControl.spotRemoval,
        alphamax: this.aiControl.smoothing,
        downsampling: this.aiControl.downsampling,
      };
      getProject(data).then((res) => {
        if (res.code === 200) {
          const svgUrlList = res.data.data.svg;
          this.aiSvg = svgUrlList.replace(/\\n/g, '\n').replace(/\\"/g, '"');
        }
      });
      // 在这里调用你想要的方法，例如更新服务器数据、重新渲染图表等
    },
    // 高级素材库
    closeAdvanced() {
      this.advancedVis = false;
    },
  },
};
</script>

<style scoped lang="less">
.tool-box {
  display: flex;
  justify-content: space-around;
  span {
    flex: 1;
    text-align: center;
    padding: 5px 0;
    background: #f6f6f6;
    margin-left: 2px;
    cursor: pointer;
    &:hover {
      background: #edf9ff;
      svg {
        fill: #2d8cf0;
      }
    }
  }
  .bg {
    background: #d8d8d8;

    &:hover {
      svg {
        fill: #2d8cf0;
      }
    }
  }
}
.img {
  width: 20px;
}
.sidebar {
  width: ⅓;
  padding: 10px;
  background-color: #f4f4f4;
  border-right: 1px solid #ccc;
}

.upload-file input[type='file'] {
  display: none;
}

.upload-file label {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.workspace {
  width: 100%;
  height: 100%;
  position: relative;
  background: #cce6fd;
  flex: 1;
}

#canvas {
  width: 100%;
  height: 100%;
}

.properties-panel {
  position: absolute;
  top: 0;
  left: 100;
  width: 20px;
  padding: 10px;
  background-color: #f4f4f4;
  border-left: 1px solid #ccc;
}
img {
  width: 40px;
  height: 40px;
}
.list-container {
  background-color: white;
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-container li {
  padding: 5px;
  border-bottom: 1px solid #c2c2c2;
  position: relative;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.childList {
  // background-color: white;
  height: 50px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.childList li {
  padding: 5px;
  background-color: white;
  border-bottom: 1px solid #c2c2c2;
  // float: left;
  position: relative;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.secChildList {
  // display: inline-flex;
  // background-color: white;
  height: 50px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.secChildList li {
  padding: 5px;
  background-color: white;
  border-bottom: 1px solid #c2c2c2;
  // float: left;
  position: relative;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 半透明黑色遮罩 */
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1; /* 确保遮罩层在内容之上 */
}

li:hover::before {
  opacity: 1; /* 鼠标悬停时显示遮罩层 */
}
.triangle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 0;
  border-color: transparent #000 transparent transparent;
  transition: border-color 0.3s;
}

.triangle.active {
  border-color: transparent red transparent transparent;
}
::v-deep .v-modal {
  display: none;
}

.dialog-content {
  display: flex;
}

.svg-display {
  position: relative;
  flex: 1;
  height: 300px; /* 确保有足够的高度 */
  // height: 300px; /* 确保有足够的高度 */
  border: 1px solid #ccc; /* 可选：添加边框 */
}
::v-deep .svg-display > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* 确保 SVG 按比例缩放 */
}
.control-panel {
  flex: 1;
  padding-left: 20px; /* 添加一些间距 */
}

.block:hover {
  cursor: pointer;
}
</style>
