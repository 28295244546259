// svgService.js
import axios from 'axios';

// 封装获取 SVG 内容的函数
async function fetchSvgContent(url) {
  try {
    const response = await axios.get(url, {
      responseType: 'text', // 确保响应类型为文本
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching SVG:', error);
    throw error;
  }
}
export default fetchSvgContent;
