// 像素转英寸
export function perInch(px) {
  return px / 96;
}
// 像素转毫米
export function perMillimeter(px) {
  return px * (25.4 / 96);
}
// 毫米转像素
export function millimeterToPx(mm) {
  return mm * (96 / 25.4);
}
// 英寸转像素
export function inchToPx(inch) {
  return inch * 96;
}
