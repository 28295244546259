/* eslint import/prefer-default-export: "off" */
import request from '@/utils/projectRequest';

// 二维码
export function getqrcode(data) {
  return request({
    url: '/api/design-library/qrcode',
    method: 'get',
    params: data,
  });
}
// 置物盒
export function getbox(data) {
  return request({
    url: '/api/design-library/open-box',
    method: 'get',
    params: data,
  });
}
// 自定义盒子
export function getcustom(data) {
  return request({
    url: '/api/design-library/custom-box',
    method: 'get',
    params: data,
  });
}
